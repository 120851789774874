import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function phoneNumberValidator(): ValidatorFn{
    return (control: AbstractControl) : ValidationErrors | null => {

        const value = control.value;

        if (!value){
            return null;
        }

        const hasNumeric = /[0-9]{9}/.test(value);

        return !hasNumeric || value.toString().length > 9 ? {fieldValid:true } : null;
    }
}