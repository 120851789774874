<div>
  <div class="modal-sms-close">
    <button mat-dialog-close aria-label="{{'Fechar' | translate}}" >
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>

  <p class="modal-sms-text">{{'Introduza o seu contacto' | translate}}</p>

  <div class="modal-sms-input">
    <form [formGroup]="smsForm">
      <mat-form-field class="example-full-width">
        <mat-label>{{'número de telemóvel' | translate}}</mat-label>
        <span matPrefix>+351 &nbsp;</span>
        <input id="sms" type="tel" formControlName="serviceText" matInput (focus)="displaySuccessMessage = false; displayErrorMessage = false">
        <span class="text-danger">
          <span [innerHTML]="displayMessage.sms"></span>
        </span>
      </mat-form-field>
    </form>
  </div>

  <div class="modal-sms-message">
    <span class="text-success" *ngIf="displaySuccessMessage">{{'Senha enviada com sucesso.' | translate}}</span>
  </div>

  <div class="modal-sms-message">
    <span class="text-danger" *ngIf="displayErrorMessage">{{errorMessage}}</span>
  </div>

  <div class="modal-sms-actions">
    <button (click)="sendSms()" [disabled]="!smsForm.valid" type="submit">
      <p>{{'Enviar senha' | translate}}</p>
    </button>
  </div>
</div>
