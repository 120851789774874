<div class="table-body">

  <!-- <table #visitDays id="visitDays" class="mat-elevation-z8 table">
    <caption id="ticket-table-caption">{{'Horas de maior procura' | translate}}</caption>
    <tr>
      <th [ngClass]="{'col-width ': i === 0}" *ngFor="let header of headers; let i = index">{{ header }}</th>
    </tr>
    <tr *ngFor="let dataRow of tableData.data; let r = index">
      <th>{{ dataRow['Hora'] || dataRow['Hour'] || 0 }}</th>
      <td *ngIf="selectedDay === 'Segunda' || selectedDay === 'Monday' ">{{ dataRow['Segunda'] || dataRow['Monday'] || 0 }}</td>
      <td *ngIf="selectedDay === 'Terça' || selectedDay === 'Tuesday' ">{{ dataRow['Terça'] || dataRow['Tuesday'] || 0 }}</td>
      <td *ngIf="selectedDay === 'Quarta' || selectedDay === 'Wednesday' ">{{ dataRow['Quarta'] || dataRow['Wednesday'] || 0 }}</td>
      <td *ngIf="selectedDay === 'Quinta' || selectedDay === 'Thursday' ">{{ dataRow['Quinta'] || dataRow['Thursday'] || 0 }}</td>
      <td *ngIf="selectedDay === 'Sexta' || selectedDay === 'Friday' ">{{ dataRow['Sexta'] || dataRow['Friday'] || 0 }}</td>
      <td *ngIf="selectedDay === 'Sábado' || selectedDay === 'Saturday' ">{{ dataRow['Sábado'] || dataRow['Saturday'] || 0 }}</td>
    </tr>
  </table> -->


  <table #visitDays id="visitDays" class="mat-elevation-z8 table">
    <caption id="ticket-table-caption">{{'Horas de maior procura' | translate}}</caption>
      <tr scope="row">
        <th >{{ headers[0] }}</th>
        <td scope="col" *ngFor="let dataRow of tableData.data; let r = index">{{ dataRow['Hora'] || dataRow['Hour'] || 0 }}</td>
      </tr>
      <tr scope="row">
        <th scope="col">{{ rowHeader || headers[1] }}</th>
        <ng-container *ngFor="let dataRow of tableData.data; let r = index">
          <td scope="col" *ngIf="selectedDay === 'Segunda' || selectedDay === 'Monday' ">{{ dataRow['Segunda'] || dataRow['Monday'] || 0 }}</td>
          <td scope="col" *ngIf="selectedDay === 'Terça' || selectedDay === 'Tuesday' ">{{ dataRow['Terça'] || dataRow['Tuesday'] || 0 }}</td>
          <td scope="col" *ngIf="selectedDay === 'Quarta' || selectedDay === 'Wednesday' ">{{ dataRow['Quarta'] || dataRow['Wednesday'] || 0 }}</td>
          <td scope="col" *ngIf="selectedDay === 'Quinta' || selectedDay === 'Thursday' ">{{ dataRow['Quinta'] || dataRow['Thursday'] || 0 }}</td>
          <td scope="col" *ngIf="selectedDay === 'Sexta' || selectedDay === 'Friday' ">{{ dataRow['Sexta'] || dataRow['Friday'] || 0 }}</td>
          <td scope="col" *ngIf="selectedDay === 'Sábado' || selectedDay === 'Saturday' ">{{ dataRow['Sábado'] || dataRow['Saturday'] || 0 }}</td>
        </ng-container>
      </tr>
  </table>

</div>


