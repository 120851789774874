import { Component, OnInit, HostListener, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { SidebarrightComponent } from '../../core/sidebarright/sidebarright.component';
import { Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FilteroptionsComponent } from '../filteroptions/filteroptions.component';
import { ApiAccessService } from '../services/apiAccess.service';
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorAlertComponent } from '../error-alert/error-alert.component';
import { GoogleAnalyticsService } from 'angular-ga';
import { debounce } from 'src/app/debounce.decorator';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { MapaService } from './services/mapa.service';
import { Group } from './models/group';
import { ResponseData } from './models/responseData';
import { UserService } from '../auth/user.service';
import { AuthService } from '../auth/auth.service';
import { UserPosition } from './models/userPosition';
import { MapPointDto } from './models/mapPointDto';
import { IsIncluded } from '../shared/helpers/isIncluded';
import { SearchType } from './models/enum/searchType';
import { Pagination } from './models/Pagination';
import { PointType } from './models/enum/pointType';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs';

//subscription function call in search.js
declare const getPointsByTextSubject: Subject<any>;

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss']
})

export class MapaComponent implements OnInit, OnDestroy {
  @Input() sideNavRight: any;
  @Input() sideRightBar: SidebarrightComponent;
  @Input() filteroptions: FilteroptionsComponent;
  @Input() divLoader: HTMLDivElement;

  private subscription: Subscription;

  @HostListener('window:resize', ['$event'])

  @debounce(65)
  onResize(event) {
    this.ResizeMap();
  }

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private mapaService: MapaService,
    private userService: UserService, 
    private authService: AuthService
  ){
      if (this.deviceService.isDesktop()) {
        this.zoomControl = true;
      } else {
        this.zoomControl = false;
      }
  }

  //#region MapStyles used on agm-map mapa.component.html
  mapStyles = [
    {
      "featureType": "poi.business",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },

    {
      "featureType": "poi.attraction",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },

    {
      "featureType": "poi.government",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },

    {
      "featureType": "poi.medical",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },

    {
      "featureType": "poi.place_of_worship",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },

    {
      "featureType": "poi.school",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },

    {
      "featureType": "poi.sports_complex",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },

    {
      "featureType": "road.highway",
      "elementType": "labels",


      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },

    {
      "featureType": "transit.station",
      "elementType": "labels",


      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }];
    //#endregion
  
  //#region Global Property
  
  /** Indicates if user can control zoom. Used on agm-map zoomControl mapa.component.html */
  zoomControl: boolean;
  /** Indicates actual zoom on map. Used on ZoomChange event */
  zoom: number = 14;
  /** Indicates size of map window. Used on agm-map style.width.px mapa.component.html */
  widthMap: number = 1366;
  widthInputSearch: number = 1366;

  /** Used in MapReady function event on mapa.component.html*/
  map: any;
  /**Area to search Organizations nearby in Meters */
  areaInMeters: number = 2500;
  /**Map's Points List*/
  mapPointsDto: MapPointDto[];
  /**Pagination has Map's Point List per Page. Used in nearby-places.component.html for pagination */
  pagination: Pagination = new Pagination();
  /**User position with latitude and longitude */
  userPosition: UserPosition = new UserPosition(0, 0);
  /**Type of Search Map's Points */
  searchType: SearchType;
  /**Check if user access this website from a mobile phone */
  mobile: boolean = false;
  /**
   * Used to center the map according to the first point in the MapPointDto list.
   * Used on agm-map mapa.component.html
   */
  latFirstPoint: number;
  lngFirstPoint: number;

  groups: Group[] = [];

  /**Used to control when show list map's points */
  showListMapPoints: boolean = true;

  titleListMapPoint: string = "Locais Perto de Si";
  groupNameSelected: string = "Loja de Cidadão";
  titleSearchBy: string = "";
  //#endregion

  //#region Methods
  async ngOnInit() {
    //subscription function call in search.js
    this.subscription = getPointsByTextSubject.subscribe(data => this.getPointsByText(data));

    if (this.getBrowserName() == 'ie') {
      this.translate.get('incompatible_browser').subscribe((text: string) => {
        this.openDialog(text);
      });
    }

    this.sideNavRight.openedStart.subscribe(() =>
      this.ResizeMap()
    );

    this.sideNavRight.closedStart.subscribe(() =>{
      this.ResizeMap()
    });

    this.Loading(true);

    await this.userService.setUserPosition();
    var token = await this.authService.GetApiToken().toPromise();
    this.authService.StoreToken(token);
    this.userPosition = JSON.parse(localStorage.getItem('userPosition'));

    this.latFirstPoint = this.userPosition.latitude;
    this.lngFirstPoint = this.userPosition.longitude;

    this.GetMapPoints(); 

    this.mapaService.getGroupsActive(this.GetLanguage())
      .subscribe(res => this.setGroups(res));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Used in mapReady event on agm-map mapa.component.html
   * @param map Map loaded in component
   */
  public mapReady(map) {
    this.map = map;
    this.addAltToImg();
    this.mobile = this.MobileCheck();
  }

  /**
   * Highlight icon on Map and 
   * Show point on top of Sidebar's list
   * Used in markerClick event on agm-marker mapa.component.html
   */
  public showPointInSidebar(locationId: number){

    var item = this.pagination.items.find(p => p.locationId === locationId);
    let isHighlight = item?.class.includes("highlight");

    //Clear all highlight icon
    this.setIconMapPoints();

    if (!item){ //new point 
      const point = this.mapPointsDto.find(p => p.locationId === locationId);
      this.highLightIconOnMap(point);
      
      //Find Point in list on sidebar
      point.class = "nearby-poi-highlight";
      this.pagination.items[0] = point;
      this.sideRightBar.InitializeSidebar();
      this.OpenSidebar()
    }
    else if (item && !isHighlight){
      this.highLightIconOnMap(item);
      item.class = "nearby-poi-highlight";
      this.sideRightBar.InitializeSidebar();
      this.OpenSidebar()
    }
    else{
      this.setItemsPerPage();  
    }
  }

  /**
   * Highlight icon in Map.
   * Used in MouseEnterRow event in sidebarright.component.ts
   * @param point map point
   */
  public highLightIconOnMap(point: MapPointDto) {
    if (point.iconUrl.indexOf("blueEmpty") > 0) {
      point.icon = {
        url: point.iconUrl,
        scaledSize: {
          height: 70,
          width: 50
        }
      };
    }
    else {
      point.icon = {
        url: point.iconUrl,
        scaledSize: {
          height: 73,
          width: 70
        }
      };
    }
  };

  /**
   * Set field icon in MapPointDto with size, in each point of Map's Points List. 
   * Used in MouseLeaveRow event in sidebarright.component.ts
   * */
  public setIconMapPoints(){
    this.mapPointsDto?.forEach(element => {
      element.class = "nearby-poi";
      if (element.iconUrl.indexOf("blueEmpty") > 0) {
        element.icon = {
          url: element.iconUrl,
          scaledSize: {
            height: 35,
            width: 25
          }
        };
      }
      else {
        element.icon = {
          url: element.iconUrl,
          scaledSize: {
            height: 43,
            width: 40 
          }
        };
      }
    });
  }

  /**
   * Search new map's points with bigger area.
   * Used in zoomChange event on agm-map mapa.component.html
   * @param event 
   * @returns 
   */
  zoomChange(event: any) {

    if (event == 11 || event == 15 || this.searchType == SearchType.Group || this.searchType == SearchType.SearchText)
      return;

    if (event > this.zoom){
      this.areaInMeters -= 500;
    }
    else if (event < this.zoom){
      this.areaInMeters += 500;
    }

    this.zoom = event;
    this.getOrganizationNearby();
  }

  /**
   * Resize map's window.
   * Used in event 
   */
  @HostListener('window:resize', ['$event'])
  ResizeMap() {
    const menu = document.getElementsByClassName('wa__rootmenu')[0];
    const menuBot = document.getElementsByClassName('wa__bottom')[0];
    if (this.sideNavRight.opened) {
      menu.classList.add('open_sidenav');
      menuBot.classList.add('open_sidenav');
      this.widthMap = document.getElementById("divMapa").clientWidth - 495;
      this.widthInputSearch = this.widthMap - 10;
    }
    else {
      menu.classList.remove('open_sidenav');
      menuBot.classList.remove('open_sidenav');
      this.setIconMapPoints();
      this.widthMap = document.getElementById("divMapa").clientWidth;
      this.widthInputSearch = this.widthMap - 55;
      let sideNavClass = document.getElementById('sideNavClassId');
      sideNavClass.focus();
    }
  }

  /**
   * Open sidebar witch contains list map Points
   */
  public OpenSidebar(){
    if (!this.sideNavRight.opened && !this.mobile) {
        this.sideNavRight.toggle();
    }
  }

  /**
   * Close sidebar
   */
  public CloseSidebar(){
    if (this.sideNavRight.opened) {
      this.sideNavRight.toggle();
    }
  }

  /**Show or hide popup loading */
  Loading(loading: boolean) {
    this.divLoader.hidden = !loading;
  }

  /**
   * Call service to get list of map's points depending on type of search. 
   * All types of search must return an object ResponseData<MapPointDto[]>
   */
  private GetMapPoints(){

    this.getSearchType();

    switch (this.searchType){
      case SearchType.OrganizationNearby:
        this.getOrganizationNearby();
        break;
      case SearchType.PointOfCare:
        this.getPointOfCareByCode();
        break;
      case SearchType.SearchText:
        this.searchPointsByText();
        break;
      case SearchType.Organization:
        this.getOrganizationByCode();
        break;
    }
  }
  
  /**
   * Select wich type of search it is
   */
  private getSearchType()
  {
    if (IsIncluded(this.router.url, "SearchPoc")){
        this.searchType = SearchType.PointOfCare;
    }
    else if (IsIncluded(this.router.url, "SearchMainAddrEntity")){
      this.searchType = SearchType.Organization;
    }
    else if (IsIncluded(this.router.url, "SearchText")){
      this.searchType = SearchType.SearchText;
    }
    else{
      this.searchType = SearchType.OrganizationNearby;
    }
  }

  /**
   * Get list of Organizations nearby user
   */
  public getOrganizationNearby(){
    this.clearSearch();

    this.searchType = SearchType.OrganizationNearby;

    this.Loading(true); 
    this.mapaService.getOrganizationsNearby(this.areaInMeters, this.GetLanguage()).subscribe(res => this.buildMapPoints(res));
  }

  /**Get PointOfCare By Code */
  private getPointOfCareByCode(){
    var code = this.route.snapshot.queryParamMap.get("pc");

    if (code){
      this.Loading(true);
      this.mapaService.getPointOfCareByCode(code, this.GetLanguage()).subscribe(res => this.buildMapPoints(res))
    }
  }

  private setTitleListMapPoint(qtyPoints: number){

    const title = `${this.translate.instant("sidebarright.titleShowText")} ${qtyPoints}`;

    switch (this.searchType){
      case SearchType.Group:
        this.titleListMapPoint = `${title} ${this.groupNameSelected}`; 
        break;
      case SearchType.SearchText:
        this.titleListMapPoint = `${title} ${this.translate.instant("sidebarright.titleSearchBy")} "${this.titleSearchBy}"`; 
        break;
      default:
        this.titleListMapPoint = `${title} ${this.translate.instant("sidebarright.titleNearby")}`;
    }
    
  }

  /**Get Organization By Code */
  private getOrganizationByCode(){
    var code = this.route.snapshot.queryParamMap.get("et");

    if (code){
      this.Loading(true);
      this.mapaService.getOrganizationByCode(code, this.GetLanguage()).subscribe(res => this.buildMapPoints(res))
    }
  }

  /**Search Points by Text */
  public searchPointsByText(){
    var text = this.route.snapshot.queryParamMap.get("text");

    this.getPointsByText(text);
  }
  /**Get Points By Text 
   * Function is call by script search.js
  */
  public getPointsByText(text: string){
    if (text){
      this.searchType = SearchType.SearchText;
      this.groups.forEach(g => g.isActive = false);

      this.Loading(true);
      this.mapaService.getPointsByText(text, this.GetLanguage()).subscribe(res => this.buildMapPoints(res))
    }
  }

  private clearSearch(){
    var inputText = document.getElementById("desktopFormKey") as HTMLInputElement;
    if (inputText){
      inputText.value = "";
    }
  }

  public searchPointsByGroupOrNearby(item: Group){
    this.clearSearch();

    if (item.isActive){
        item.isActive = false;
        this.getOrganizationNearby();
    }
    else{
      this.groups.forEach(g => g.isActive = false);
      item.isActive = true;
      this.groupNameSelected = item.name;
      this.getPointsOfCareByGroup(item.id, item.type);
    }
  }

  /**
   * Get list of PointOfCare by group.
   * Used in divGrupos event click button on sidebarright.component.html
   * @param groupId identifier of group
   */
  public getPointsOfCareByGroup(groupId: number, pointType: PointType){
    this.Loading(true); 
    this.searchType = SearchType.Group;

    if (pointType == PointType.Organization){
      this.mapaService.getOrganizationsByGroup(groupId, this.GetLanguage()).subscribe(res => this.buildMapPoints(res));
    }
    else{
      this.mapaService.getPointsOfCareByGroup(groupId, this.GetLanguage()).subscribe(res => this.buildMapPoints(res));
    }
  }

  /**
   * Build Map's points 
   * @param response list of mapPoints returned in service search
   */
  private buildMapPoints(response: ResponseData<MapPointDto[]>){
    this.pagination = new Pagination();
    this.sideRightBar.InitializeSidebar();

    if (response.statusCode == 200){
      this.setTitleListMapPoint(response.data.length);

      this.mapPointsDto = response.data;
      this.setIconMapPoints();

      this.latFirstPoint = this.mapPointsDto[0].latitude;
      this.lngFirstPoint = this.mapPointsDto[0].longitude;
      this.centerMap(this.latFirstPoint, this.lngFirstPoint);
      
      this.calculateMaxPages(this.mapPointsDto.length);
      this.setItemsPerPage();

      this.Loading(false);
    }
    else if (response.statusCode == 404){
      this.Loading(false);
    }

    this.OpenSidebar();
  }

  /**Set Items for current page. Used in nearby-places.component.html */
  public setItemsPerPage(){
    this.pagination.items = [];
    let first = (this.pagination.currentPage * this.pagination.itemsPerPage) - this.pagination.itemsPerPage;
    let final = first + this.pagination.itemsPerPage > this.mapPointsDto.length ? this.mapPointsDto.length : first + this.pagination.itemsPerPage;
    for(let i = first; i < final; i++){
      this.pagination.items.push(this.mapPointsDto[i])
    }

    this.setButtonActivePage();
  }
  
  /**Set class Active on pagination button */
  public setButtonActivePage(){
    this.addClassActive(document.getElementById("page1"), this.pagination.pageOne == this.pagination.currentPage);
    this.addClassActive(document.getElementById("page2"), this.pagination.pageTwo == this.pagination.currentPage);
    this.addClassActive(document.getElementById("page3"), this.pagination.pageThree == this.pagination.currentPage);
    this.addClassActive(document.getElementById("page4"), this.pagination.maxPage == this.pagination.currentPage);
    this.addClassActive(document.getElementById("pageP"), this.pagination.currentPage == 1, "disabled");
    this.addClassActive(document.getElementById("pageN"), this.pagination.maxPage == this.pagination.currentPage, "disabled");
  }

  /**Add or Remove class active button*/
  private addClassActive(button: HTMLElement, isActive: boolean, classToAdd: string = "active"){
      if (isActive){
        button?.classList.add(classToAdd);
      }
      else{
        button?.classList.remove(classToAdd);
      }
  }

  /**Calculate number of Pages. Used in nearby-places.component.html */
  private calculateMaxPages(totalItems: number){
    this.pagination.maxPage = totalItems < this.pagination.itemsPerPage ? 1 : Math.ceil(totalItems / this.pagination.itemsPerPage);
  }

  /**
   * Center the map
   * @param lat latitude
   * @param lng longitude
   */
  centerMap(lat: Number, lng: Number) {
    if (this.map != null) {
      this.map.setCenter({ lat: lat, lng: lng });
    }
    else {
      this.delay(50).then(any => {
        this.map.setCenter({ lat: lat, lng: lng });
      });
    }
  }

  /**
   * Get current language choose by user. PT or EN. 
   * @returns {string} Current language. PT default.
   */
  GetLanguage(): string {
    return this.filteroptions.GetLanguage();
  }

  /**
   * Add tag Alt in each img google
   */
  addAltToImg() {
    setTimeout(
      function () {
        const elements1 = document.querySelectorAll('img');
        elements1.forEach(element => {
          let str = element.src
          if (str.includes("maps.googleapis") || str.includes("maps.gstatic.com")) {
            element.alt = 'Mapa';
          }
        });
      }, 3000);
  }

  /**
   * Get current user's browser name
   * @returns {string} user's browser name
   */
  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  /** Set Groups */
  public setGroups(groups: ResponseData<Group[]>) {
    this.groups = groups.data;
  }

  /**Check if user is access site from mobile */
  MobileCheck() {
    var check = false;
    (function (a) { 
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) 
        check = true; 
      })(navigator.userAgent || navigator.vendor);
    return check;
  };

  /**Wait a few moments.
   * Used on function centerMap()
   */
  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }

  /** Open a dialog showing some information to user */
  openDialog(message) {
    let dialogRef = this.dialog.open(ErrorAlertComponent, {
      data: { erro: message }
    });
  }

  //metodos antigos
  private clearRouteParams() {
    this.router.navigate(
      ['.'],
      { relativeTo: this.route, queryParams: {} }
    );
  }

  /**@deprecated Old method */
  /*private assembleMap(){
    if (this.reajustarGeolocalizacao) {//funcao usada no botao me localiza, aparece quando o menu lateral esta fechado. Mas nao esta funcionando. 19/05/2023

      this.centerMap(this.latFirstPoint, this.lngFirstPoint);

      this.Loading(true);

      this.reajustarGeolocalizacao = false;

      this.clearRouteParams();

      // Workarround to prevent the map from jumping back to the last searched position
      setTimeout(() => {
        this.sideRightBar.clearPoints();
        this.zoom = 14;

        //Reset filter options
        this.sideRightBar.resetDropDownFilter();
      }, 0);
    }
    else {
      //load point of current location
      if (this.pointsParams.searchType == 1 && !this.searchByGeoLocationInit)
        this.ListarPontos(this.userPosition.longitude, this.userPosition.longitude, '', '', '', '', '', this.radius, 1, 1);

      this.zoom = 14;

      this.sideRightBar.filterUseGeoLocation = true;

      if (this.searchByGeoLocationInit)
        this.SelectMethodInitial();
    }
  }*/
 

  /**
   * Evento disparado ao clicar em qualquer lugar no mapa. 
   * Atribui uma nova coordenada para o usario, e faz nova pesquisa de locais perto de si com as novas coordenadas.
   * Refazer esse metodo - Ruth Resende 23/05/2023
   * @param $event 
   */
  MakeMarker($event) {
    console.log("new coords. Latitude: "+ $event.coords.lat+". Longitude: "+$event.coords.lng )
    /*this.userPosition.latitude = $event.coords.lat;
    this.userPosition.longitude = $event.coords.lng;

    this.textSearch = "";

    if (this.map.getZoom() > 11)
      this.ListarPontos(this.userPosition.latitude, this.userPosition.longitude, '', '', '', '', '', this.radius, 1, 1);
    */
    this.centerMap(this.userPosition.longitude, this.userPosition.longitude);
  };



  /*ListarPontos(lat: number, lng: number, tipo: string, descricao: string, distrito: string, municipio: string, freguesia: string, area: number, tipoBusca: number, tipoFiltro: number, novaConsulta: boolean = false) {

    this.Loading(true);

    if (novaConsulta)
      this.pointsParams.pageNumber = 0;

    let zo = 14;

    if (this.map != null)
      zo = this.map.getZoom();

    if (this.selectMethodInitial) {
      zo = 14;
      this.pointsParams.pageNumber = 0;
    }

    if (this.zoomChanged) {
      this.pointsParams.pageNumber = 0;
      this.selectMethodInitial = true;
      this.searchByZoomChanged = true;
    }

    this.pointsParams.language = this.GetLanguage();
    this.pointsParams.latitude = lat;
    this.pointsParams.longitude = lng;
    this.pointsParams.type = tipo;
    this.pointsParams.pointName = descricao;
    this.pointsParams.district = distrito;
    this.pointsParams.county = municipio;
    this.pointsParams.parish = freguesia;
    this.pointsParams.area = area == 0 ? this.radius : area;
    this.pointsParams.searchType = tipoBusca;
    this.pointsParams.zoom = zo;
    this.pointsParams.filterType = tipoFiltro;
    this.pointsParams.pageNumber += 1;
    this.pointsParams.category = tipo;

    if(tipoBusca == 6)
    {  
      if (this.pointsParams.pageNumber == 1){   
          this.mapaService.getPointsByGroup(this.pointsParams.type, this.pointsParams.language).subscribe(result =>
                {          
                  if (result.statusCode == 200){                                                          
                    this.allMapPoints = result.data; //temporario 
                    this.getCoordinates(result.data);           
                    this.ResizeIcon(this.pushPoints(result.data, 0), 6, true);                                           
                  }
                  else {
                    console.log(`Error GetToken. StatusCode: ${result.statusCode}\nMessage: ${result.message}`);
                  }
                });                              
      }
      else{
        this.ResizeIcon(this.pushPoints(this.allMapPoints, (this.pointsParams.pageNumber * 4)-4), 6, true);
        this.showMorePoints = (this.pointsParams.pageNumber * 4) < this.coordinates.length;
      }
    }
    else
    {
      this.mapaService.getPointsByLocals(this.pointsParams).subscribe(
        res => this.ResizeIcon(res, tipoBusca));
    }    
    if (area != 0)
      this.radius = area;
  }*/

 /* SelectMethodInitial() {
    this.zoomChanged = true;
    this.route.url.subscribe(console.log);
    var serviceCode = this.route.snapshot.queryParamMap.get("serviceCode");

    var district = this.route.snapshot.queryParamMap.get("district");

    var county = this.route.snapshot.queryParamMap.get("county");

    var entityCode = this.route.snapshot.queryParamMap.get("entityCode");

    var poc = this.route.snapshot.queryParamMap.get("poc");

    var service = this.route.snapshot.queryParamMap.get("service");

    var point = this.route.snapshot.queryParamMap.get("point");

    var entity = this.route.snapshot.queryParamMap.get("entity");

    var text = this.route.snapshot.queryParamMap.get("text");

    this.miniMap = this.route.snapshot.queryParamMap.get("mm");

    var et = this.route.snapshot.queryParamMap.get("et");

    var pc = this.route.snapshot.queryParamMap.get("pc");

    const pocs = this.route.snapshot.queryParamMap.getAll('pocs');

    const providersId = this.route.snapshot.queryParamMap.get('provider');

    if (this.miniMap == 1 || (pocs && pocs.length > 0)) {
        this.SetMiniMap(true);
    }

    if (providersId) {
      this.SetMiniMap(true);
      this.sideRightBar.getProvider(providersId);
    }

    if (serviceCode != null && serviceCode != "" && (entityCode == "" || district == null) && (county == null || county == "")) {
      this.searchByGeoLocationInit = false;
      this.pointsParams.searchType = 3;
      this.service.SearchService(serviceCode).subscribe(buscar => this.ResizeIcon(buscar, 0));
    }
    else if (entityCode != null && entityCode != "" && district != null && district != "" && county != null && county != "") {
      this.searchByGeoLocationInit = false;
      this.pointsParams.searchType = 4;
      this.service.SearchEntity(district, county, entityCode).subscribe(buscar => this.ResizeIcon(buscar, 0));
    }
    else if (serviceCode != null && serviceCode != "" && district != null && district != "" && county != null && county != "") {
      this.searchByGeoLocationInit = false;
      this.fillPointsParams(null, null, district, county, null, null, null, null, serviceCode, 4, null, 1, 100, "", 1);
      //this.pointsParams.searchType = 4;
      this.mapaService.getPointsByLocals(this.pointsParams).subscribe(res => this.ResizeIcon(res, this.pointsParams.searchType));
      //this.service.SearchEntity(district, county, serviceCode).subscribe(buscar => this.ResizeIcon(buscar, 0));
    }
    else if (poc != null && poc != "" && service != null && service != "") {
      this.searchByGeoLocationInit = false;
      this.pointsParams.searchType = 5;
      this.service.SearchPocService(poc, service).subscribe(buscar => this.ResizeIconPocService(buscar, service));
    }
    else if (point != null && point != "") {
      this.searchByGeoLocationInit = false;
      this.pointsParams.searchType = 4;
      this.service.SearchPoint('PT', point).subscribe(buscar => this.ResizeIcon(buscar, 0));
    }
    else if (pc != null && pc != "") {
      this.searchByGeoLocationInit = false;
      this.pointsParams.searchType = 4;
      this.service.SearchPoc('pt', pc).subscribe(buscar => this.ResizeIcon(buscar, 0, true));
    }
    else if (et != null && et != "") {
      this.searchByGeoLocationInit = false;
      this.pointsParams.language = this.GetLanguage();
      this.pointsParams.latitude = this.userPosition.latitude;
      this.pointsParams.longitude = this.userPosition.longitude;
      this.pointsParams.pointName = '';
      this.pointsParams.serviceCode = et;
      this.pointsParams.area = 2500;
      this.pointsParams.searchType = 4;
      this.pointsParams.zoom = this.zoom;
      this.pointsParams.pageNumber += 1;
      this.textSearch = text;

      this.zoom = 14;

      this.mapaService.getPointsByLocals(this.pointsParams)
        .subscribe(res => {
          this.ResizeIcon(res, 0);
        });
    }
    else if (poc != null && poc != "" && entity != null && entity != "") {
      this.searchByGeoLocationInit = false;
      this.pointsParams.searchType = 4;
      this.service.SearchPocEntity('PT', poc, entity).subscribe(buscar => this.ResizeIcon(buscar, 0));
    }
    else if (pocs !== null && pocs.length > 0) {
      this.searchByGeoLocationInit = false;
      this.pointsParams.searchType = 4;
      this.sideNavRight.toggle();
      this.service.SearchPocs('PT', pocs).subscribe(buscar => this.ResizeIcon(buscar, 0, false, pocs));
    }
    else if (text != null && text != "") {
      this.searchByGeoLocationInit = false;
      this.pointsParams.language = this.GetLanguage();
      this.pointsParams.latitude = this.userPosition.latitude;
      this.pointsParams.longitude = this.userPosition.longitude;
      this.pointsParams.pointName = text;
      this.pointsParams.area = 2500;
      this.pointsParams.searchType = 5;
      this.pointsParams.zoom = this.zoom;
      this.pointsParams.pageNumber += 1;
      this.textSearch = text;

      this.zoom = 14;

      this.mapaService.getPointsByLocals(this.pointsParams)
        .subscribe(res => {
          this.ResizeIcon(res, 2);
        });
    }
    else {
      this.searchByGeoLocationInit = false;
      this.pointsParams.pageNumber = 0;
      this.ListarPontos(this.userPosition.latitude, this.userPosition.longitude, '', '', '', '', '', this.radius, 1, 1);
    }
  }*/

 /* ResizeIcon(points: Points[], tipoBusca: number, isAzFunc: boolean = false, pocs?: string[]) {
   
    this.lastTipoBusca = tipoBusca;
    let mapPoints: Points[] = points["points"];

    if (isAzFunc){
      mapPoints = points;
    }

    if (points !== null)
      this.listDigitalServices = points["digitalServices"];

    if (this.sideRightBar.filterUseGeoLocation && mapPoints && mapPoints.length == 0) {
      this.Loading(false);
      
      if (this.seeMore) {
        this.seeMore = false;
        return;
      }

      this.nenhumPontoEncontrado = true;
      this.citizenPoints = [];
      return;
    }

    if ((this.pointsParams.searchType == 3 || this.pointsParams.searchType == 4 || this.pointsParams.searchType == 5) && mapPoints && mapPoints.length == 0) {
      this.Loading(false);
      this.nenhumPontoEncontrado = true;
      this.openDialog("Nenhum ponto encontrado");
      return
    }

    if (tipoBusca == 2 && points !== null && mapPoints.length == 0) {
      this.Loading(false);
      this.nenhumPontoEncontrado = true;
      return
    }
    else if (tipoBusca == 1 && points !== null && mapPoints.length != 0) {
      this.radius = 2500;
      this.zoom = this.searchByZoomChanged ? this.zoom : 14;
      // this.filteroptions.area = 5;
      this.nenhumPontoEncontrado = false;
    }
    else if (tipoBusca == 2 && points !== null && mapPoints.length != 0) {
      this.radius = 2500;
      // this.filteroptions.area = 2.5;
      this.nenhumPontoEncontrado = false;
    }
    else if (tipoBusca == 6 && points !== null && mapPoints.length != 0) {
      this.radius = 2500;
      // this.filteroptions.area = 2.5;
      this.nenhumPontoEncontrado = false;
    }
    else if (tipoBusca == 7 && points !== null && mapPoints.length != 0) {
      this.radius = 2500;
      // this.filteroptions.area = 2.5;
      this.nenhumPontoEncontrado = false;
    }

    //Set User point
    //this.myPoint.lat = this.lat;
    //this.myPoint.lng = this.lng;

    //Get Pins to Google Map    
    if ((this.selectMethodInitial || this.searchByZoomChanged) && tipoBusca != 6) 
    {
      if (pocs && pocs.length > 0) {
        this.mapaService.getCoordinatesByPocs(pocs).subscribe(
          res => {
            this.getCoordinates(res)
          });
      } else if (tipoBusca == 0 && mapPoints && mapPoints.length == 1) //Service SearchPoc
      {
        let coordinate: Coordinates[] = 
                        [{
                          id: mapPoints[0].id, 
                          latitude: mapPoints[0].latitude, 
                          longitude: mapPoints[0].longitude, 
                          icon: "", 
                          iconUrl: mapPoints[0].iconUrl, 
                          distanceOfMyGeoLocation: 0 
                        }];
          
        this.getCoordinates(coordinate);
      }
      else {
        this.mapaService.getCoordinatesByLocals(this.pointsParams).subscribe(
          res => {
            this.getCoordinates(res)
          });
      }
      
    }

    if (points !== null)
      if (this.selectMethodInitial || this.searchByZoomChanged)
        this.citizenPoints = mapPoints;
      else
        this.citizenPoints.push(...mapPoints);

    this.searchByZoomChanged = false;

    if (this.citizenPoints != null) 
    {
      this.citizenPoints.forEach(element => 
          {
            this.point.lat = element.latitude;
            this.point.lng = element.longitude;
            element.distanceOfMyGeoLocation = this.GetDistance(this.myPoint, this.point);
          });

      //Sort by selected filter
      this.OrdenarpontosCidadao(this.citizenPoints);
    }

    //Open right list of locations
    this.sideRightBar.AbrirListaLateralLocais();

    if (!this.sideNavRight.opened && this.miniMap != 1 && !pocs) {
      if (!this.mobile) {
        this.sideNavRight.toggle();
      }
    }

    if (this.miniMap == 1 || (pocs && pocs.length > 0)) {
      this.SetMiniMap(true);
    }

    if (this.pointsParams.searchType != 1 || (this.pointsParams.searchType == 1 && this.pointsParams.type != '')) {

      if (this.citizenPoints && this.citizenPoints.length > 0) {
        //add in 19/05/2020 pedido por Pedro Viana
        //begin

        //this.latMap = this.citizenPoints[0].latitude;
        //this.lngMap = this.citizenPoints[0].longitude;

        //this.centerMap(this.latMap, this.lngMap);

        this.zoom = 14;
      }
      this.circle = false;
    }
    else {
      this.circle = true;
    }

    this.Loading(false);
  }*/

  /*getCoordinates(coordinates: Coordinates[]) {
    this.coordinates =  coordinates;        
    this.coordinates.forEach(element => {

      if (element.iconUrl.indexOf("blueEmpty") > 0) {
        element.icon = {
          url: element.iconUrl,
          scaledSize: {
            height: 35,
            width: 25
          }
        };
      }
      else {
        element.icon = {
          url: element.iconUrl,
          scaledSize: {
            height: 50,
            width: 35
          }
        };
      }

      this.point.lat = element.latitude;
      this.point.lng = element.longitude;

      //element.distanceOfMyGeoLocation = this.GetDistance(this.myPoint, this.point);
    });    
    this.addAltToImg();
    this.ResizeMap();
  }*/



  /*OrdenarpontosCidadao(citizenPoints: Points[]) {

    switch (this.pointsParams.filterType) {
      //Mais próximo
      case 1:
        this.citizenPoints = citizenPoints.sort((a, b) => a.distance <= b.distance ? -1 : 1);
        break;
      //Serviços abertos
      case 2:
        this.citizenPoints = citizenPoints.sort((a, b) => a.open <= b.open ? -1 : 1);
        break;
      //Tempo espera
      case 3:
        this.citizenPoints = citizenPoints.sort((a, b) => a.waitingTime <= b.waitingTime ? -1 : 1);
        break;
      //Mais rápido
      case 4:
        this.citizenPoints = citizenPoints.sort((a, b) => a.faster <= b.faster ? -1 : 1);
        break;
      //Tempo de carro
      case 5:
        this.citizenPoints = citizenPoints.sort((a, b) => a.drivingTime <= b.drivingTime ? -1 : 1);
        break;
      default:
        return;
    }
  }*/


  /*ClearHighLightAllMarker() {
    if (this.coordinates != null) {

      this.coordinates.forEach(element => {
        if (element.iconUrl.indexOf("blueEmpty") > 0) {
          element.icon = {
            url: element.iconUrl,
            scaledSize: {
              height: 35,
              width: 25
            }
          };
        }
        else {
          element.icon = {
            url: element.iconUrl,
            scaledSize: {
              height: 50,
              width: 35
            }
          };
        }
      });
    }
  };*/

  /*ResizeIconPocService(pocservice: any, serviceId: string) {

    this.citizenPoints[0] = pocservice.ponto;

    if (this.citizenPoints[0].iconUrl.indexOf("blueEmpty") > 0) {
      this.citizenPoints[0].icon = {
        url: this.citizenPoints[0].iconUrl,
        scaledSize: {
          height: 35,
          width: 25
        }
      }
    }
    else {
      this.citizenPoints[0].icon = {
        url: this.citizenPoints[0].iconUrl,
        scaledSize: {
          height: 50,
          width: 35
        }
      }
    };

    if (this.citizenPoints.length > 0) {

      //this.latMap = this.userPosition.latitude;
      //this.lngMap = this.userPosition.longitude;

      this.centerMap(this.userPosition.latitude, this.userPosition.longitude);
    }

    this.circle = false;
    this.sideNavRight.toggle();
    this.sideRightBar.CarregarPocService(pocservice, +serviceId);
    this.Loading(false);
  }*/


 /* MostrarInformacoes(id: number) {

    var point = this.citizenPoints.find(s => s.id === id);

    if (point === null || point === undefined)
      this.getUnlistedPointById(id);

    if (point === null || point === undefined)
      return;

    //this.highLightIconOnMap(point);

    this.gaService.event.emit({
      category: 'Marker',
      action: 'Click',
      label: point.title,
      value: point.id
    });

    //center map and manIcon
    //this.latMap = point.latitude;
    //this.lngMap = point.longitude;
    this.centerMap(point.latitude, point.longitude);

    this.sideRightBar.VoltarEstadoOriginal();
    this.sideRightBar.Informacoes(false, point.id, this.userPosition.latitude, this.userPosition.longitude, true);

  }*/

  /*private getUnlistedPointById(id): void {
    if (this.citizenPoints.some(point => point.id === id))
      return;
    this.mapaService.getPointByLocal({ ...this.pointsParams, id }).subscribe(res => {
      if (res && res['points'] && res['points'].length) {
        const point = res['points'][0];
        point.distanceOfMyGeoLocation = this.GetDistance(this.myPoint, { lat: point.latitude, lng: point.longitude });
        this.citizenPoints.push(point);
        this.MostrarInformacoes(point.id);
      }
    }
    );
  }*/

  resetUrl() {
    window.history.pushState(null, document.title, '/');
  }

  /*public fillPointsParams(paramCategory: string, paramPointName: string, paramDistrict: string, paramCounty: string, paramParish: string,
                          paramArea: number, paramLatitude: number, paramLongitude: number, paramServiceCode: string, paramSearchType: number,
                          paramZoom: number, paramPageNumber: number, paramRowsPage: number, 
                          paramType: string, paramFilterType: number){

    this.pointsParams.language = this.GetLanguage();
    this.pointsParams.category = paramCategory;
    this.pointsParams.pointName = paramPointName;
    this.pointsParams.district = paramDistrict;
    this.pointsParams.county = paramCounty;
    this.pointsParams.parish = paramParish;
    this.pointsParams.area = paramArea;
    this.pointsParams.latitude = paramLatitude;
    this.pointsParams.longitude = paramLongitude;
    this.pointsParams.serviceCode = paramServiceCode;
    this.pointsParams.searchType = paramSearchType;
    this.pointsParams.zoom = paramZoom;
    this.pointsParams.pageNumber = paramPageNumber;
    this.pointsParams.rowsPage = paramRowsPage;
    this.pointsParams.type = paramType;
    this.pointsParams.filterType = paramFilterType;
  }*/
  //#endregion
}

/*
export interface Item {
  value: number;
  viewValue: string;
}

export interface Point {
  lat: number;
  lng: number;
}

export interface PontoCidadao {
  id: number;
  titulo: string;
  descricao: string;
  lat: number;
  lng: number;
  imgPath: string;
  site: string;
  telefone: string;
  morada: string;
  email: string;
  horario: string;
  iconURL: string;
  icon: any;
  latCentral: number;
  lngCentral: number;
  distance: number;
  distanceOfMyGeoLocation: number;
  restricoes: string;
  aberto: boolean;
  tempoEspera: string;
  maisRapido: string;
  deslocacaoDeCarro: string;
  deslocacaoAPe: string;
  statusTempoEspera: string;
  image: string;
}

export interface Busca {
  tipo: number;
  distrito: number;
  municipio: number;
  freguesia: number;
  nomePonto: string;
  distritos: Item[];
  municipios: Item[];
  freguesias: Item[];
  tipos: Item[];
}

export interface ParamBusca {
  Language: string;
  Tipo: string;
  NomePonto: string;
  Distrito: string;
  Municipio: string;
  Freguesia: string;
  Area: number;
  LatitudePonto: number;
  LongitudePonto: number;
  Zoom: number;
  TipoBusca: number;
  TipoFiltro: number;
}

export interface Path {
  lat: number;
  lng: number;
}*/
