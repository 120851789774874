import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MapaComponent } from "../../mapa/mapa.component";
import { MapPointDto } from "../../mapa/models/mapPointDto";

@Component({
  selector: 'app-nearby-places',
  templateUrl: './nearby-places.component.html',
  styleUrls: ['../sidebarright.component.scss']
})
export class NearbyPlacesComponent {
  @Input() mapa: MapaComponent;
  @Input() tiposFiltro: any[] = [];
  @Input() umLocalMesmaLat: boolean;
  @Input() latPonto: number = 0;
  @Input() lngPonto: number = 0;
  @Input() tipoFiltroEscolhido: number = 1;

 // @Output() filtrarLocais = new EventEmitter();
  @Output() alteredLocaisPertoDeSiFilter = new EventEmitter();
  @Output() informacoes = new EventEmitter();
  @Output() mouseEnterRow = new EventEmitter();
  @Output() mouseLeaveRow = new EventEmitter();
  //@Output() showNextPointList = new EventEmitter();
  //@Output() clearPoints = new EventEmitter();

  /*public FiltrarLocais(value) : void {
    this.filtrarLocais.next(value);
  }*/

  public AlteredLocaisPertoDeSiFilter(value) : void {
    this.alteredLocaisPertoDeSiFilter.next(value);
  }

  public getMapPointInfoDetail(point: MapPointDto) : void {
    this.informacoes.next(point);
  }

  public MouseEnterRow(value) : void {
    this.mouseEnterRow.next(value);
  }

  public MouseLeaveRow() : void {
    this.mouseLeaveRow.next();
  }

  /*public ShowNextPointList() : void {
    this.showNextPointList.next();
  }*/

  /*public ClearPoints() : void {
    this.clearPoints.next();
  }*/

  /**Click event to change Pagination */
  private clickPage(page: number){
      this.mapa.pagination.currentPage = page;

      if (page >= 3 && page < this.mapa.pagination.maxPage){
        this.mapa.pagination.pageOne = page - 2;
        this.mapa.pagination.pageTwo = page - 1;
        this.mapa.pagination.pageThree = page;
      }
      else if (page >= 4 && page == this.mapa.pagination.maxPage){
          this.mapa.pagination.pageOne = page - 3;
          this.mapa.pagination.pageTwo = page - 2;
          this.mapa.pagination.pageThree = page - 1;
        }
      
      this.mapa.setItemsPerPage();  
  }
}


