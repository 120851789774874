import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Area } from './models/area';
import { Splash } from './models/splash';
import { SplashService } from './services/splash.service';
import { UserService } from '../auth/user.service';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ])
    ]
    )
  ]
})
export class SplashComponent implements OnInit, OnDestroy {

  splash: Splash;
  area: Area;
  errorMessage: string;
  image: any;
  selectedArea: number;
  isLastArea: boolean = false;

  constructor(private splashService: SplashService,
    private sanitizer: DomSanitizer,
    private userService: UserService) { }

  modal: any;
  modalContent: any;

  header: any;
  footer: any;
  mainContainer: any;
  covidBanner: any;
  imageExists: boolean = false;

  hideWidgetsInterval: any;

  step: number = 1;

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.closeModal();
  }

  ngOnInit() {

    if (!this.checkIfOpen())
      return;

    this.keepWidgetsClosed();

    /*this.splashService.getSplashActive()
      .subscribe(
        res => this.getSplashActive(res),
        error => this.errorMessage);*/
  }

  ngOnDestroy() {
    clearInterval(this.hideWidgetsInterval || 0);
  }

  getSplashActive(res: Splash) {

    if (res.areas === null) {
      this.closeModal();

      return;
    }

    this.splash = res;

    this.image = this.sanitizer.bypassSecurityTrustResourceUrl(this.splash.image);
    this.image.name = 'iniciativa Não paramos. Estamos ON';

    this.openModal();

    if (res.areas !== null && this.splash.areas.length > 0) {
      var area = this.splash.areas.slice(0, 1).shift();
      this.getAreaData(area.id);
    }
    else
      this.closeModal();
  }

  checkIfOpen(): boolean {
    let storeModal: string = localStorage.getItem('storeModal');
    //storeModal = null;
    if (storeModal !== 'true' && this.getBrowserName() !== 'ie') {
      localStorage.setItem('storeModal', 'true');
      this.hideWidgets();
      return true;
    }
    else
      return false;
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  goToNextArea() {
    var index = this.splash.areas.findIndex(s => s.id === this.selectedArea);

    if (this.isLastArea)
      this.closeModal();
    else {
      var nextArea = this.splash.areas[index + 1];
      this.getAreaData(nextArea.id);
    }
  }

  getAreaData(idArea: number) {
    this.selectedArea = idArea;
    this.area = this.splash.areas.find(s => s.id === idArea);

    this.checkIsLastArea();
  }

  checkIsLastArea() {
    var index = this.splash.areas.findIndex(s => s.id === this.selectedArea);
    this.isLastArea = this.splash.areas.length - 1 === index;
  }

  openModal() {
    this.modal = document.getElementById("myModal");
    this.header = document.getElementsByTagName("header")[0];
    this.footer = document.getElementsByTagName("footer")[0];
    this.covidBanner = document.getElementById("covidBanner");
    this.mainContainer = document.getElementById("mainContainer");

    this.step = 1;

    this.mainContainer.style.opacity = "0.2";
    this.covidBanner.style.opacity = "0.2";
    this.modal.style.display = "block";
  }

  closeModal() {
    if (this.splash == undefined)
      return;

    clearInterval(this.hideWidgetsInterval || 0);
    const widgets = document.getElementById('widgetAssist');
    widgets.style.display = 'block';
    this.header.style.opacity = "1";
    this.footer.style.opacity = "1";
    this.mainContainer.style.opacity = "1";
    this.covidBanner.style.opacity = "1";
    this.modal.style.display = "none";

  }

  goCovid() {
    document.location.href = "https://eportugal.gov.pt/covid-19";
  }

  onModalSwipe(event: any) {
    if (event.additionalEvent === "panleft" && this.step < 3)
      this.step++;
    else if (event.additionalEvent === "panright" && this.step > 1)
      this.step--;
  }

  keepWidgetsClosed(msTime = 100) {
    this.hideWidgetsInterval = setInterval(() => {
      this.hideWidgets();
    }, msTime);
  }

  hideWidgets() {
    const widgets = document.getElementById('widgetAssist');
    widgets.style.display = 'none';
  }

  //TODO: Será implementado nas funcionalidades de acessibilidade
  // onModalSwipe(event: any) {
  //   if (event.additionalEvent === "panleft" && this.step < 3)
  //     this.step++;
  //   else if (event.additionalEvent === "panright" && this.step > 1)
  //     this.step--;
  // }
}
