import { Component, ElementRef, Inject, Injectable, Input, OnInit, ViewChild } from '@angular/core';

import { Chart } from 'chart.js';
import '../../../assets/js/chart.rounded.js';

import { ServiceDemands } from './models/service-demands';
import { BaseService } from '../services/base.service';
import { MAT_DIALOG_DATA, MatIconRegistry } from '@angular/material';
import { ModalService } from '../modal/services/modal.service';
import { DivisionServiceListStatisticTicketDto } from './models/divisionServiceListStatisticTicketDto';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})


export class ChartComponent extends BaseService implements OnInit {
  @Input() chartData: ServiceDemands;

  @ViewChild('barCanvas', {static: false}) private barCanvas: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DivisionServiceListStatisticTicketDto,
    private modalService: ModalService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) { 
    super(); 
    iconRegistry.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg'));
  
  }

  chart = Chart;
  
  hours: number[];
  selectedDay: number;


  ngOnInit() {
    //this.selectedDay = new Date().getDay();
    //this.showChart(this.selectedDay);
  }

  ngAfterViewInit() {
    this.selectedDay = new Date().getDay();
    this.showChart(this.selectedDay);
  }

  changeSelectedDay(day: number) {
    this.selectedDay = day;
    this.showChart(day);
  }
  
  showChart(day: number) {

    this.destroyPreviousDataIfExists();

    const ticketStatisticsDay = this.data.statistics.find(s => s.day == day);

    let x = 0;
    /*ticketStatisticsDay.hours.forEach(s => {
      if (s % 3)
    })
    for (var _i = 0; _i < ticketStatisticsDay.hours.length; _i++) {

      const val = parseInt(this.data.hours[_i]);
      if (val % 3) {
        this.data.hours[_i] = "";
      }
    }*/

    this.chart = new Chart(this.barCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: ticketStatisticsDay.hours,

        datasets: [
          {
            data: ticketStatisticsDay.tickets,
            backgroundColor: "#034AD8",
          }
        ]
      },
      options: {
        cornerRadius: 80,
        fullCornerRadius: true,
        stackedRounded: true,
        legend: { display: false },
        maintainAspectRatio: true,
        with: "180px",
        height: "360px",
        title: {
          display: true,
        },
        scales: {
          xAxes: [{
            stacked: false,
            gridLines: {
              display: false,
              borderWidth: 0,
              drawBorder: false
            }
          }],
          yAxes: [{
            stacked: false,
            ticks: {
              beginAtZero: true,
              display: false,
            },
            gridLines: {
              display: true,
              borderWidth: 0,
              drawBorder: false
            }
          }]
        },
        tooltips: {
          enabled: false
        }
      }
    });
  }

  private destroyPreviousDataIfExists() {
    if (this.chart.clear && this.chart.destroy) {
      this.chart.clear();
      this.chart.destroy();
    }
  }
}
