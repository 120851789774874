import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() tableData: any;
  @Input() selectedDay: string;
  @Input() rowHeader: string;

  headers = [];
  data = [];
  constructor() { }

  ngOnInit() {
    this.data = this.tableData;
    this.prepareData();
  }

  prepareData() {
    this.headers = [];
    this.data = [];
    const valueIndex = this.tableData.headers.findIndex(s => s === this.selectedDay);
    this.headers.push(this.tableData.headers[0]);
    this.headers.push(this.tableData.headers[valueIndex]);
  }
}
