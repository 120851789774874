import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { SidebarrightComponent } from '../sidebarright/sidebarright.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-filteroptions',
  templateUrl: './filteroptions.component.html',
  styleUrls: ['./filteroptions.component.scss']
})

export class FilteroptionsComponent implements OnInit {
  @Input() sideNav;
  @Input() mapa;
  @Input() sideNavRight: any;
  @Input() sideRightBar: SidebarrightComponent;
  @Input() sideFilterBar: any;
  @Input() set selectedLanguage(language: string) {
    if (language) {
      this._selectedLanguage = language;
      localStorage.setItem("languageSelected", language);
      this.translate.use(language);
    }
  }

  get selectedLanguage(): string {
    return this._selectedLanguage;
  }

  public _selectedLanguage: string;




  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private translate: TranslateService) {
    iconRegistry.addSvgIcon(
      'menuIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icons8-menu.svg'));
    iconRegistry.addSvgIcon(
      'lupaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/glass-browser.svg'));
    iconRegistry.addSvgIcon(
      'userIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/userIcon.svg'));
    iconRegistry.addSvgIcon(
      'filterIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/filter_icon.svg'));
    iconRegistry.addSvgIcon(
      'pinIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pin_location.svg'));
    iconRegistry.addSvgIcon(
      'arrowBack',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow_back.svg'));

      let storeLanguage = localStorage.getItem('selectedLanguage');
      this.languageSelected = storeLanguage ? storeLanguage.slice(0, 2) : 'pt';
  }

  mouseoverBusca: boolean = false;
  mouseoverUser: boolean = false;
  showFilter: boolean = true;
  
  languageSelected: string;
  
  area: number = 2.5;

  // item: Item[] = [
  //   {value:1, viewValue:'Categorias'},
  //   {value:2, viewValue:'Eventos de vida'},
  //   {value:3, viewValue:'Serviços'}
  // ]


  AbrirBusca() {
    //console.log("aqui")
    this.sideRightBar.AbrirBusca();

    if (!this.sideNavRight.opened) {

      if (this.sideNav.opened)
        this.sideNav.toggle();

      this.sideNavRight.toggle();
    }

    this.sideRightBar.LimparBusca();

  }

  showFilterBar() {
    this.showFilter = !this.showFilter
  }

  /*AbrirUser() {
    this.sideRightBar.AbrirUser();

    if (!this.sideNavRight.opened) {

      if (this.sideNav.opened)
        this.sideNav.toggle();

      this.sideNavRight.toggle();
    }

  }*/

  AbrirListaLateralLocais() {
    this.sideRightBar.InitializeSidebar();

    if (!this.sideNavRight.opened) {

      const menu = document.getElementsByClassName('wa__rootmenu')[0];
      const menuBot = document.getElementsByClassName('wa__bottom')[0];
      menu.classList.add('open_sidenav');
      menuBot.classList.add('open_sidenav');

      if (this.sideNav.opened)
        this.sideNav.toggle();

      this.sideNavRight.toggle();
    }
  }

  OpenLeftBar() {
    if (this.sideNavRight.opened)
      this.sideNavRight.toggle();

    this.sideNav.toggle();
  }

  GetLanguage(): string {
    return this.languageSelected;
  }

  ChangeLanguage(language): void {
    localStorage.setItem("languageSelected", language);
    this.translate.use(language);

    window.location.reload();

    //this.mapa.MakeMarkerRefresh();
  }

  ReposicionarGeolocalizacao() {
    this.mapa.reajustarGeolocalizacao = true;
    this.mapa.getPosition();
  }

  AlterarArea() {
    this.mapa.UpdateRadius(this.area * 1000);
  }

  ngOnInit() {
    //console.log(this.mapa)
  }
}

export interface Item {
  value: string;
  viewValue: string;
}

