import { MapPointDto } from "./mapPointDto";

export class Pagination{
    currentPage: number;
    maxPage: number; 
    itemsPerPage: number;
    items: MapPointDto[];
    pageOne: number;
    pageTwo: number;
    pageThree: number;

    constructor(){
        this.currentPage = 1;
        this.itemsPerPage = 4;
        this.maxPage = 1;
        this.items = [];
        this.pageOne = 1;
        this.pageTwo = 2;
        this.pageThree = 3;
    }
}