<!--Menu Explorar e Botão Sobre devem ser refeitors Ruth 23/05/2023-->
<!-- START BUSCA -->
<!--<app-explore-panel
  (goBack)="VoltarLocal()"
  (about)="showAbout()"
  (onSearch)="searchPoI($event)"
  [poiExist]="mapa.nenhumPontoEncontrado"
  [mapArea]="mapa.filteroptions.area"
  [hidden]="!mostrarBusca || !hasPermission('search')"
></app-explore-panel>-->

<!-- SOBRE -->
<!--<div [hidden]="!mostrarSobre">
    <div class="explore-action-btn">
        <button class="btn-back voltar" (click)="AbrirBusca()">
            <mat-icon class="btn-back-arrow" svgIcon="arrowBack"></mat-icon>
            <span>{{'VOLTAR' | translate}}</span>
        </button>

    </div>

    <div class="explore">
        <div class="explore-title">
            <h2>{{ 'Sobre' | translate }}</h2>
        </div>
        <div class="about-text">
            <p>{{ 'sidebarright_about' | translate }}</p>
            <p>{{ 'O Mapa de Cidadão disponibiliza' | translate }} {{totalLocaisServicos.locais}} {{ 'locais onde se prestam' | translate }}
                {{totalLocaisServicos.servicos}} {{ "_serviços_" | translate }}</p>
            <p class="updated">{{ 'Atualizado em' | translate }}: 08/09/2021</p>
        </div>
        <div class="about-actions">
            <button class="about-actions-btn" (click)="ShowTutorial()" id="btnTutorialNavegacao">
                <span *ngIf="notificarTutorial?.notification" class="badge badge-info">{{ 'Novo' | translate }}</span>
                <p>{{ 'Tutorial de navegação' | translate }}</p>
            </button>
            <button class="about-actions-btn" (click)="OpenAccessibilityDeclaration()">
                <p>{{ 'Declaração de acessibilidade' | translate }}</p>
            </button>
        </div>
        <div class="about-info">
            <p>{{ 'Pode descarregar a aplicação do Mapa de Cidadão para Android e iOS' | translate }}</p>
        </div>

        <div class="about-apps">
            <div class="col-5">
                <a href="https://play.google.com/store/apps/details?id=pt.ama.mapadocidadao">
                    <img src="assets/icons/google-play.png" alt="{{ 'Ir para google play' | translate }}" width="200" height="100">
                </a>
            </div>

            <div class="col-5">
                <a href="https://apps.apple.com/us/app/mapa-de-cidad%C3%A3o/id966526205">
                    <img src="assets/icons/apple-app.svg" alt="{{ 'Ir para app store' | translate }}" width="180" height="100">
                </a>
            </div>
        </div>
    </div>
</div>-->



<!-- START LIST MAP'S POINTS -->
<div [hidden]="!mapa.showListMapPoints"
    class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 fundo"
    style="overflow-x: scroll;">
    <div [hidden]="!mapa.showListMapPoints">
      <div class="btn-group group-list-map-points" role="group">
        <button type="button" class="btn botao-voltar"
          (click)="this.mapa.sideNavRight.toggle()" aria-label="pressione para ocultar a janela locais perto de si" title="{{ 'sidebarright.buttonOcultar' | translate }}">
          <mat-icon svgIcon="arrowForward" aria-hidden="true" class="arrow-forward"></mat-icon>
        </button>
        <button type="button" class="btn btn-nearbyPlaces" 
          (click)="mapa.getOrganizationNearby()" aria-label="pressione para mostrar locais perto de si" title="{{ 'sidebarright.buttonNearby' | translate }}">
          <mat-icon svgIcon="nearbyPlaces" aria-hidden="true" class="nearby-places"></mat-icon>
        </button>
      </div>
      <!--<div class="col-6 button-div">
        <button class="options-button no-margin"
          attr.aria-label="{{ 'sidebarright.buttonFilter' | translate }}" (click)="AbrirBusca()" *ngIf="hasPermission('search')">
          <span class="buttonFilter-span">{{'sidebarright.buttonFilter' | translate }}</span>
          <mat-icon class="buttonFilter-icon" svgIcon="menu" aria-hidden="true" ></mat-icon>
        </button>
        <button class="options-button no-margin"
          attr.aria-label="{{ 'Filtro' | translate }}" (click)="showAbout()" *ngIf="!hasPermission('search')">
          <span class="buttonAbout-span">{{ 'SOBRE' | translate }}</span>
        </button>
      </div>-->

    </div>

    <app-nearby-places
        [mapa]="mapa"
        [tiposFiltro]="tiposFiltro"
        [umLocalMesmaLat]="umLocalMesmaLat"
        [latPonto]="latPonto"
        [lngPonto]="lngPonto"
        [tipoFiltroEscolhido]="tipoFiltroEscolhido"
        (informacoes)="getMapPointInfoDetail($event)"
        (mouseEnterRow)="MouseEnterRow($event)"
        (mouseLeaveRow)="MouseLeaveRow()"
    ></app-nearby-places>

    <!--Grupos e Layers-->
    <div *ngIf="showFilterByGroup" style="margin-top: 30px;">
        <div class="life-events-title">
            <h2>
              {{'groups' | translate}}
            </h2>
        </div>
        <div class="divGrupos">
            <ul class="ulCategoria col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding: 0;">
                <li role="button" tabindex="0" [ngClass]="{'li-active': group.isActive }" *ngFor="let group of mapa.groups; let i = index" class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 liCategoria box pt20"
                    (click)="mapa.searchPointsByGroupOrNearby(group)"
                    style="margin-left: 4px; overflow: hidden;" [hidden]="!mostrarMaisGrupos && i > 3">
                    <img aria-hidden="true" src="{{group.image}}"  class="buttonCategoria" alt="">
                    <!--<span class="liSpan" style="line-height: 17px; margin-top: 1px;">{{group.name}}</span>-->
                </li>
            </ul>
        </div>

        <!--Mostrar mais grupos desativado-->
        <!--<div class="col-12" style="padding: 0">
            <div [hidden]="mapa.groups.length <= 4" class="post-actions">
                <div
                    [hidden]="mostrarMaisGrupos"
                    class="divButton col-6 pr0"
                    style="cursor: pointer; margin-bottom: 20px;padding-left: 5px"
                    (click)="mostrarMaisGrupos = true"
                >
                    <span class="liSpan mt4">
                        {{'see_more' | translate}}
                    </span>
                    <mat-icon svgIcon="arrowDown" aria-hidden="true" class="arrow-down"></mat-icon>
                </div>

                <div
                    [hidden]="!mostrarMaisGrupos"
                    class="divButton col-6 pr0"
                    style="cursor: pointer; margin-bottom: 20px;padding-left: 5px"
                    (click)="mostrarMaisGrupos = false"
                >
                    <span class="liSpan mt4">
                        {{'see_less' | translate}}
                    </span>
                    <mat-icon svgIcon="arrowUp" aria-hidden="true" class="arrow-up"></mat-icon>
                </div>

                <div *ngIf="showClearPointsButton" class="divButton col-6 pr0"
                    style="cursor: pointer; margin-bottom: 20px;display: flex; justify-content: flex-end;padding-right: 4px;">
                    <span class="liSpan mt4" (click)="clearPoints()">{{'clear' | translate}}</span>
                </div>
            </div>
        </div>-->
    </div>

    <!-- TOP SERVIÇOS DIGITAIS desativado-->
    <!--<div
        *ngIf="digitalServices && digitalServices.length !== 0"
        [hidden]="mapa.listDigitalServices && mapa.listDigitalServices.length > 0 || !hasPermission('digital-services-list')"
    >
        <div class="digital-services-title">
            <h2>
                {{'top-list-service' | translate}}
            </h2>
        </div>
            <ul class="digital-services-list">
                <li *ngFor="let itens of digitalServices; let i = index">
                    <button class="digital-services-btn" id="{{itens.id}}" title="{{ 'abre no Portal ePortugal' | translate }}" attr.aria-label="{{itens.name}}"
                        (click)="openUrl(itens.link)">
                        <div class="digital-services-btn-icon">
                            <mat-icon svgIcon="globle" aria-hidden="true"></mat-icon>
                        </div>
                        <div class="digital-services-btn-title">
                            <p>{{itens.name}}</p>
                        </div>
                        <div>
                            <mat-icon class="arrow-east" svgIcon="arrowEast" aria-hidden="true"></mat-icon>
                        </div>
                    </button>
                </li>
            </ul>

        <div class="col-12" style="padding: 0">
            <div class="post-actions">
                <div
                    *ngIf="!showLessTopDigitalServices"
                    [hidden]="mostrarMaisTopDigitalServices"
                    class="divButton col-6 pr0"
                    style="cursor: pointer; margin-bottom: 20px;padding-left: 5px"
                    (click)="getTopDigitalServices()"
                >
                    <span class="liSpan mt4">
                       {{'see_more' | translate}}
                    </span>
                    <mat-icon svgIcon="arrowDown" aria-hidden="true" class="arrow-down"></mat-icon>
                </div>

                <div
                    *ngIf="showLessTopDigitalServices"
                    class="divButton col-6 pr0"
                    style="cursor: pointer; margin-bottom: 20px;padding-left: 5px"
                    (click)="seeLessTopDigitalServices()"
                >
                    <span class="liSpan mt4">
                        {{'see_less' | translate}}
                    </span>
                    <mat-icon svgIcon="arrowUp" aria-hidden="true" class="arrow-down"></mat-icon>
                </div>

                <div *ngIf="topDigitalServicesPage > 1" class="divButton col-6 pr0"
                    style="cursor: pointer; margin-bottom: 20px;display: flex; justify-content: flex-end;padding-right: 4px;">
                    <span class="liSpan mt4" (click)="clearTopDigitalServices()">{{'clear' | translate}}</span>
                </div>
            </div>
        </div>
    </div>-->

    <!-- SERVIÇOS DIGITAIS desativado-->
    <!--<div [hidden]="!hasPermission('digital-services-list')" *ngIf="mapa.listDigitalServices && mapa.listDigitalServices.length !== 0">
        <div class="life-events-title">
            <h2>
                {{'list-service' | translate}}
            </h2>
        </div>
        <ul class="digital-services-list">
            <li *ngFor="let itens of mapa.listDigitalServices; let i = index"
                [hidden]="!mostrarMaisDigitalServices && i > 3">
                <button class="digital-services-btn" id="{{itens.id}}" title="abre no Portal ePortugal" attr.aria-label="{{itens.name}}"
                    (click)="openUrl(itens.link)">
                    <div class="digital-services-btn-icon">
                        <mat-icon svgIcon="globle" aria-hidden="true"></mat-icon>
                    </div>
                    <div class="digital-services-btn-title">
                        <p>{{itens.name}}</p>
                    </div>
                    <div>
                        <mat-icon class="arrow-east" svgIcon="arrowEast" aria-hidden="true"></mat-icon>
                    </div>
                </button>
            </li>
        </ul>

        <div [hidden]="mapa.listDigitalServices.length <= 4" class="post-actions">
            <div [hidden]="mostrarMaisDigitalServices" class="col-11 pr0 pl-2"
             (click)="mostrarMaisDigitalServices = true;">
                <div class="divButton">
                    <span class="liSpan mt4">{{'see_more' | translate}}</span>
                    <mat-icon svgIcon="arrowDown" aria-hidden="true" class="arrow-down"></mat-icon>
                </div>
            </div>
            <div [hidden]="!mostrarMaisDigitalServices" class="col-11 pr0 pl-2"
             (click)="mostrarMaisDigitalServices = false;">
                <div class="divButton">
                    <span class="liSpan mt4">{{'see_less' | translate}}</span>
                    <mat-icon svgIcon="arrowUp" aria-hidden="true" class="arrow-down"></mat-icon>
                </div>
            </div>
        </div>
    </div>-->


    <!--<div class="display-b">
        <ul class="ulCategoria col-12">
        </ul>
    </div>-->
</div>


<!-- END LIST MAP'S POINTS -->


<!-- START DETAIL MAP'S POINTS -->

<div [hidden]="!mostrarLocal" class="col-md-12 fundo" on-scroll="MostrarGoTopButton(1)" id="loacalpertodesiaposclick">

    <div id="titulo">

        <div class="col-md-2" [style.marginBottom.px]="10">
            <button class="btn botao-voltar" style="width: auto; display: flex;" (click)="InitializeSidebar()" type="button" title="{{'VOLTAR' | translate}}">
                <mat-icon class="arrow-back" svgIcon="arrowBack" aria-hidden="true"></mat-icon>
                <span style="padding-top:2px; padding-left: 2px;">{{'VOLTAR' | translate}}</span>
            </button>
        </div>
        <div class="col-md-10" class="image-container" aria-hidden="true">
            <img class="" mat-card-avatar class="image-content" [src]="image" aria-hidden="true" alt="{{ 'imagem local partida' | translate }}">
            <!--<mat-icon svgIcon="{{ image }}" aria-hidden="true"></mat-icon>-->
        </div>
    </div>

    <div id="btnmenuSideBar">
        <ul class="btn-menu">
            <li [attr.aria-current]="mostrarInfos ? 'page' : 'false'">
                <button [ngClass]="{'botaoLocal': true, 'clicked': mostrarInfos}" (click)="MostrarInfos()">
                    <div class="btnmenuSideBar-img" aria-hidden="true">
                        <mat-icon svgIcon="info"></mat-icon>
                    </div>
                    <p class="responsiveText">{{'Informações' | translate}}</p>
                </button>
            </li>
            <li [attr.aria-current]="showListPointsOfCare ? 'page' : 'false'">
                <button
                    [disabled]='!existemEntidadesSenhas'
                    [ngClass]="{'botaoLocal': true, 'clicked': showListPointsOfCare, 'senha-disabled': !existemEntidadesSenhas}"
                    (click)="getPointsOfCare();"
                >
                    <div class="btnmenuSideBar-img">
                        <mat-icon svgIcon="ticket"></mat-icon>
                    </div>
                    <p class="responsiveText"> {{'sidebarright.buttonShowTicket' | translate}}</p>
                </button>
            </li>
            <li [attr.aria-current]="mostrarDirecoes ? 'page' : 'false'">
                <button [ngClass]="{'botaoLocal': true, 'clicked': mostrarDirecoes}" (click)="LinkDirecao()">
                    <div class="btnmenuSideBar-img" aria-hidden="true">
                        <mat-icon svgIcon="directions"></mat-icon>
                    </div>
                    <p class="responsiveText"><span>{{'Direcções' | translate}}</span></p>
                </button>
            </li>
        </ul>
    </div>

    <div class="lista">

        <!-- MOSTRAR INFOS -->

        <div class="texto-informativo-local-titulo">
            <h2>{{titulo}}</h2>
        </div>

        <div [hidden]="!mostrarInfos" class="col-md-12 margin-info">
            <div class="texto-informativo-local">
                <h3 class="contactos">{{'Contactos gerais' | translate}}</h3>
                <div class="contacts-info">

                    <!-- MORADA -->
                    <div [hidden]="!(morada != '')" class="contacts-info-content">
                        <div class="contacts-info-content-img">
                            <img attr.aria-label="{{'Morada' | translate}}" src="assets/icons/pin_adress.svg" alt="{{ 'imagem endereço pino' | translate }}">
                        </div>
                        <div class="contacts-info-content-text">
                            <a target="" href="javascript:void(0)" (click)="Direcao()">
                                <p class="linkSideBar">{{morada}}</p>
                            </a>
                        </div>
                    </div>

                    <!-- TELEFONE -->
                    <div [hidden]="!(mostrarTelefone)" class="contacts-info-content">
                        <div class="contacts-info-content-img">
                            <img attr.aria-label="{{'Telefone' | translate}}" src="assets/icons/phone.svg" alt="{{ 'imagem telefone' | translate }}">
                        </div>
                        <div class="contacts-info-content-text">
                            <a *ngFor="let tel of telefone" target="_blank" href="tel:{{tel}}">
                                <div>
                                    <p class="linkSideBar">{{tel}}</p>
                                </div>
                            </a>
                        </div>
                    </div>

                    <!-- EMAIL -->
                    <div *ngIf="emails && emails.length" class="contacts-info-content">
                        <div class="contacts-info-content-img">
                            <img attr.aria-label="{{'Email' | translate}}" src="assets/icons/emailIcon.svg" alt="{{ 'imagem email' | translate }}">
                        </div>
                        <div class="contacts-info-content-text">
                            <a *ngFor="let email of emails" target="_blank" href="mailto:{{email}}">
                                <p class="linkSideBar">{{email}}</p>
                            </a>
                        </div>
                    </div>

                    <!-- SITE -->
                    <div [hidden]="!(site != '')" class="contacts-info-content">
                        <div class="contacts-info-content-img">
                            <img alt="{{'Site' | translate}}" src="assets/icons/globle_icon.svg" >
                        </div>
                        <div class="contacts-info-content-text">
                            <a target="_blank" [href]="site.indexOf('http') == -1  ? 'https://' + site : site">
                                <p class="linkSideBar">{{site}}</p>
                            </a>
                        </div>
                    </div>

                    <!-- HORARIO -->
                    <div [hidden]="!(mostrarHorario)" class="contacts-info-content">
                        <div class="contacts-info-content-img">
                            <img alt="{{'Horario' | translate}}" src="assets/icons/clock.svg">
                        </div>
                        <div class="contacts-info-content-text">
                            <div *ngFor="let itensHorario of horario">
                                <p>{{itensHorario}}</p>
                            </div>
                        </div>
                    </div>

                    <!-- MAIS INFORMAÇÕES -->
                    <br>
                    <div [hidden]="linkEntidade === ''" class="contacts-info-content-text">
                        <a target="_blank" [href]="linkEntidade">
                            <p class="linkSideBar">{{ 'Mais Informações' | translate }}</p>
                        </a>
                    </div>
                </div>

                <div class="separador-info-local col-md-12">
                    <div class="col-md-12 barra"></div>
                </div>
            </div>
            <div class="texto-informativo-local" [hidden]="!informacao">
                <h3 class="contactos">{{'INFORMAÇÕES' | translate}}</h3>
                <span>
                    <div [innerHtml]="informacao"></div>
                </span>
            </div>
        </div>
        <!-- FIM MOSTRAR INFOS -->

        <!--MOSTRAR DIREÇÕES'-->
        <div [hidden]="!mostrarDirecoes" class="col-md-12 margin-info">
            <div class="texto-informativo-local deslocacao">

                <div tabindex="0">
                    <mat-icon aria-hidden="false" [attr.aria-label]="'de carro' | translate" class="material-icons-sharp">directions_car</mat-icon>
                    <b><span>{{'distance' | translate}}: {{drivingTime}}</span></b>
                </div>

                <div tabindex="0">
                    <mat-icon aria-hidden="false" [attr.aria-label]="'a pé' | translate" class="material-icons-sharp">directions_walk</mat-icon>
                    <b><span>{{'distance' | translate}}: {{walkingTime}}</span></b>
                </div>

                <a tabindex="0" target="" href="javascript:void(0)" (click)="LinkDirecao()" aria-label="Consultar Direções, abre uma nova janela">
                    <p class="linkSideBar" aria-hidden="true">{{ 'Consultar Direções' | translate }}</p>
                </a>
            </div>
        </div>

        <div class="alert alert-secondary" role="alert" [hidden]="!showAlertPointOfCareNotFound" style="margin-top: 10px;">
            {{'sidebarright.showAlertPointOfCareNotFound'|translate}}
        </div>
        <div [hidden]="!showListPointsOfCare" class="service-div-margim">
            <div class="col-md-12 p-0" *ngFor="let pointOfCare of pointsOfCare" >
                <button role="heading" aria-level="3" data-toggle="collapse" [attr.aria-expanded]="idEntidade === pointOfCare.id" style="border: none;width: 100%;"
                        [ngClass]="{'row lista-pai-clicavel entity-item-clicked': (pointOfCare.clicked),
                                'row lista-pai-clicavel entity-item': (!pointOfCare.clicked) }"
                        (click)="getPointOfCareDivisions(pointOfCare.id)" id={{pointOfCare.id}}>
                    <div class="entity-item">
                        <div class="entity-item-img" aria-hidden="true">
                            <img src="{{pointOfCare.logoPath}}" alt="Logo">
                        </div>

                        <div class="entity-item-title" id="EntidadeNome">
                            <p>{{pointOfCare.title}}</p>
                        </div>

                        <div class="entity-item-arrow" id="setaImg">
                            <img aria-hidden="true"
                                [src]="pointOfCare.clicked && imgDivisaoLoading == 1 ? pathImgDivisaoLoading : pathImgDivisaoSeta"
                                class="pull-right seta-img"
                                [ngClass]="{'seta-img-clicked-non-transition': (pointOfCare.clicked), 'seta-img-non-clicked-non-transition': (!pointOfCare.clicked) }">
                        </div>
                    </div>
                </button>

                <div [hidden]="!showAlertDivisionNotFound"> 
                    <div class="alert alert-secondary" role="alert" [hidden]="!pointOfCare.clicked" style="margin-top: 10px; width: 80%; vertical-align: middle; left: 40px">
                        {{'sidebarright.showAlertDivisionsNotFound'|translate}}
                    </div>
                </div>

                <div class="item-services" [hidden]="!showListDivision"> 
                    <div class="list-group list-group-flush list-group-divisions" *ngFor="let division of pointOfCareDivisions">
                        <button id={{division.code}} [hidden]="division.pointOfCareId != pointOfCare.id"
                            [attr.aria-expanded]="division.clicked && showTicketArea"
                            type="button" class="list-group-item list-group-item-action"
                            (click)="getDivisionsTicketInfoDetail(division, pointOfCare.sigaInstituteIdentifier, pointOfCare.sigaEntityIdentifier, pointOfCare.sigaLocalIdentifier)">
                            {{division.code}}-{{division.title}}
                            <img aria-hidden="true"
                                [src]="division.clicked && imgTicketLoading ? pathImgDivisaoLoading : pathImgDivisaoSeta"
                                class="pull-right seta-img"
                                [ngClass]="{'seta-img-clicked-non-transition': (division.clicked), 'seta-img-non-clicked-non-transition': (!division.clicked) }">
                        </button>
                        
                        <div [hidden]="!showAlertTicketInformationNotFound"> 
                            <div class="alert alert-secondary" role="alert" [hidden]="!pointOfCare.clicked || !division.clicked" style="margin-top: 10px; width: 80%; vertical-align: middle; left: 40px">
                                {{'sidebarright.showAlertTicketInformationNotFound'|translate}}
                            </div>
                        </div>
                         
                        <div [hidden]="!showTicketArea || !pointOfCare.clicked || !division.clicked"
                            class="col-md-12 lista-filho entity-services" style="background: #fff">
                            <div>
                                <div class="entity-services-title">
                                    <h3><b>{{ 'Senhas' | translate }}</b></h3>
                                </div>

                                <div class="entity-services-content">
                                    <table>
                                        <thead>
                                            <tr class="entity-services-content-info">
                                                <th>
                                                    <label class="entity-services-content-label" style="margin-bottom: 27px; line-height: 23px;">{{'Última senha atendida'| translate}}</label>
                                                </th>
                                                <th>
                                                    <label class="entity-services-content-label" style="margin-bottom: 27px; line-height: 23px;">{{'sidebarright.waitingUsers' | translate}}</label>  
                                                </th>
                                                <th>
                                                    <label class=" entity-services-content-label state-label" >{{'sidebarright.statusService'|translate}}</label>
                                                    <label *ngIf="selectedDivisionTicketInfo?.isOpen" class=" entity-services-content-label state-label">{{'sidebarright.serviceAvailable' | translate}} </label>
                                                    <label *ngIf="!selectedDivisionTicketInfo?.isOpen" class=" entity-services-content-label state-label">{{'sidebarright.serviceUnavailable' | translate}} </label>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="entity-services-content-info">
                                                    <p>
                                                        <b *ngIf="selectedDivisionTicketInfo">{{selectedDivisionTicketInfo?.lastTicketNumberAttended}}</b>
                                                        <b *ngIf="!selectedDivisionTicketInfo">&nbsp;&nbsp;&nbsp;{{'0'}}</b>
                                                    </p>
                                                </td>
                                                <td class="entity-services-content-info">
                                                    <p>
                                                        <b *ngIf="selectedDivisionTicketInfo">{{selectedDivisionTicketInfo?.waitingUsers}}</b>
                                                        <b *ngIf="!selectedDivisionTicketInfo">&nbsp;&nbsp;&nbsp;{{'0'}}</b>
                                                    </p> 
                                                </td>
                                                <td class="entity-services-content-info state-info" style="padding-top: 14px; padding-left: 50px;">
                                                    <div>
                                                        <label *ngIf="selectedDivisionTicketInfo?.isOpen" class="label-senhas-disponiveis" for="check-senhas">Há senhas disponíveis</label>
                                                        <mat-icon id="check-senhas" class="entity-services__icon_sucesso" *ngIf="selectedDivisionTicketInfo?.isOpen">check_circle</mat-icon>
                                                        
                                                        <svg *ngIf="!selectedDivisionTicketInfo?.isOpen" role="image" aria-hidden="false" [attr.aria-label]="'Não existem senhas disponíveis' | translate" xmlns="http://www.w3.org/2000/svg" 
                                                            class="icon icon-tabler icon-tabler-circle-x text-light entity-services__icon_error" 
                                                            width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="red" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                            <circle cx="12" cy="12" r="9" />
                                                            <path d="M10 10l4 4m0 -4l-4 4" />
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
 
                                </div>
                                <div *ngIf="selectedDivisionTicketInfo?.isOpen">
                                    <div class="line-break font-size-access">
                                        <label style="font-weight: 500;">{{'Tempo médio de atendimento:' |
                                            translate}}
                                        </label>
                                        <b>&nbsp;&nbsp;&nbsp;{{selectedDivisionTicketInfo?.averageServiceTime}}</b>
                                    </div>
                                    <div class="line-break font-size-access">
                                        <label style="font-weight: 500;">{{'Tempo médio de espera da última senha:' |
                                            translate}} </label>
                                        <b>&nbsp;&nbsp;&nbsp;{{selectedDivisionTicketInfo?.waitingTimeLastTicket}}</b>
                                    </div>
                                    <div class="line-break font-size-access">
                                        <div class="content-waiting">
                                            <span class="content-title">{{ 'Tempo de espera' | translate }}: </span>
                                            <mat-icon class="waiting-bullet" [ngClass]="{
                                              'green': selectedDivisionTicketInfo?.waitingStatus == 1,
                                              'yellow': selectedDivisionTicketInfo?.waitingStatus == 2,
                                              'red': selectedDivisionTicketInfo?.waitingStatus == 3
                                            }">circle</mat-icon>
                                            
                                            <span class="waiting-info">{{ ('waiting_' + selectedDivisionTicketInfo?.waitingStatus) | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div class="entity-services-actions" >
                                    <button class="botao-service-detail entity-service-actions-buttons-whith"
                                        [disabled]="!selectedDivisionTicketInfo?.isOpen"
                                        (click)="openModalStatisticsTicket(division.sigaServiceIdentifier, division.code)">
                                        <mat-icon class="botao-service-detail-icon" svgIcon="ticket" *ngIf="!waitingTicketStatistics"></mat-icon>
                                        <img aria-hidden="true"
                                                [src]="pathImgDivisaoLoading"
                                                class="pull-top seta-img"
                                                *ngIf="waitingTicketStatistics">
                                        <p>{{ 'sidebarright.buttonShowStatistics' | translate }}</p>
                                    </button>
                                    <button class="available-service-button botao-service-detail entity-service-actions-buttons-whith"
                                        [disabled]="!selectedDivisionTicketInfo?.isOpen"
                                        (click)="openModalTicket(division, pointOfCare.title, pointOfCare.sigaInstituteIdentifier, pointOfCare.sigaEntityIdentifier, pointOfCare.sigaLocalIdentifier)">
                                        <mat-icon class="botao-service-detail-icon" svgIcon="ticket"></mat-icon>
                                        <p>{{ 'OBTER SENHA' | translate }}</p>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div [hidden]="!mostrarInfos" class="col-md-12 margin-info" style="padding:0;margin-bottom:1.4rem">

                <div [hidden]="qtdeBalcaoComTextoProcurado == 0">
                    <div class="row" style="margin-left: auto;">
                        <label style="font-weight: bold;padding-left: 15px;">{{'local_search' | translate}}</label>
                    </div>

                    <div *ngFor="let entSenhas of entidadesSenhas">
                        <div *ngFor="let itensSenhas of entSenhas.divisoes"
                            [hidden]="itensSenhas.qtdeTextoEcontradoDivisao == 0"
                        >
                            <div class="col-md-12 lista-filho" [hidden]="itensSenhas.divisionCode == ''"
                                style="cursor:pointer" style="background: white;border: 1px solid #e6e6e6;"
                            >
                                <h4 style="font-size: 20px;    margin-bottom: 20px;">{{itensSenhas.divisionName}}</h4>

                                <div mat-actions style="width:100%;display:inline-block">
                                    <p class="line-break font-size-access">
                                        <label style="font-weight: 500;">{{'Última senha atendida'| translate}}:
                                        </label>
                                        <b>&nbsp;{{(itensSenhas.numUltimaSenha == null ? 'Sem
                                            informação':itensSenhas.numUltimaSenha) | translate}}</b>
                                    </p>

                                    <p class="line-break font-size-access">
                                        <label style="font-weight: 500;">{{'Pessoas em espera:' | translate}} </label>
                                        <b>&nbsp;{{(itensSenhas.utentesEmEspera == null ? 'Sem
                                            informação':itensSenhas.utentesEmEspera) | translate}}</b>
                                    </p>

                                    <p class="line-break font-size-access">
                                        <label style="font-weight: 500;">{{'Tempo médio de atendimento:' | translate}}
                                        </label>
                                        <b>&nbsp;{{(itensSenhas.tempoMedAtendimento == null ? 'Sem
                                            informação':itensSenhas.tempoMedAtendimento) | translate}}</b>
                                    </p>

                                    <p class="line-break font-size-access">
                                        <label style="font-weight: 500;">{{'Tempo médio de espera da última senha:' |
                                            translate}} </label>
                                        <b>&nbsp;{{(itensSenhas.tempoEsperaUltimaSenha == null ? 'Sem
                                            informação':itensSenhas.tempoEsperaUltimaSenha) | translate}}</b>
                                    </p>

                                    <p class="line-break font-size-access">
                                        <label style="font-weight: 500;">{{'Estado:' | translate}}</label>
                                        <b>&nbsp;{{(itensSenhas.estado == null ? 'Sem informação':itensSenhas.estado) |
                                            translate}}</b>
                                    </p>


                                    <div [hidden]="itensSenhas.habilitarSenha == 0">
                                        <div style="width: 50%;float:left">
                                            <button class="botaoModalEnvioSenha botao-service-detail"
                                                (click)="MostrarEnviarEmail(itensSenhas.divisionCode, 2)">{{EnviarSenhaPorEmail
                                                | translate}}</button>
                                        </div>
                                        <div style="width: 50%;float:right;">
                                            <button class="botaoModalEnvioSenha botao-service-detail"
                                                (click)="MostrarEnviarSMS(itensSenhas.divisionCode, 2)">{{EnviarSenhaPorSMS
                                                | translate}}</button>
                                        </div>
                                    </div>

                                    <div [hidden]="!this.mostrarInserirEmail || itensSenhas.divisionCode != this.idServicoClickedDeatil"
                                        mat-content style="width:100%;display:inline-block"
                                    >
                                        <div style="width:50%;float:left;margin-bottom: 12px">
                                            <mat-form-field class="formFieldEnvio"
                                                style="align-items: baseline;display: initial;">
                                                <input id="emailEnviarEntidade" matInput placeholder="{{Email}}"
                                                    [(ngModel)]="emailEnviar" [formControl]="emailFormControl"
                                                    [errorStateMatcher]="matcher">
                                                <mat-error
                                                    *ngIf="emailFormControl.hasError('pattern') && !emailFormControl.hasError('required')"
                                                    style="font-size: 16px;">
                                                    {{EmailInvalid}}
                                                </mat-error>
                                                <mat-error *ngIf="emailFormControl.hasError('required')"
                                                    style="font-size: 16px;">
                                                    {{EmailEmpty}}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div style="width:50%;float:right">
                                            <button class="botaoEnviar theme-principal botao-service-detail"
                                                (click)="this.tipoEnvio = 1; RetirarSenha (itensSenhas.idPOC, itensSenhas.divisionCode, emailEnviar, itensSenhas.divisionName);  this.origemCaminhoEntidadeSenha = 1;">{{'Obter'
                                                | translate}}</button>
                                        </div>
                                    </div>

                                    <div [hidden]="!this.mostrarInserirTelemovel || itensSenhas.divisionCode != this.idServicoClickedDeatil"
                                        mat-content style="width:100%;display:inline-block"
                                    >
                                        <div style="width:50%;float:left;margin-bottom: 12px">
                                            <mat-form-field class="formFieldEnvio"
                                                style="align-items: baseline;display: initial;">
                                                <input id="smsEnviarEntidade" matInput placeholder="{{Telemovel}}"
                                                    [(ngModel)]="smsEnviar" [formControl]="phoneFormControl"
                                                    [errorStateMatcher]="matcher">
                                                <mat-error
                                                    *ngIf="phoneFormControl.hasError('pattern') && !phoneFormControl.hasError('required')"
                                                    style="font-size: 16px;">
                                                    {{TelemovelInvalid}}
                                                </mat-error>
                                                <mat-error *ngIf="phoneFormControl.hasError('required')"
                                                    style="font-size: 16px;">
                                                    {{TelemovelEmpty}}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div style="width:50%;float:right">
                                            <button class="botaoEnviar theme-principal botao-service-detail"
                                                (click)="this.tipoEnvio = 2; RetirarSenha (itensSenhas.idPOC, itensSenhas.divisionCode, smsEnviar, itensSenhas.divisionName);  this.origemCaminhoEntidadeSenha = 1; this.tipoEnvio = 2;">{{'Obter'
                                                | translate}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <!-- Fim do bloco -->
            </div>
        </div>
    </div>

<!-- END LOCAL -->



<!-- START ENTIDADE -->

<div [hidden]="!mostrarEntidade" class="col-12 fundo" on-scroll="MostrarGoTopButton(2)">
    <div class="row" class="image-box">
        <div class="col-md-2" [style.marginBottom.px]="10">
            <button [ngClass]="{'voltar': true}"
                style="padding: 0;position: absolute;top:0; background: transparent;"
                (click)="this.mostrarEntidade = false; this.mostrarLocal = true; this.mostrarBusca = false; this.mostrarSenha = false">
                <img src="assets/icons/arrow-left-blue.png" class="voltar-seta-img-nova" alt="{{ 'seta para voltar' | translate }}">

                <span style="padding-top:2px;">{{'VOLTAR' | translate}}</span>
            </button>
        </div>
        <div class="col-md-12" class="image-container" aria-hidden="true">
            <img mat-card-avatar class="image-content" [src]="entidadeEscolhida.logoPath" aria-hidden="true" alt="">
        </div>
    </div>

    <div class="separador-img">
    </div>

    <div class="lista">
        <div class="col-md-12" style="padding:0">
            <button role="heading" aria-level="2"
                attr.aria-expanded="{{(this.mostrarEntidadeInfo && !this.mostrarEntidadeServicos)}}"
                class="row box-nome-local" (click)="MostrarEntidadeInfos()"
                style="cursor:pointer; border: none;width: 100%;"
            >
                <div class="col-sm-11 col-md-11" id="EntidadeNome" style="text-align: left;">
                    <span class="nome-entidade">{{entidadeEscolhida.nome}}</span>
                </div>

                <div class="col-sm-1 col-md-1" id="setaImg">

                    <img aria-hidden="true" src="assets/icons/setapz.svg" class="pull-right seta-img" alt="{{ 'seta para mostar detalhes' | translate }}"
                        [style.marginTop.px]="3"
                        [ngClass]="{'seta-img-clicked': this.mostrarEntidadeInfo, 'seta-img-non-clicked': !this.mostrarEntidadeInfo }"
                        class="pull-right seta-img">
                </div>
            </button>

            <div class="margin-entidade" style="margin-bottom:1.4rem"
                [hidden]="!(this.mostrarEntidadeInfo && !this.mostrarEntidadeServicos)"
            >
                <div class="col-md-12 texto-informativo-local">

                    <h3 class="contactos">{{'Contactos gerais' | translate}}</h3>

                    <div [hidden]="!(entidadeEscolhida.morada != '')" class="col-md-12"
                        style="margin-bottom:10px; padding: 0;">

                        <img attr.aria-label="{{'Morada' | translate}}" src="assets/icons/pin_adress.svg" alt="{{ 'icone de pino de endereço' | translate }}"
                            style="width: 5%;margin-right: 5px;">
                        <span class="span-text">{{entidadeEscolhida.morada}}</span>
                    </div>
                    <div [hidden]="!(entidadeEscolhida.telefone != '')" class="col-md-12"
                        style="margin-bottom:10px; padding: 0;">

                        <img attr.aria-label="{{'Telefone' | translate}}" src="assets/icons/phone.svg" alt="{{ 'icone de telefone' | translate }}"
                            style="width: 4.5%;margin-right: 5px;">
                        <a target="_blank" href="tel:{{entidadeEscolhida.telefone}}">
                            <span class="span-text">{{entidadeEscolhida.telefone}}</span>
                        </a>
                    </div>
                    <div [hidden]="!(entidadeEscolhida.email != '')" class="col-md-12"
                        style="margin-bottom:10px; padding: 0;">

                        <img attr.aria-label="{{'Email' | translate}}" src="assets/icons/emailIcon.svg" alt="{{ 'icone de email' | translate }}"
                            style="width: 4.3%;margin-right: 5px;">
                        <a target="_blank" href="mailto:{{entidadeEscolhida.email}}">
                            <span class="span-text">{{entidadeEscolhida.email}}</span>
                        </a>
                    </div>
                    <div [hidden]="!(entidadeEscolhida.site != '')" class="col-md-12"
                        style="margin-bottom:10px; padding: 0;">

                        <img attr.aria-label="{{'Site' | translate}}" src="assets/icons/globle_icon.svg" alt="{{ 'icone do site da entidade' | translate }}"
                            style="width: 4.5%;margin-right: 5px;">
                        <a rel="noopener" target="_blank" href="http://{{site}}">
                            <span class="span-text">{{entidadeEscolhida.site}}</span>
                        </a>
                    </div>

                </div>

                <div>
                    <div class="col-md-12 texto-informativo-local" style="margin-top: 1.4rem;">
                        <h3 class="contactos" style="padding: 0">{{'Informações' | translate}}</h3>

                        <span>
                            <div [innerHtml]="entidadeEscolhida.informacao" style="font-weight: 200;"></div>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12" style="padding:0"
            [hidden]="entidadeEscolhida.divisoes == null || entidadeEscolhida.divisoes.length == 0">

            <button role="heading" aria-level="3" attr.aria-expanded="{{mostrarEntidadeDivisoes}}"
                class="row box-servicos" (click)="MostrarEntidadeDivisoes()"
                style="cursor:pointer;margin:0; margin-bottom: 10px;width: 100%;; border: none">
                <div class="col-sm-11 col-md-11" style="text-align: left;" id="EntidadeServicoNome">
                    <span class="nome-entidade">{{'Senhas' | translate}}</span>
                </div>

                <div class="col-sm-1 col-md-1" id="setaImg">
                    <img aria-hidden="true" src="assets/icons/setapz.svg" [style.marginTop.px]="3" alt="{{ 'seta para alternar entre mostrar e ocultar conteúdo' | translate }}"
                        [ngClass]="{'seta-img-clicked': this.mostrarEntidadeDivisoes, 'seta-img-non-clicked': !this.mostrarEntidadeDivisoes }"
                        class="pull-right seta-img">
                </div>
            </button>

            <div class="col-md-12" [hidden]="!mostrarEntidadeDivisoes" style="padding:0">
                <div class="col-md-12" *ngFor="let itensSenhas of entidadeEscolhida.divisoes"
                    [hidden]="!mostrarEntidadeDivisoes" style="padding:0"
                >
                    <div class="col-md-12 lista-filho" [hidden]="itensSenhas.divisionCode == ''"
                        style="cursor:pointer" style="background: white;border: 1px solid #F1F3F8;"
                    >
                        <h4 style="font-size: 20px;    margin-bottom: 20px;">{{itensSenhas.divisionName}}</h4>
                        <div mat-actions style="width:100%;display:inline-block">


                            <p class="line-break font-size-access">
                                <label style="font-weight: 500;">{{'Última senha atendida'| translate}}:
                                </label>
                                <b>&nbsp;{{(itensSenhas.numUltimaSenha == null ? 'Sem
                                    informação':itensSenhas.numUltimaSenha) | translate}}</b>
                            </p>

                            <p class="line-break font-size-access">
                                <label style="font-weight: 500;">{{'Pessoas em espera:' | translate}} </label>
                                <b>&nbsp;{{(itensSenhas.utentesEmEspera == null ? 'Sem
                                    informação':itensSenhas.utentesEmEspera) | translate}}</b>
                            </p>

                            <p class="line-break font-size-access">
                                <label style="font-weight: 500;">{{'Tempo médio de atendimento:' | translate}}
                                </label>
                                <b>&nbsp;{{(itensSenhas.tempoMedAtendimento == null ? 'Sem
                                    informação':itensSenhas.tempoMedAtendimento) | translate}}</b>
                            </p>

                            <p class="line-break font-size-access">
                                <label style="font-weight: 500;">{{'Tempo médio de espera da última senha:' |
                                    translate}} </label>
                                <b>&nbsp;{{(itensSenhas.tempoEsperaUltimaSenha == null ? 'Sem
                                    informação':itensSenhas.tempoEsperaUltimaSenha) | translate}}</b>
                            </p>

                            <p class="line-break font-size-access">
                                <label style="font-weight: 500;">{{'Estado:' | translate}}</label>
                                <b>&nbsp;{{(itensSenhas.estado == null ? 'Sem informação':itensSenhas.estado) |
                                    translate}}</b>
                            </p>


                            <div [hidden]="itensSenhas.habilitarSenha == 0">
                                <div style="width: 50%;float:left">
                                    <button class="botaoModalEnvioSenha botao-service-detail"
                                        (click)="MostrarEnviarEmail(itensSenhas.divisionCode, 2)">{{EnviarSenhaPorEmail
                                        | translate}}</button>
                                </div>
                                <div style="width: 50%;float:right;">
                                    <button class="botaoModalEnvioSenha botao-service-detail"
                                        (click)="MostrarEnviarSMS(itensSenhas.divisionCode, 2)">{{EnviarSenhaPorSMS
                                        |
                                        translate}}</button>
                                </div>

                            </div>
                            <div [hidden]="!this.mostrarInserirEmail || itensSenhas.divisionCode != this.idServicoClickedDeatil"
                                mat-content style="width:100%;display:inline-block">
                                <div style="width:50%;float:left;margin-bottom: 12px">
                                    <mat-form-field class="formFieldEnvio"
                                        style="align-items: baseline;display: initial;">
                                        <input id="emailEnviarEntidade_1" matInput placeholder="{{Email}}"
                                            [(ngModel)]="emailEnviar" [formControl]="emailFormControl"
                                            [errorStateMatcher]="matcher">
                                        <mat-error
                                            *ngIf="emailFormControl.hasError('pattern') && !emailFormControl.hasError('required')"
                                            style="font-size: 16px;">
                                            {{EmailInvalid}}
                                        </mat-error>
                                        <mat-error *ngIf="emailFormControl.hasError('required')"
                                            style="font-size: 16px;">
                                            {{EmailEmpty}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div style="width:50%;float:right">
                                    <button class="botaoEnviar theme-principal botao-service-detail"
                                        (click)="this.tipoEnvio = 1; RetirarSenha (itensSenhas.idPOC, itensSenhas.divisionCode, emailEnviar, itensSenhas.divisionName);  this.origemCaminhoEntidadeSenha = 1;">{{'Obter'
                                        | translate}}</button>
                                </div>
                            </div>
                            <div [hidden]="!this.mostrarInserirTelemovel || itensSenhas.divisionCode != this.idServicoClickedDeatil"
                                mat-content style="width:100%;display:inline-block">
                                <div style="width:50%;float:left;margin-bottom: 12px">
                                    <mat-form-field class="formFieldEnvio"
                                        style="align-items: baseline;display: initial;">
                                        <input id="smsEnviarEntidade_1" matInput placeholder="{{Telemovel}}"
                                            [(ngModel)]="smsEnviar" [formControl]="phoneFormControl"
                                            [errorStateMatcher]="matcher">
                                        <mat-error
                                            *ngIf="phoneFormControl.hasError('pattern') && !phoneFormControl.hasError('required')"
                                            style="font-size: 16px;">
                                            {{TelemovelInvalid}}
                                        </mat-error>
                                        <mat-error *ngIf="phoneFormControl.hasError('required')"
                                            style="font-size: 16px;">
                                            {{TelemovelEmpty}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div style="width:50%;float:right">
                                    <button class="botaoEnviar theme-principal botao-service-detail"
                                        (click)="this.tipoEnvio = 2; RetirarSenha (itensSenhas.idPOC, itensSenhas.divisionCode, smsEnviar, itensSenhas.divisionName);  this.origemCaminhoEntidadeSenha = 1; this.tipoEnvio = 2;">{{'Obter'
                                        | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12" style="padding:0"
            [hidden]="entidadeEscolhida.servicos == null || entidadeEscolhida.servicos.length == 0"
        >
            <button role="heading" aria-level="3" attr.aria-expanded="{{mostrarEntidadeServicos}}"
                class="row box-servicos" (click)="MostrarEntidadeServicos()"
                style="cursor:pointer;margin:0; width: 100%; border: none">
                <div class="col-sm-11 col-md-11" id="EntidadeServicoNome" style="text-align: left;">
                    <span class="nome-entidade">{{'Serviços' | translate}}</span>
                </div>

                <div class="col-sm-1 col-md-1" id="setaImg">
                    <img aria-hidden="true" src="assets/icons/setapz.svg" [style.marginTop.px]="3" alt="{{ 'seta para alternar entre mostrar e ocultar conteúdo' | translate }}"
                        [ngClass]="{'seta-img-clicked': this.mostrarEntidadeServicos, 'seta-img-non-clicked': !this.mostrarEntidadeServicos }"
                        class="pull-right seta-img">
                </div>
            </button>

            <div [hidden]="!this.mostrarEntidadeServicos" class="lista scrollable" cdkScrollable>
                <div *ngFor="let itens of entidadeEscolhida.servicos">
                    <button
                        attr.aria-expanded="{{!(!this.mostrarEntidadeServicosDetail || itens.idServico != this.idServiceClicked)}}"
                        class="row lista-filho" (click)="InfoService(itens.idServico, itens.nome)"
                        style="cursor:pointer;margin:0;margin-bottom:5px; width: 100%; border: none">
                        <div role="heading" aria-level="4" class="col-sm-11 col-md-11" id="EntidadeNome"
                            style="    text-align: left;">
                            <span>

                                {{itens.nome}}
                            </span>
                        </div>

                        <div class="col-sm-1 col-md-1" id="setaImg">
                            <img aria-hidden="true" src="assets/icons/setapz.svg" [style.marginTop.px]="3" alt="{{ 'seta para alternar entre mostrar e ocultar conteúdo' | translate }}"
                                [ngClass]="{'seta-img-clicked': !(!this.mostrarEntidadeServicosDetail || itens.idServico != this.idServiceClicked), 'seta-img-non-clicked': (!this.mostrarEntidadeServicosDetail || itens.idServico != this.idServiceClicked) }"
                                class="pull-right seta-img">
                        </div>
                    </button>

                    <div [hidden]="!this.mostrarEntidadeServicosDetail || itens.idServico != this.idServiceClicked"
                        class="col-md-12 servico-informacoes" style="padding:0">
                        <div class="col-md-12 multi-text">

                            <span>
                                <div [innerHtml]="itens.informacao"></div>
                            </span>

                            <div [hidden]="itens.urlService == ''">
                                <button class="botao-service-detail complemento" style="width: auto;"
                                    (click)="GoService(itens.urlService)">{{'Aceda ao serviço' |
                                    translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button *ngIf="mostrarBotaoTopPage[2]" (click)="GoTopPage(2)" class="btnTop"
            title="{{ 'Botão para o topo da página' | translate }}" style="bottom: -32%">
            <svg class="" viewBox="0 0 32 32" style="height: 22px; width: auto; transform: rotateZ(-90deg);">
                <path fill="currentColor" style="fill: #ffff"
                    d="M30.023 16.994h-28.994c-0.549 0-0.994-0.445-0.994-0.994s0.445-0.994 0.994-0.994h28.994c0.549 0 0.994 0.445 0.994 0.994s-0.445 0.994-0.994 0.994v0z">
                </path>
                <path fill="currentColor" style="fill: #ffff"
                    d="M19.817 26.937c-0.005 0-0.011 0-0.017 0-0.289 0-0.549-0.128-0.725-0.33l-0.001-0.001c-0.154-0.174-0.248-0.405-0.248-0.657 0-0.298 0.131-0.565 0.339-0.747l0.001-0.001 10.354-9.143-10.354-9.2c-0.209-0.183-0.34-0.45-0.34-0.748 0-0.253 0.094-0.483 0.249-0.658l-0.001 0.001c0.185-0.206 0.452-0.335 0.749-0.335 0.251 0 0.481 0.092 0.658 0.245l-0.001-0.001 11.189 9.954c0.212 0.179 0.345 0.445 0.345 0.743s-0.134 0.564-0.344 0.742l-0.001 0.001-11.189 9.954c-0.162 0.116-0.364 0.186-0.583 0.186-0.028 0-0.056-0.001-0.084-0.003h0.004z">
                </path>
            </svg>
        </button>
    </div>
</div>

<!-- END ENTIDADE -->


<!-- START SENHA -->

<div [hidden]="!mostrarSenha" class="fundo">

    <div class="col-md-12">
        <div class="col-md-2" [style.marginBottom.px]="10">
            <button [ngClass]="{'voltar': true}"
                style="padding: 0;position: absolute;top:0; background: transparent;"
                (click)="this.mostrarEntidade = false; this.showListDivision = false; this.mostrarLocal = true; this.mostrarBusca = false; this.mostrarSenha = false">
                <img src="assets/icons/arrow-left-blue.png" class="voltar-seta-img-nova" alt="{{ 'seta para voltar' | translate }}">

                <span style="padding-top:2px;">{{'VOLTAR' | translate}}</span>
            </button>
        </div>
    </div>

    <div class="col-md-12" [hidden]="!mostrarMinhasSenha">
        <div class="col-md-12 margin-bottom-100" style="float: left !important;">
            <h2 style="text-align: center;font-size: 28px;margin-bottom: 20px;">{{'Senha' | translate}}</h2>
            <div *ngFor="let itens of senhasHistorico" [hidden]="SenhaHoje(itens.dataEmissao)">
                <div class="col-md-12 senha-container" style="display: flex;font-size: 16px;">
                    <div class="col-md-2 image-ticket-container">
                        <div class="image-ticket">
                            <div mat-content>
                                <span class="sr-only" style="top: 38px;">{{'Senha' | translate}}: </span>
                                <b style="color: #ffffff">{{itens.codigoSenha}}</b>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <div mat-content>
                            <span>{{'Serviço' | translate}}: </span> <b>{{itens.divisionName}}</b>
                        </div>
                        <div mat-content>
                            <span>{{'Entidade' | translate}}: </span> <b>{{itens.nomeEntidade}}</b>
                        </div>
                        <div mat-content>
                            <span>{{'Local' | translate}}: </span> <b>{{itens.nomePonto}}</b>
                        </div>
                        <div mat-content>
                            <span>{{'Emitido em' | translate}}: </span> <b>{{itens.dataHoraEmissao}}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12" [hidden]="!mostrarHistorico">
            <div class="col-md-12 margin-bottom-100">
                <div class="scrollable" cdkScrollable *ngFor="let itens of senhasHistorico">

                    <button class="row lista-pai-senhas margin-top-10" (click)="InfoSenha(itens.idSenha)">
                        <div class="col-sm-11 col-md-11" id="EntidadeNome">
                            {{itens.nomeServico}} - {{itens.dataEmissao}}
                        </div>
                        <div class="col-sm-1 col-md-1" id="setaImg">

                            <img aria-hidden="true" src="assets/icons/setapz.svg" alt="{{ 'seta para alternar entre mostrar e ocultar conteúdo' | translate }}"
                                [ngClass]="{'seta-img-clicked': (!this.mostrarSenhaDetail || itens.idSenha != this.idSenhaClicked), 'seta-img-non-clicked': !(!this.mostrarSenhaDetail || itens.idSenha != this.idSenhaClicked) }"
                                class="pull-right seta-img">

                        </div>
                    </button>

                    <div [hidden]="!this.mostrarSenhaDetail || itens.idSenha != this.idSenhaClicked"
                        class="col-md-12" style="margin-top: 6px;"
                    >
                        <h2 style="text-align: center;font-size: 28px;margin-bottom: 20px;">{{'Senha' |
                            translate}}
                        </h2>

                        <div class="col-md-12 senha-container" style="display: flex">
                            <div class="col-md-2 image-ticket-container">
                                <div class="image-ticket">
                                    <div mat-content>
                                        <span class="sr-only" style="top: 38px;">{{'Senha' | translate}}:
                                        </span>
                                        <b style="color: #ffffff">{{itens.codigoSenha}}</b>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-10">
                                <div mat-content>
                                    {{'Serviço' | translate}}: <b>{{itens.nomeServico}}</b>
                                </div>
                                <div mat-content>
                                    {{'Divisão' | translate}}:<b> {{itens.divisionName}}</b>
                                </div>
                                <div mat-content>
                                    {{'Entidade' | translate}}:<b> {{itens.nomeEntidade}}</b>
                                </div>
                                <div mat-content>
                                    {{'Local' | translate}}:<b> {{itens.nomePonto}}</b>
                                </div>
                                <div mat-content>
                                    {{'Emitido em' | translate}}:<b> {{itens.dataHoraEmissao}}</b>
                                </div>
                                <div mat-content class="error-ticket" [hidden]="itens.mensagemErro == ''">
                                    <b>{{itens.mensagemErro}}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- END SENHA -->
