<div class="modal-ticket-close">
  <button mat-dialog-close attr.aria-label="{{'Fechar' | translate}}">
    <mat-icon class="icon" [inline]="true">close</mat-icon>
  </button>
</div>

<p class="modal-ticket-text">{{'A sua senha' | translate}}</p>

<div class="modal-ticket-info-title">
  <p>{{data.ticket.pointOfCareName}}</p>
  <p>{{data.ticket.divisionName}}</p>
</div>

<div class="modal-ticket-icon">
  <img [hidden]="!showLoading" class="ticket-loading" src="{{imageLoading}}" alt="Loading...">
  <img [hidden]="showLoading" class="ticket-image" src="{{imageTicket}}" [alt]="'Você têm a senha número: ' + ticketNumber">
  <div aria-hidden="true" class="ticket-text">{{ticketNumber}}</div>
</div>

<div class="ticket-error-message">
  <mat-error *ngIf="showErrorMessage">{{ticketErrorMessage}}</mat-error>
</div>
<br>
<div *ngIf="showOptions">
  <p class="modal-ticket-text-text" style="font-size: 12px;" [hidden]="showErrorMessage" [innerHTML]="sigaMessage"></p>
  <p [ngClass]="{ 'modal-ticket-text-text' : !showTitleRedTicketConfirmation, 'modal-ticket-text-red': showTitleRedTicketConfirmation}">
    <strong>{{ticketConfirmationSend}}</strong>
  </p>
</div>
