import { Component, OnInit, Input } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'app';
  events: string[] = [];
  opened: boolean;
  openedRight: boolean;

  constructor(translate: TranslateService){
    translate.setDefaultLang('pt');
    translate.use('pt');
  }
}
