import { Component, OnInit, Inject } from '@angular/core';
import { MatIconRegistry, MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from './services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { DivisionServiceTicketDto } from './models/divisionServiceTicketDto';
import { ResponseData } from '../mapa/models/responseData';
import { ticketDto } from './models/ticketDto';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  errorMessage: void;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { ticket : ticketDto },
    private modalService: ModalService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ModalComponent>
    ) {

    iconRegistry.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg'));
    iconRegistry.addSvgIcon(
      'email',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/email.svg'));
    iconRegistry.addSvgIcon(
      'sms',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/sms.svg'));
  }

  ticketNumber: string = '';
  sigaCode: string = '';
  sigaMessage: string = '';
  ticketId: number;
  showErrorMessage: boolean = false;
  showOptions: boolean = true;
  ticketErrorMessage: string = '';
  imageTicket: string = 'assets/icons/ticket_icon_large.svg';
  ticketConfirmationSend: string = '';
  showTitleRedTicketConfirmation: boolean = false;

  showLoading: boolean = false;
  imageLoading: string = 'assets/icons/wait.gif'
  close: boolean = false;

  ngOnInit() {
    this.showTitleRedTicketConfirmation = false;
    this.showLoading = true;
          
    this.modalService.getSigaDivisionNextTicket(this.data.ticket).subscribe(res => this.setTicket(res));
  }

  setTicket(response: ResponseData<DivisionServiceTicketDto>) {
    if (response.statusCode == 200){
      this.ticketNumber = response.data.ticketNumber;
      this.ticketId = response.data.ticketIdentifier;
      this.showOptions = true;
      this.sigaCode = response.data.ticketCode;
      this.sigaMessage = response.data.message.replace(/(\n)+/g, " | ");

      if (this.data.ticket.confirmationType == 1){
        this.ticketConfirmationSend = this.translate.instant("modalGetTicket.textTicketSendSms");
      }
      else{
        this.ticketConfirmationSend = this.translate.instant("modalGetTicket.textTicketSendEmail");
      }
    }
    else if (response.statusCode == 190) //Get ticket successfully but has error sending proof by email or sms
    {
      this.ticketNumber = response.data.ticketNumber;
      this.ticketId = response.data.ticketIdentifier;
      this.showOptions = true;
      this.sigaCode = response.data.ticketCode;
      this.sigaMessage = response.data.message.replace(/(\n)+/g, " | ");
      this.ticketConfirmationSend = this.translate.instant("Não foi possível enviar sua senha.");
      this.showTitleRedTicketConfirmation = true;
    }
    else if (response.statusCode < 100) //SIGA Business error
    {
      this.showErrorMessage = true;
      this.ticketErrorMessage = response.message;
      this.showOptions = false;
    }
    else
    {
      this.showErrorMessage = true;
      this.ticketErrorMessage = this.translate.instant("modalGetTicket.errorMessage");
      this.showOptions = false;
    }

    this.showLoading = false;
  }

}
