<header id="banner" class="header-border">
  <div id="section-header" class="navbar-expand-lg ept-header d-lg-flex flex-lg-column">
      <input type="hidden" id="inputIsPWA">
      <div class="navbar navbar-expand-lg navbar-light">
          <button class="navbar-toggler d-flex flex-column d-lg-none" type="button" data-toggle="collapse" data-target="#eptMenu" aria-controls="eptMenu" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon icon36 mb-0"></span>
              <span class="ept-heading6 ept-header__toggler__text mb-0">Menu</span>
          </button>
          <a title="ePortugal - o novo portal de serviços públicos" class="navbar-brand p-0" href="{{urlPortugal}}" >
              <img class="img-fluid ept-header__logo" id="imgLogoHeader" src="{{urlPortugal}}/o/servgov-theme/images/redesign/logo_Portugal.svg" alt="{ { 'ePortugal o novo portal de serviços públicos' | translate }}" width="236" height="60"/>
          </a>
          <button class="btn d-lg-none ept-header__search__toggle p-0" type="button" data-toggle="collapse" data-target="#searchbox" 
          aria-expanded="false" aria-controls="searchbox" aria-label="Pesquisar em ePortugal">
              <svg class="icon36" role="img" aria-labelledby="searchboxIconLabel">
                  <title id="searchboxIconLabel">{{ 'Pesquisar em ePortugal' | translate }}</title>
                  <use href="#pesquisar" />
              </svg>
          </button>
          <div class="d-lg-block ept-header__search py-5 py-lg-0 collapse" id="searchbox">
          <div class="search-top portlet__form search-hidden-validator d-flex justify-content-center">
              <div class="search-form" id="eptSiteSearchForm">
                  <form name="fm" class="searchForm" role="search" onsubmit="return false">
                      <fieldset>
                          <legend class="hide-accessible">{{ 'Pesquisar em ePortugal' | translate }}</legend>
                          <input name="_searchresults_mvcRenderCommandName" type="hidden" value="tudo" class="portalCategoriesId"/>
                          <div class="search-top-field form-inline">
                              <label class="label-search-form-input" for="desktopFormKey">
                                  <span class="hide-accessible">{{ 'O que procura?' | translate }}</span>
                                  <input data-content="A sua pesquisa deve conter no mínimo 3 caracteres." class="search-form-input" 
                                      id="desktopFormKey" name="_searchresults_keywords" type="text" 
                                      placeholder="{{ 'O que procura?' | translate }}" value="" oninput="showSuggestionsList(this);">
                              </label>
                              <button type="submit" class="search-top-btn">
                                  <svg class="icon36" role="img" aria-labelledby="searchTopBtnIconLabel" viewBox="0 0 36 36">
                                      <title id="searchTopBtnIconLabel">{{ 'Submeter pesquisa' | translate }}</title>
                                      <use href="#pesquisar" />
                                  </svg>
                                  <span class="hide-accessible">{{'Botão para submeter pesquisa' | translate }}</span>
                              </button>
                          </div>
                      </fieldset>
                  </form>
                  <div class="listSuggestions box-list-suggestions">
                      <ul class="my-0 listSuggestionsItens"></ul>
                  </div>
              </div>
          </div>
          </div>
          <div class="d-none d-lg-block">
              <!--  LANGUAGE SELECTOR  -->
              <div class="dropdown ept-dropdown ept-langselector" data-toggle="tooltip" data-placement="top" title="Escolher idioma">
                  <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span class="sr-only">{{ 'Escolher idioma' | translate }}</span>
                      <span>{{ selectedLanguage }}</span>
                      <svg class="icon24" aria-hidden="true" focusable="false">
                          <use href="#seta_baixo" />
                      </svg>
                  </button>
                  <div class="dropdown-menu" >
                      <a data-active="true" class="dropdown-item" href="#" (click)="languageChanged(language)"
                          *ngFor="let language of availableLanguages" name="dllLanguageDesktop"> <!--name dllLanguageDesktop se faz necessario por causa da função changeElementsLanguageSelected do Mapa-->
                          {{ language }}
                          <svg class="icon24 ept-dropdown__icon" aria-hidden="true" focusable="false">
                              <use href="#check" />
                          </svg>
                      </a>
                  </div>
              </div>

          </div>
      </div>
      <div class="d-flex flex-row justify-content-between ept-header__menu-wrapper">
          <nav class="collapse navbar-collapse p-0 ept-menu" id="eptMenu">
              <!--  lang and AR dropdowns (mobile only)  -->
              <div class="d-flex d-lg-none flex-row justify-content-between ept-menu__top">
                  <!--  LANGUAGE SELECTOR  -->
                  <div class="dropdown ept-dropdown ept-langselector" data-toggle="tooltip" data-placement="top" title="Escolher idioma">
                      <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span class="sr-only">{{ 'Escolher idioma' | translate }}</span>
                          <span>{{ selectedLanguage }}</span>
                          <svg class="icon24" aria-hidden="true" focusable="false">
                              <use href="#seta_baixo" />
                          </svg>
                      </button>
                      <div class="dropdown-menu">
                          <a data-active="true" class="dropdown-item" href="#" (click)="languageChanged(language)"
                              *ngFor="let language of availableLanguages" name="dllLanguageMobile">
                              {{ language }}
                              <svg class="icon24 ept-dropdown__icon" aria-hidden="true" focusable="false">
                                  <use href="#check" />
                              </svg>
                          </a>
                      </div>
                  </div>
                  <!--  RESERVED AREA DROPDOWN LOGIN  -->
                  <div class="dropdown ept-dropdown ept-arlogin" data-toggle="tooltip" data-placement="top" title="Área reservada">
                      <button class="btn dropdown-toggle d-flex flex-row align-items-center" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <svg class="icon36" aria-hidden="true" focusable="false">
                              <use href="#area_reservada" />
                          </svg>
                          <!-- se o user não estiver autenticado no sistema -->
                          <span>{{ 'Área reservada' | translate }}</span>
                          <!-- /se o user não estiver autenticado no sistema -->
                          <!-- se o user estiver autenticado no sistema -->
                          <!-- <span class="ept-arlogin__user__initials"></span> -->
                          <!-- NOTA: o primeiro nome e a iniciail do ultimo nome do user autenticado devem ser inseridos na span ".ept-arlogin__user__initials"  -->
                          <!-- /se o user estiver autenticado no sistema -->
                          <svg class="icon24" aria-hidden="true" focusable="false">
                              <use href="#seta_baixo" />
                          </svg>
                      </button>
                      <div class="dropdown-menu">
                          <!-- se o user não estiver autenticado no sistema -->
                          <a class="dropdown-item" href="{{urlPortugal}}/entrar" title="Entrar no portal ePortugal">
                              <svg class="icon36 ept-dropdown__icon mr-2" aria-hidden="true" focusable="false">
                                  <use href="#entrar_ar" />
                              </svg>
                              <span>{{ 'Entrar no portal' | translate }}</span>
                          </a>
                          <a class="dropdown-item" href="{{urlPortugal}}/criar-registo" title="Criar novo registo">
                              <svg class="icon36 ept-dropdown__icon mr-2" aria-hidden="true" focusable="false">
                                  <use href="#criar_registo" />
                              </svg>
                              <span>{{ 'Criar registo' | translate }}</span>
                          </a>
                          <!-- se o user estiver autenticado no sistema -->
                          <!-- <a class="dropdown-item" href="{{urlPortugal}}/group/guest/servicos/cidadaos" title="Aceda à sua área reservada">
                              <svg class="icon36 ept-dropdown__icon mr-2" aria-hidden="true" focusable="false">
                                  <use href="#a_minha_area" />
                              </svg>
                              <span>A minha área</span>
                          </a>
                          <a class="dropdown-item" href="{{urlPortugal}}/entrar?p_p_id=PortalAmaLogin&_PortalAmaLogin_mvcRenderCommandName=viewLogout" title="Sair">
                              <svg class="icon36 ept-dropdown__icon mr-2" aria-hidden="true" focusable="false">
                                  <use href="#sair" />
                              </svg>
                              <span>Sair</span>
                          </a> -->
                      </div>
                  </div>

              </div>
              <!--  main menu  -->
              <ul class="navbar-nav flex-column flex-lg-row flex-lg-wrap m-0 p-0 ept-menu__list" id="eptMenuList"></ul>
          </nav>
          <!--  RESERVED AREA DROPDOWN LOGIN (desktop only)  -->
          <div class="d-none d-lg-block">
              <div class="dropdown ept-dropdown ept-arlogin" data-toggle="tooltip" data-placement="top" title="Área reservada">
                  <button class="btn dropdown-toggle d-flex flex-row align-items-center" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <svg class="icon36" aria-hidden="true" focusable="false">
                          <use href="#area_reservada" />
                      </svg>
                      <!-- se o user não estiver autenticado no sistema -->
                      <span>{{ 'Área reservada' | translate }}</span>
                      <!-- /se o user não estiver autenticado no sistema -->
                      <!-- se o user estiver autenticado no sistema -->
                      <!-- <span class="ept-arlogin__user__initials"></span> -->
                      <!-- NOTA: o primeiro nome e a iniciail do ultimo nome do user autenticado devem ser inseridos na span ".ept-arlogin__user__initials"  -->
                      <!-- /se o user estiver autenticado no sistema -->
                      <svg class="icon24" aria-hidden="true" focusable="false">
                          <use href="#seta_baixo" />
                      </svg>
                  </button>
                  <div class="dropdown-menu">
                      <!-- se o user não estiver autenticado no sistema -->
                      <a class="dropdown-item" href="{{urlPortugal}}/entrar" title="Entrar no portal ePortugal">
                          <svg class="icon36 ept-dropdown__icon mr-2" aria-hidden="true" focusable="false">
                              <use href="#entrar_ar" />
                          </svg>
                          <span>{{ 'Entrar no portal' | translate }} </span>
                      </a>
                      <a class="dropdown-item" href="{{urlPortugal}}/criar-registo" title="Criar novo registo">
                          <svg class="icon36 ept-dropdown__icon mr-2" aria-hidden="true" focusable="false">
                              <use href="#criar_registo" />
                          </svg>
                          <span>{{ 'Criar registo' | translate }}</span>
                      </a>
                      <!-- se o user estiver autenticado no sistema -->
                      <!-- <a class="dropdown-item" href="{{urlPortugal}}/group/guest/servicos/cidadaos" title="Aceda à sua área reservada">
                          <svg class="icon36 ept-dropdown__icon mr-2" aria-hidden="true" focusable="false">
                              <use href="#a_minha_area" />
                          </svg>
                          <span>A minha área</span>
                      </a>
                      <a class="dropdown-item" href="{{urlPortugal}}/entrar?p_p_id=PortalAmaLogin&_PortalAmaLogin_mvcRenderCommandName=viewLogout" title="Sair">
                          <svg class="icon36 ept-dropdown__icon mr-2" aria-hidden="true" focusable="false">
                              <use href="#sair" />
                          </svg>
                          <span>Sair</span>
                      </a> -->
                  </div>
              </div>
          </div>
      </div>
  </div>
</header>
