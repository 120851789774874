import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { BaseService } from 'src/app/core/services/base.service';
import { Tutorial } from '../models/tutorial';
import { Notification } from '../models/notification';

@Injectable()
export class TutorialService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getTutorialActive(): Observable<Tutorial> {
        let response = this.http
            .get<Tutorial>(this.UrlService + '/api/tutorial/active/web/pt', this.GetHeaderJson())
            .pipe(catchError(super.serviceError));

        return response;
    }

    isToNotify(): Observable<Notification>{
        let response = this.http
            .get<Notification>(this.UrlService + '/api/tutorial/notification/web/pt', this.GetHeaderJson())
            .pipe(catchError(super.serviceError));

        return response;
    }
}