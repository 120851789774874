import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() sideNav;  
  constructor() { }

  ngOnInit() {
  }


  MostrarAreaReservada(locaisSpan: boolean = null, locaisSpot: boolean = null) {
    if(locaisSpan || locaisSpot)
      this.areaReservada = true;
    else
      this.areaReservada = false;
  }

  MostrarLocais(locaisSpan: boolean = null, locaisSpot: boolean = null) {
    if(locaisSpan || locaisSpot)
      this.locais = true;
    else
      this.locais = false;
  }
  
  locais: boolean = false;
  areaReservada: boolean = false;

  tipo: number;

  item: Item[] = [
    {value:1, viewValue:'Teste 1 Teste 1 Teste 1 Teste 1 '},
    {value:2, viewValue:'Teste 2 Teste 2 Teste 2 Teste 2'},
    {value:3, viewValue:'Teste 3 Teste 3 Teste 3 Teste 3'},
    {value:3, viewValue:'Teste 4 Teste 4 Teste 4 Teste 4'},
    {value:3, viewValue:'Teste 5 Teste 5 Teste 5 Teste 5'}
  ]

}

export interface Item{
  value:number;
  viewValue: string;
}
