<div>
  <div class="next-to-me-container">
    <h2 class="titleH2">{{mapa.titleListMapPoint}}</h2>

    <!--<div class="local-dropdown">
      <select class="local-dropdown-select"
        (change)="FiltrarLocais($event.currentTarget.value); AlteredLocaisPertoDeSiFilter(true);" aria-label="Filtro">
        <option *ngFor="let filtro of tiposFiltro" [value]="filtro.code">{{ filtro.name | translate }}
        </option>
      </select>
    </div>-->
  </div>

  <div class="alert alert-danger" role="alert" *ngIf="!mapa.mapPointsDto || mapa.mapPointsDto.length === 0">
      {{'Nenhum ponto encontrado'|translate}}
  </div>

  <ul class="digital-services-list" role="list">
    <li *ngFor="let point of mapa.pagination.items" role="listitem">
      <app-nearby-poi-button [attr.aria-label]="point.title" [data]="point"
        (onclick)="getMapPointInfoDetail(point)" (onmouseenter)="MouseEnterRow($event)"
        (onmouseleave)="MouseLeaveRow()"
        [hidden]="umLocalMesmaLat && !(umLocalMesmaLat && point.latitude == latPonto && point.longitude == lngPonto)">
      </app-nearby-poi-button>
    </li>
  </ul>
  
  <!--PAGINATION-->
  <div class="col-md-12" *ngIf="mapa.pagination.maxPage > 1" title="{{'pagination.title' | translate}}">
    <ul class="pagination justify-content-center" role="list">
      <li id="pageP" class="page-item disabled" (click)="clickPage(mapa.pagination.currentPage-1)" title="{{'pagination.previous' | translate}}">
        <a class="page-link" href="#" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li id="page1" class="page-item active" (click)="clickPage(mapa.pagination.pageOne)"><a class="page-link" href="#" >{{mapa.pagination.pageOne}}</a></li>
      <li id="page2" class="page-item" (click)="clickPage(mapa.pagination.pageTwo)"><a class="page-link" href="#">{{mapa.pagination.pageTwo}}</a></li>
      <li id="page3" class="page-item" (click)="clickPage(mapa.pagination.pageThree)" [hidden]="mapa.pagination.maxPage == 2"><a class="page-link" href="#">{{mapa.pagination.pageThree}}</a></li>
      <li class="page-item disabled" [hidden]="mapa.pagination.maxPage < 5 || mapa.pagination.pageThree + 1 == mapa.pagination.maxPage"><a class="page-link" href="#">...</a></li>
      <li id="page4" class="page-item" (click)="clickPage(mapa.pagination.maxPage)" [hidden]="mapa.pagination.maxPage < 3"><a class="page-link" href="#">{{mapa.pagination.maxPage}}</a></li>
      <li id="pageN" class="page-item" (click)="clickPage(mapa.pagination.currentPage+1)" title="{{'pagination.next' | translate}}">
        <a class="page-link" href="#" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
 </div>  
  <!--END PAGINATION-->


  <!--Retirando botao ver mais e limpar-->
  <!--<div class="col-12" style="padding:0">
    <div class="post-actions">
      <div class="divButton col-6 pr0" style="cursor: pointer; margin-bottom: 20px; padding-left: 5px;"
        (click)="ShowNextPointList()">
        <span class="liSpan mt4" [hidden]="!mapa.showMorePoints">
          {{'see_more' | translate}}
        </span>
        <mat-icon svgIcon="arrowDown" aria-hidden="true" class="arrow-down" [hidden]="!mapa.showMorePoints"></mat-icon>
      </div>

      <div *ngIf="mapa.citizenPoints && mapa.citizenPoints.length || tipoFiltroEscolhido !== 1" class="divButton col-6 pr0"
        style="cursor: pointer; margin-bottom: 20px;display: flex; justify-content: flex-end;padding-right: 4px;"
        (click)="ClearPoints(); AlteredLocaisPertoDeSiFilter(false);">
        <span class="mt4">
          {{'clear' | translate}}
        </span>
      </div>
    </div>
  </div>-->
</div>