<div id="tutorialModal" class="tutorial-modal" role="dialog" aria-modal="true">
  <div id="carouselTutorial" class="carousel" data-ride="carousel" data-interval="false">
    <div class="carousel-inner">

      <a class="close-tutorial" (click)="closeModal()" role="gridcell">
        <span class="menu-close close-tutorial">X</span>
      </a>

      <div class="carousel-item" *ngFor="let image of images let i = index" [ngClass]="{'active': i == 0 }">
        <img [src]="image" [id]="'tutorial-image-' + i" [tabindex]="i" [attr.aria-label]="legendas[i]" class="d-block w-100">
      </div>

      <div class="navigation-menu" tabindex="0">
        <a id="prevPageBtn" class="carousel-control-prev" (click)="pageCounter()" href="#carouselTutorial" role="gridcell" data-slide="prev" aria-label="previous" tabindex="0">
          <i class="carousel-control-prev-icon" [attr.aria-label]="currentPage !== 1 ? 'Página anterior' : 'Primeira Página'"></i>
        </a>
        <div class="page-counter" aria-hidden="true">
          {{ 'Página' | translate }} {{ currentPage }} {{ 'de' | translate }} {{ totalPages }}
        </div>
        <a id="nextPageBtn" class="carousel-control-next" (click)="pageCounter()" href="#carouselTutorial" role="gridcell" data-slide="next" aria-label="next" tabindex="0">
          <i class="carousel-control-next-icon" [attr.aria-label]="currentPage < totalPages ? 'Próxima página' : 'Fechar Tutorial'"></i>
        </a>
      </div>
    </div>
  </div>
</div>
