import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";

import { BaseService } from 'src/app/core/services/base.service';
import { Click } from '../models/click';
import { DigitalServices } from '../models/digital-services';
import { ResponseData } from '../../mapa/models/responseData';
import { MapPointInfoDto } from '../models/mapPointInfoDto';
import { PointOfCareDto } from '../models/pointOfCareDto';
import { PointOfCareDivisionDto } from '../models/pointOfCareDivisionDto';
import { DivisionServiceInfoDetailDto } from '../models/divisionServiceInfoDetailDto';
import { DivisionServiceListStatisticTicketDto } from '../../chart/models/divisionServiceListStatisticTicketDto';

@Injectable()
export class SidebarService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    registerScheduleClick(click: Click): Observable<Click> {
        let response = this.http
            .post(this.UrlService + '/api/statistics/click/', click, this.GetHeaderJson())
            .pipe(
                map(super.extractData),
                catchError(super.serviceError));

        return response;
    }

    getTopDigitalServices(page: number): Observable<DigitalServices[]> {

        let pageSize = 5;
        let response = this.http
            .get<DigitalServices[]>(this.UrlService + `/api/digital-services/${page}/${pageSize}`, this.GetHeaderJson())
            .pipe(catchError(super.serviceError));

        return response;
    }

     /**
    * Returns Info Detail of PointOfCare selected
    * @param pointOfCareId identifier of pointOfCare
    * @param language language 
    * @returns ResponseData with MapPointInfoDto
    */
    public getPointsOfCareInfoDetail(pointOfCareId: number, language: string): Observable<ResponseData<MapPointInfoDto>> {    
      return this.http.get<ResponseData<MapPointInfoDto>>(this.UrlService + `/${this.azFunction}/map/pointOfCare/info/${pointOfCareId}?languageCode=${language}`);          
    }

    /**
    * Returns Info Detail of Organization selected
    * @param organizationId identifier of organization
    * @param language language 
    * @returns ResponseData with MapPointInfoDto
    */
    public getOrganizationInfoDetail(organizationId: number, locationId:number, language: string): Observable<ResponseData<MapPointInfoDto>> {    
      return this.http.get<ResponseData<MapPointInfoDto>>(this.UrlService + `/${this.azFunction}/map/organization/info/${organizationId}/${locationId}?languageCode=${language}`);          
    }

    /**Get list of PointOfCare by LocationId */
    public getPointsOfCareByLocation(locationId:number, language: string): Observable<ResponseData<PointOfCareDto[]>> {
        return this.http.get<ResponseData<PointOfCareDto[]>>(this.UrlService + `/${this.azFunction}/map/pointOfCare/location/${locationId}?languageCode=${language}`);          
    }

    /**Get list of PointOfCareDivisions 
     * @param pointOfCareId PointOfCare Identifier
     * @param language Language selected by user
    */
    public getPointOfCareDivisions(pointOfCareId: number, language: string): Observable<ResponseData<PointOfCareDivisionDto[]>> {
        return this.http.get<ResponseData<PointOfCareDivisionDto[]>>(this.UrlService + `/${this.azFunction}/map/pointOfCare/division/${pointOfCareId}?languageCode=${language}`);
    }

    /** Get SIGA Information detail about ticket Point of Care Division
     * @param sigaInstituteIdentifier Institute identifier on SIGA system
     * @param sigaEntityIdentifier Entity identifier on SIGA system
     * @param sigaLocalIdentifier Local Identifier on SIGA system
    */
    public getDivisionsTickestInfoDetail(sigaInstituteIdentifier: number, sigaEntityIdentifier: number, sigaLocalIdentifier: number): Observable<ResponseData<DivisionServiceInfoDetailDto[]>>{
        return this.http.get<ResponseData<DivisionServiceInfoDetailDto[]>>(this.UrlService + `/${this.azFunction}/map/pointOfCare/division/siga/${sigaInstituteIdentifier}/${sigaEntityIdentifier}/${sigaLocalIdentifier}`);
    }

    /**
     * Get IP of User device used to acess site CitizenMap
     * @returns IP
     */
    public getUserDeviceIp(): Observable<any>{
        return this.http.get<any>('https://api.ipify.org?format=json');
    }
    
    /**
     * Get SIGA statistics of ticket
     * @param sigaServiceIdentifier Division Service Identifier on Siga System
     * @param divisionCode Division code
     * @returns 
     */
    getSigaTicketStatistics(sigaServiceIdentifier: number, divisionCode: string){
        return this.http.get<ResponseData<DivisionServiceListStatisticTicketDto>>(this.UrlService + `/${this.azFunction}/map/pointOfCare/division/sigaTicketStatistics/${sigaServiceIdentifier}/${divisionCode}`); 
    }
}