
<div #divLoader class="loaderBackdrop">
  <img alt="loader" src="assets/gifs/loader.gif" class="loader" />
</div>

<h1 class="sr-only size2em">Aplicação do Mapa de Cidadão</h1>



<!-- Modal Splash
<app-splash></app-splash>
<app-tutorial *ngIf="sideRightBar.mostrarTutorial"></app-tutorial>-->

<!-- Mobile div -->
<div class="mobileDiv" [hidden]="!mobile">
  <div class="dialog">
    <div class="row pb-5 pt-5" >
      <button class="botao bt-w-15" (click)="mobile = false;">X</button>
      <img src="assets/images/app-mapa.svg" class="image-mobile-dialog" alt="Mapa">

      <span class="content-mobile-dialog">
        <p style="margin: 0;"><b>Mapa de Cidadão</b></p>
        <p style="margin: 0;">Serviços e senhas online</p>
        <p style="margin: 0;">Aplicação móvel</p>
      </span>

      <a class="botao btn-w-9" attr.aria-label="Ver na loja" [hidden]="!iOS"
        href="https://apps.apple.com/us/app/mapa-de-cidad%C3%A3o/id966526205">
        <span>Aceder</span>
      </a>

      <a class="botao btn-w-9"  attr.aria-label="Ver na loja" [hidden]="iOS"
        href="http://market.android.com/details?id=pt.ama.mapadocidadao">
        <span>Aceder</span>
      </a>
    </div>
  </div>
</div>

<!-- main container -->
<mat-sidenav-container id="mainContainer" [hasBackdrop]="false">

  <!-- side bar left-->
  <mat-sidenav #sideNavLeft [fixedInViewport]="false" mode="over" [(opened)]="opened" [style.width.%]="100">
    <app-sidebar [sideNav]="sideNavLeft"></app-sidebar>
  </mat-sidenav>

  <!-- side bar right-->
  <mat-sidenav #sideRight position="end" class="sideNavClass"  [fixedInViewport]=false [(opened)]="openedRight"
      role="complementary" aria-label="Locais perto de si">
    <app-sidebarright #sideRightBar [mapa]="mapa" [divLoader]="divLoader" [selectedLanguage]="selectedLanguage"></app-sidebarright>
  </mat-sidenav>

  <!-- main content -->
  <div aria-hidden="true">

    <main class="content" aria-label="Google Map, se não consegue usar o mapa interativo consulte a área, locais perto de si" aria-hidden="true">
      <app-search [mapa]="mapa"></app-search>

      <div id="divMapa" aria-hidden="true">

        <app-mapa id="mapa" #mapa [sideNavRight]="sideRight" [sideRightBar]="sideRightBar" [filteroptions]='filteroptions'
          [divLoader]="divLoader"></app-mapa>

      </div>
      <div role="complementary" aria-label="mapa" aria-hidden="true">
        <app-filteroptions #filteroptions [sideNav]="sideNavLeft" [mapa]="mapa" [sideNavRight]="sideRight"
          [sideRightBar]="sideRightBar" [selectedLanguage]="selectedLanguage" style="border: 0px;margin: 0px;padding: 0px;"></app-filteroptions>
      </div>
    </main>
  </div>

</mat-sidenav-container>

