<div>

  <div class="modal-email-close">
    <button mat-dialog-close aria-label="{{'Fechar' | translate}}">
      <mat-icon svgIcon="closeIcon"></mat-icon>
    </button>
  </div>

  <div [hidden]="selectedRadio" class="justify-content-center">
    <p class="modal-confirmation-choose-text">{{'modalGetTicket.titleChooseConfirmation' | translate}}</p>
    <div class="form-check form-check-inline" style="margin-left: 30px; margin-top: 20px; color: #2B363C">
      <input class="form-check-input" type="radio" name="rdConfirmation" value="1" [(ngModel)]="selectedRadio" id="rdSms" checked>
      <label class="form-check-label" for="rdEmail">SMS</label>
    </div>
    <div class="form-check form-check-inline" style="margin-left: 20px; color: #2B363C">
      <input class="form-check-input" type="radio" name="rdConfirmation" value="2" [(ngModel)]="selectedRadio" id="rdEmail">
      <label class="form-check-label" for="rdEmail">Email</label>
    </div>
  </div>

  <div id="sendSMS" *ngIf="selectedRadio == 1">
    <p class="modal-sms-text">{{'Introduza o seu contacto' | translate}}</p>

    <div class="modal-sms-input">
      <form [formGroup]="smsForm">
        <mat-form-field class="example-full-width">
          <mat-label>{{'número de telemóvel' | translate}}</mat-label>
          <span matPrefix>+351 &nbsp;</span>
          <input id="sms" type="number" formControlName="serviceText" matInput (focus)="displaySuccessMessage = false; displayErrorMessage = false">
        </mat-form-field>
      </form>
    </div>

    <div class="modal-sms-message">
      <span class="text-success" *ngIf="displaySuccessMessage">{{'Senha enviada com sucesso.' | translate}}</span>
    </div>

    <div class="modal-sms-message">
      <span class="text-danger" *ngIf="displayErrorMessage">{{errorMessage}}</span>
    </div>

    <div class="modal-sms-actions">
      <button (click)="sendSms()" [disabled]="!smsForm.valid" type="submit">
        <p>{{'modalGetTicket.buttonGetTicketSms' | translate}}</p>
      </button>
    </div>
  </div>

  <div id="sendEmail" *ngIf="selectedRadio == 2">
    <p class="modal-email-text">{{'Introduza o seu email' | translate}}</p>

    <div class="modal-email-input">
      <form [formGroup]="emailForm">
        <mat-form-field class="modal-email-field-input">
          <mat-label>email</mat-label>
          <input id="email" name="email" type="email" formControlName="serviceText" placeholder="{{'E-mail (requerido)' | translate}}" matInput aria-label="Email" (focus)="displaySuccessMessage = false; displayErrorMessage = false">
        </mat-form-field>
      </form>
    </div>

    <div class="modal-email-message" role="alertdialog">
      <mat-error class="text-success" *ngIf="displaySuccessMessage">{{'Senha enviada com sucesso.' | translate}}</mat-error>
    </div>

    <div class="modal-email-message">
      <mat-error class="text-danger" *ngIf="displayErrorMessage">{{errorMessage}}</mat-error>
    </div>

    <div class="modal-email-actions">
      <button (click)="sendEmail()" [disabled]="!emailForm.valid" type="submit">
        <p>{{'modalGetTicket.buttonGetTicketEmail' | translate}}</p>
      </button>
    </div>

  </div>
</div>
