import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public year: number = new Date().getFullYear();
  public selectedLanguage: string

  constructor(private cookieService: CookieService) {

    const languageCookie = this.cookieService.get("GUEST_LANGUAGE_ID");
    this.selectedLanguage = languageCookie === "" ? "pt" : languageCookie
  }

  ngOnInit() {

  }

}
