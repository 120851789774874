import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Tutorial } from './models/tutorial';
import { Step } from './models/step';
import { TutorialService } from './services/tutorial.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})

export class TutorialComponent implements OnInit, OnDestroy {
  modal: any;
  mainContainer: any;
  errorMessage: string;
  tutorial: Tutorial;
  steps: Step;
  images: any[] = [];
  legendas: string[] = [];
  currentPage: number = 1;
  totalPages: number = 0;
  firstInteraction = true;

  counterInterval: any = 0;

  constructor(private tutorialService: TutorialService,
    private sanitizer: DomSanitizer) {
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.closeModal();
  }

  @HostListener("document:keydown.ArrowRight", ["$event"]) onRightKeydownHandler(
    event: KeyboardEvent
  ) {
    this.firstInteraction = false;
    console.log($('.carousel'));
    console.log('right');
    // document.getElementById('nextPageBtn').click();
  }

  @HostListener("document:keydown.ArrowLeft", ["$event"]) onLeftKeydownHandler(
    event: KeyboardEvent
  ) {
    this.firstInteraction = false;
    console.log($('.carousel'));
    console.log('left');
    // document.getElementById('prevPageBtn').click();
  }

  ngOnInit() {

    this.tutorialService.getTutorialActive()
      .subscribe(
        res => this.getTutorialActive(res),
        error => this.errorMessage);

    this.openModal();
    // document.getElementById('nextPageBtn').click();
    document.getElementById('prevPageBtn').click();
  }
  
  ngOnDestroy() {
    clearInterval(this.counterInterval);
  }

  getTutorialActive(res: Tutorial) {
    if (res.steps == null)
      this.closeModal();

    this.tutorial = res;
    if (this.tutorial.steps != null && this.tutorial.steps.length > 0) {
      this.totalPages = this.tutorial.steps.length;
      for (let i = 0; i < this.tutorial.steps.length; i++) {
        this.images.push(this.sanitizer.bypassSecurityTrustResourceUrl(this.tutorial.steps[i].image));
        this.legendas.push(this.tutorial.steps[i].description);
      }
      this.counterInterval = setInterval(() => this.pageCounter(), 10);
    }
  }

  pageCounter() {
    if (this.firstInteraction) {
      document.getElementById('nextPageBtn').focus();
      document.getElementById('nextPageBtn').click();
    }
    this.firstInteraction = false;
    var pages = document.getElementsByClassName('carousel-item');
    for (var i = 0; i < pages.length; i++) {
        if (pages[i].classList.contains('active')) {
          this.currentPage = i + 1;
          document.getElementById('tutorial-image-' + i).focus();
          break;
        }
    }
  }

  openModal() {
    this.hideWidgets();
    this.modal = document.getElementById("tutorialModal");
    this.modal.style.display = "block";
    document.getElementById("prevPageBtn").focus();

  }
  
  closeModal() {
    const modalBtn = document.getElementById("btnTutorialNavegacao");
    modalBtn.focus()
    this.modal.style.display = "none";
    this.showWidgets();
  }

  showWidgets() {
    const widgets = document.getElementById('widgetAssist');
    widgets.style.display = 'block';
  }

  hideWidgets() {
    const widgets = document.getElementById('widgetAssist');
    widgets.style.display = 'none';
  }
}
