import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from "rxjs";
import { filter, finalize, switchMap, take } from "rxjs/operators";
import { UserService } from "../auth/user.service";
import { Token } from "../auth/models/token";

@Injectable()
export class AuthInterceptor implements HttpInterceptor{
    private getTokenInProgress = false;
    private getTokenSubject = new BehaviorSubject(null);

    constructor(private authService: AuthService, 
                private userService: UserService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler){
        if (req.url.includes(environment.urlMapaApi) && !req.url.includes("token")){
            
            if (!this.authService.IsTokenGenerated() || this.authService.IsTokenExpired()){
                if (this.getTokenInProgress){
                    return this.getTokenSubject.pipe(
                        filter((result) => result), 
                        take(1), 
                        switchMap(() => next.handle(this.AddJwtTokenHttpRequest(req)))
                    );    
                } else {
                    this.getTokenInProgress = true;
                    this.getTokenSubject.next(null);
                    
                    return this.authService.GetApiToken().pipe(
                        switchMap((token) => {
                            this.authService.StoreToken(token);
                            this.getTokenSubject.next(token);
                            return next.handle(this.AddJwtTokenHttpRequest(req));
                        }), 
                        finalize(() => (this.getTokenInProgress = false))
                    );
                }
            }
            else {
                return next.handle(this.AddJwtTokenHttpRequest(req));
            } 
        }
        else {
            return next.handle(req);
        }
    }

    protected AddJwtTokenHttpRequest(req: HttpRequest<any>) : HttpRequest<any>{
        var jwtToken: Token = JSON.parse(localStorage.getItem('token'));
        return req.clone({
            headers : req.headers.set('Authorization', "Bearer "+ jwtToken.token)
                                 .set('Content-Type', 'application/json')
        });
    }
}