import { Injectable } from "@angular/core";
import { UserPosition } from "../mapa/models/userPosition";

@Injectable()
export class UserService{

  /**
   * Get UserPosition and set it in localStorage 
   * @returns userPosition
   */
    getUserPosition() {
        return new Promise((resolve, reject) => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                if (position) {
                  var user: UserPosition = new UserPosition(position.coords.latitude, position.coords.longitude);
                  localStorage.setItem('userPosition', JSON.stringify(user));
                  resolve(user);
                }
              },
              (error) => console.log(error)
            );
          } else {
            reject('Geolocation is not supported by this browser.');
          }
        });
    }

    /**
     * Get UserPosition and set it in localStorage if not already exists
     */
    public async setUserPosition() {
      var userPosition = localStorage.getItem('userPosition');
      if (userPosition == null){
        await this.getUserPosition();
      }
    }
}
