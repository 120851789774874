/**
 * Type of Search in CitizenMap 
 * @OrganizationNeaby {1} Get List of All Organization nearby user. 
 * @PointOfCare {2} Get PointOfCare by Code. Used by ePortugal SearchPoc 
 * @Organization {3} Get Organization By Code. Used by ePortugal SearchMainAddrEntity.
 * @Group {4} Get PointOfCare by Group.
 */
export enum SearchType {
    OrganizationNearby = 1,
    PointOfCare = 2, 
    Organization = 3, 
    Group = 4,
    SearchText = 5
}