import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of, throwError } from "rxjs";
import { ResponseData } from "../mapa/models/responseData";
import { Token } from "./models/token";
import { UserPosition } from "../mapa/models/userPosition";

@Injectable()
export class AuthService{
    constructor(private http: HttpClient){

    }
    
    protected apiKey = environment.apiKey;
    protected urlMapaApim = environment.urlMapaApi;
    protected azFunction = environment.azFunction;

    StoreToken(result : ResponseData<Token>){
        if (result.statusCode == 200){
            localStorage.setItem('token', JSON.stringify(result.data));
        }
        else {
            throwError(`Error GetToken. StatusCode: ${result.statusCode}\nMessage: ${result.message}`);
        }
    }
    
    IsTokenExpired() : boolean{
        var jwtToken: Token = JSON.parse(localStorage.getItem('token'));
        return jwtToken.expiresAt != null ? jwtToken.expiresAt * 1000 <= Date.now() : true;
    }

    IsTokenGenerated() : boolean{
        var jwtToken = localStorage.getItem('token');
        return jwtToken != null;
    }

    //Creates a Jwt Bearer Token HS256 algorithm
     GetApiToken(): Observable<ResponseData<Token>> {
        var userPosition: UserPosition = JSON.parse(localStorage.getItem('userPosition'));
        
        if (userPosition == null){
            console.log('userPosition not found in this device');
            userPosition = new UserPosition(0, 0);
        }
                 
        return this.http.get<ResponseData<Token>>(`${this.urlMapaApim}/${this.azFunction}/map/${this.apiKey}/${userPosition.latitude}/${userPosition.longitude}/token`);
    }
}