<div id="myModal" class="modal" role="dialog" aria-modal="true" style="z-index: 5;">
  <div class="container">
    <div id="modalContent" class="modal-content modal-content-responsive">
      <button class="close" (click)="closeModal()" id="closeModal" aria-label="Fechar a janela e voltar ao ePortugal">
        <span class="xClose" >×</span>
      </button>

      <div class="row row-background">
        <div class="leftSquare topBotSpace">
          <div class="contentLeft">

            <h2 [hidden]="image === null">
              <img *ngIf="image" class="image-top" [src]="image" alt="{{ image?.name }}">
            </h2>
            <div *ngFor="let item of splash?.areas;">
              <div
                [ngClass]="{'box-red box-responsive': selectedArea === item.id, 'box-non-red box-responsive': selectedArea !== item.id}"
                (click)="getAreaData(item.id)" id={{item.id}} [innerHTML]="item.title">
              </div>

            </div>
          </div>
        </div>

        <div class="rightSquare topBotSpace">
          <div class="contentRight fl">
            <div class="fontText" [innerHTML]="area?.content"></div>
          </div>

          <div class="divDots ml0">
            <button class="button gree mr15tt" (click)="goCovid()"><b>Saber mais</b></button>

            <button class="button red ttTa" (click)="goToNextArea()" [attr.aria-label]="isLastArea ? 'Fechar tutorial' : 'Avançar no tutorial' ">
              <b>{{isLastArea ? 'Fechar' : 'Seguinte'}}</b>
              <div class="w100">
                <img class="arrow-responsive" src="assets/icons/seta.png" alt="">
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
