<div>

  <div class="modal-email-close">
    <button mat-dialog-close aria-label="{{'Fechar' | translate}}">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>

  <p class="modal-email-text">{{'Introduza o seu email' | translate}}</p>

  <div class="modal-email-input">
    <form [formGroup]="emailForm">
      <mat-form-field>
        <mat-label>email</mat-label>
        <input id="email" type="email" placeholder="{{'E-mail (requerido)' | translate}}" formControlName="serviceText" matInput aria-label="Email">
        <span class="text-danger">
          <span [innerHTML]="displayMessage.email"></span>
        </span>
      </mat-form-field>
    </form>
  </div>

  <div class="modal-email-message" role="alertdialog">
    <mat-error class="text-success" *ngIf="displaySuccessMessage">{{'Senha enviada com sucesso.' | translate}}</mat-error>
  </div>

  <div class="modal-email-message">
    <mat-error class="text-danger" *ngIf="displayErrorMessage">{{errorMessage}}</mat-error>
  </div>

  <div class="modal-email-actions">
    <button (click)="sendEmail()" [disabled]="!emailForm.valid" type="submit">
      <p>{{'Enviar senha' | translate}}</p>
    </button>
  </div>
</div>
