import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from "rxjs";
import { environment } from 'src/environments/environment';

export abstract class BaseService {


    protected UrlService: string = environment.urlMapaApi;
    protected azFunction: string = environment.azFunction;

    protected GetHeaderJson() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
    }

    protected extractData(response: any) {
        return response.data || {};
    }

    protected serviceError(response: Response | any) {
        let customError: string[] = [];

        if (response instanceof HttpErrorResponse) {

            if (response.statusText === "Unknown Error") {
                customError.push("Ocorreu um erro desconhecido");
                response.error.errors = customError;
            }
        }

        return throwError(response);
    }

    /**Get language selected by user */
    protected getLanguage(){
        let storeLanguage = localStorage.getItem('selectedLanguage');
        return storeLanguage ? storeLanguage.slice(0, 2) : 'pt';
      }
}
