import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'

import { IndexComponent } from './core/index/index.component';

const routes: Routes = [
  { path: 'index', component: IndexComponent},
  { path: 'SearchService', component: IndexComponent },
  { path: 'SearchEntity', component: IndexComponent },
  { path: 'SearchPocService', component: IndexComponent },
  { path: 'SearchPocEntity', component: IndexComponent },
  { path: 'SearchPoint', component: IndexComponent },
  { path: 'SearchPoc', component: IndexComponent },
  { path: 'SearchMainAddrEntity', component: IndexComponent },
  { path: 'SearchText', component: IndexComponent },
  { path: '', redirectTo: '/index', pathMatch: 'full' },
  { path: '**', redirectTo:'' },
  { path: '404', redirectTo:'/index' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
