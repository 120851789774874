<div class="options" [hidden]="sideNavRight.opened" [ngClass]="showFilter ? 'options--open': ''">

  <div class="options-content options-content-style">
    <span id="firstHeaderText">{{'Definir área de pesquisa' | translate}}</span>

    <div id="matSliderHeader">
      <mat-slider role="slider" aria-input-field-name="Alterar Area" style="width:100%" color="primary" step="0.1"
        min="0" max="5" [(ngModel)]="area" (change)="AlterarArea()">
      </mat-slider>
    </div>
    <div id="matSliderHeaderDistance">
      <span>&nbsp;&nbsp;{{area}} km</span>
    </div>
    <div aria-label="explorar locais">
      <button class="options-button" (click)="AbrirBusca()" aria-label="explorar locais">
        <mat-icon svgIcon="filterIcon" aria-label="explorar locais"></mat-icon>
      </button>
    </div>
    <div aria-label="me localiza">
      <button role="button" class="options-button" (click)="ReposicionarGeolocalizacao()" aria-label="me localiza">
        <div class="options-button-pin">
          <mat-icon svgIcon="pinIcon" aria-label="me localiza"></mat-icon>
        </div>
      </button>
    </div>
    <div>

      <p *ngIf="mapa != null && mapa.coordinates != null" tabindex="0" id="LanguageOption">{{'total_point' | translate}}
        {{mapa.coordinates.length}}</p>

    </div>
    <button *ngIf="showFilter" class="options-button-hide" (click)="showFilterBar()" role="button" aria-label="Filtro">
      <mat-icon svgIcon="arrowBack" aria-hidden="true"></mat-icon>
    </button>
    <button *ngIf="!showFilter" class="options-button-hide" (click)="showFilterBar()" role="button" aria-label="Filtro">
      <mat-icon svgIcon="filterIcon" aria-hidden="true"></mat-icon>
    </button>
  </div>
</div>

<div *ngIf="!sideNavRight.opened" class="show-side-bar">
  <button class="btn show-side-bar-btn" (click)="AbrirListaLateralLocais()" role="button"
    aria-label="Locais perto de si, pressione para expandir a janela" id="sideNavClassId" tabindex="0">
    <mat-icon class="arrow-back" svgIcon="arrowBack" aria-hidden="true"></mat-icon>
  </button>
</div>
