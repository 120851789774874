import { confirmationType } from "./enum/confirmationType";
import { systemType } from "./enum/systemType";

/**Dto to get and send ticket by email or sms*/
export class ticketDto {
    organizationName: string;
    pointOfCareName: string;
    divisionName: string;
    sigaInstituteIdentifier: number;
    sigaEntityIdentifier: number; 
    sigaLocalIdentifier: number; 
    sigaServiceIdentifier: number;
    userDeviceId: string
    ticketNumber: string;
    sigaMessage: string;
    email: string;
    phoneNumber: string;
    languageCode: string;
    divisionId: number;
    confirmationType: confirmationType;
    systemType: systemType;

    /**
     * Create a new instance of SendTicketDto
     * @param organizationName Organization name
     * @param pointOfCareName Point Of Care name
     * @param divisionName Division service name
     * @param ticketNumber Siga ticket number
     * @param message Siga Message
     * @param email Recipient email
     * @param phoneNumber Phone Number
     */
    public static newTicketSend(organizationName: string, pointOfCareName: string, divisionName: string, 
                ticketNumber: string, message: string, languageCode: string) {
        
        const ticket = new ticketDto();
        
        ticket.organizationName = organizationName;
        ticket.pointOfCareName = pointOfCareName;
        ticket.divisionName = divisionName;
        ticket.ticketNumber = ticketNumber;
        ticket.sigaMessage = message;
        ticket.languageCode = languageCode;

        return ticket;
    }

    public static newTicket(organizationName: string, pointOfCareName: string, divisionName: string, 
                               sigaInstituteIdentifier: number, sigaEntityIdentifier: number, sigaLocalIdentifier: number, sigaServiceIdentifier: number, 
                               userDeviceId: string, languageCode: string, divisionId: number){

        const ticket = new ticketDto
        ticket.organizationName = organizationName;
        ticket.pointOfCareName = pointOfCareName;
        ticket.divisionName = divisionName;
        ticket.sigaInstituteIdentifier = sigaInstituteIdentifier;
        ticket.sigaEntityIdentifier = sigaEntityIdentifier;
        ticket.sigaLocalIdentifier = sigaLocalIdentifier;
        ticket.sigaServiceIdentifier = sigaServiceIdentifier;
        ticket.userDeviceId = userDeviceId;
        ticket.languageCode = languageCode;
        ticket.systemType = systemType.WebPortal;
        ticket.divisionId = divisionId;

        return ticket;
    }
}
