import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { BaseService } from 'src/app/core/services/base.service';
import { Splash } from '../models/splash';

@Injectable()
export class SplashService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    /*getSplashActive(): Observable<Splash> {
        let response = this.http
            .get<Splash>(this.UrlService + '/api/splash/active/web/pt', this.GetHeaderJson())
            .pipe(catchError(super.serviceError));

        return response;
    }*/
}
