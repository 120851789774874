import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";

import { BaseService } from 'src/app/core/services/base.service';
import { Points } from '../models/points';
import { Coordinates } from '../models/coordinates';
import { Group } from '../models/group';
import { ResponseData } from '../models/responseData';
import { MapPointDto } from '../models/mapPointDto';

@Injectable()
export class MapaService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  /**
   * Returns List of PointOfCare by Group
   * @param groupId identifier of group
   * @param language language of group's name
   * @returns ResponseData with List of MapPointDto
   */
  public getPointsOfCareByGroup(groupId: number, language: string): Observable<ResponseData<MapPointDto[]>> {    
    return this.http.get<ResponseData<MapPointDto[]>>(this.UrlService + `/${this.azFunction}/map/pointOfCare/group/${groupId}?languageCode=${language}`);          
  }

   /**
  * Returns List of Organization by Group
  * @param groupId identifier of group
  * @param language language of group's name
  * @returns ResponseData with List of MapPointDto
  */
  public getOrganizationsByGroup(groupId: number, language: string): Observable<ResponseData<MapPointDto[]>> {    
    return this.http.get<ResponseData<MapPointDto[]>>(this.UrlService + `/${this.azFunction}/map/organization/group/${groupId}?languageCode=${language}`);          
  }

  /**
   * Returns List of Organization Nearby user. 
   * User's Latitude and Longitude will be extracted from Jwt token, which is passed in each http request
   * @param areaInMeters area in Meters to search
   * @param language language to filter organization's title 
   * @returns ResponseData with List of MapPointDto
   */
  public getOrganizationsNearby(areaInMeters: number, language: string): Observable<ResponseData<MapPointDto[]>> {
    return this.http.get<ResponseData<MapPointDto[]>>(this.UrlService + `/${this.azFunction}/map/organization/nearby/${areaInMeters}?languageCode=${language}`);
  }

  /**
   * Returns List of points filtered by user text and ordered by nearest distance. 
   * User's Latitude and Longitude will be extracted from Jwt token, which is passed in each http request
   * @param searchText text used to search for points
   * @param language language to filter organization's title 
   * @returns ResponseData with List of MapPointDto
   */
  public getPointsByText(text: string, language: string): Observable<ResponseData<MapPointDto[]>> {
    return this.http.get<ResponseData<MapPointDto[]>>(this.UrlService + `/${this.azFunction}/map/point/searchText?text=${text}&languageCode=${language}`);
  }

  /**
   * Get active groups to search
   * @param {string} language language to filter group's title 
   * @returns ResponseData with List of Groups
   */
  getGroupsActive(language: string): Observable<ResponseData<Group[]>> {
    return this.http.get<ResponseData<Group[]>>(`${this.UrlService}/${this.azFunction}/map/point/groups?languageCode=${language}`);
  }

  /**
   * Get PointOfCare by code
   * @param code code of pointOfCare
   * @param language language to filter pointOfCare's title 
   * @returns ResponseData with List of MapPointDto
   */
  getPointOfCareByCode(code: string, language: string): Observable<ResponseData<MapPointDto[]>> {
    return this.http.get<ResponseData<MapPointDto[]>>(`${this.UrlService}/${this.azFunction}/map/pointOfCare/code/${code}?languageCode=${language}`);
  }

  /**
   * Get Organization by code
   * @param code code of Organization
   * @param language language to filter organization's title 
   * @returns ResponseData with List of MapPointDto
   */
  getOrganizationByCode(code: string, language: string): Observable<ResponseData<MapPointDto[]>> {
    return this.http.get<ResponseData<MapPointDto[]>>(`${this.UrlService}/${this.azFunction}/map/organization/code/${code}?languageCode=${language}`);
  }

}