import { Component, Input } from "@angular/core";
import { MapaComponent } from "../mapa/mapa.component";

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})

export class SearchComponent{
    @Input() mapa: MapaComponent;

    searchText: string = "";
    inputHasFocus: boolean = false;

    public searchOnCitizenMap(){
        this.mapa.titleSearchBy = this.searchText;
        this.mapa.getPointsByText(this.searchText);

        this.searchText = "";
        this.inputHasFocus = false;

        console.log("widthMapa" +this.mapa.widthMap);
        console.log("widthInputSearch"+ this.mapa.widthInputSearch);
    } 

    public inputSetFocus(focus: boolean){
        this.inputHasFocus = focus;
    }
}

