import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import 'hammerjs';
import { ApiAccessService } from '../app/core/services/apiAccess.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SplashService } from './core/splash/services/splash.service';
import { TutorialService } from './core/tutorial/services/tutorial.service';
import { SidebarService } from './core/sidebarright/services/sidebar.service';
import { ModalService } from './core/modal/services/modal.service';
import { MapaService } from './core/mapa/services/mapa.service';
import { NgxTranslateModule } from './translate/translate.module';
import { AuthService } from './core/auth/auth.service';
import { AuthInterceptor } from './core/interceptor/authconfig.interceptor';
import { ErrorIntercept } from './core/interceptor/error.interceptor';
import { UserService } from './core/auth/user.service';
import { MatIconModule } from '@angular/material/icon';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    NgxTranslateModule,
    MatIconModule
  ],
  exports: [
    MatIconModule
  ],
  providers: [
    ApiAccessService, SplashService, TutorialService, SidebarService, ModalService, MapaService, AuthService, UserService, CookieService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }



