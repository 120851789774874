import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { BaseService } from './base.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})

export class ApiAccessService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  BuscarIpPublico(): Observable<any> {
    return this.http.get<any>('https://api.ipify.org?format=json');
  }

}

