<button
  id="nearby-poi-{{ data.locationId }}"
  class="{{ data.class }}"
  attr.aria-label="{{ data.title }} {{ data.distance }}"
  (click)="onclick.emit(data)"
  (mouseenter)="onmouseenter.emit(data)"
  (mouseleave)="onmouseleave.emit(data)"
>
  <div class="image-box">
    <img aria-hidden="true" src="{{ data.imageUrl }}"  class="img-locais" alt="{{ 'imagem do local' | translate }}">
    <!--<mat-icon svgIcon="{{ data.imageUrl }}" aria-hidden="true"></mat-icon>-->
  </div>

  <div class="poi-content">
    <p class="content-title">{{ data.title }}</p>

    <div class="content-distance" style="display: flex;flex-wrap: wrap;">
      {{ data.distance }}
    </div>
  </div>

  <div class="arrow">
    <mat-icon svgIcon="arrowForward" aria-hidden="true"></mat-icon>
  </div>
</button>
