import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { trigger, style, animate, transition } from '@angular/animations';
import { TranslateService } from "@ngx-translate/core";
import { MatSidenav } from "@angular/material";
import { UserService } from '../auth/user.service';
import { UserPosition } from '../mapa/models/userPosition';

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.scss"],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ])
    ]
    )
  ]
})

export class IndexComponent implements OnInit, AfterViewInit {
  title: string = "app";
  events: string[] = [];
  opened: boolean;
  openedRight: boolean;
  closeInterval: any = 0;
  closedByDeviceType: boolean = false;
  userPosition: UserPosition;

  mobile: boolean = false;
  iOS: boolean = false;

  @ViewChild('sideRight', {static: false}) sideRight: MatSidenav;

  @Input() set selectedLanguage(language: string) {
    if (language) {
      this._selectedLanguage = language;
      localStorage.setItem("languageSelected", language);
      this.translate.use(language);
    }
  }

  get selectedLanguage(): string {
    return this._selectedLanguage;
  }

  public _selectedLanguage: string;

  constructor(private translate: TranslateService,
              private userService: UserService) {

    var mbl = this.MobileCheck();

    if (mbl.any()) {
      this.mobile = true;
    }

    if (this.mobile) {
      if (mbl.iOS()) this.iOS = true;
    }
  }

  async ngOnInit() {
    localStorage.removeItem("languageSelected");
  }

  ngAfterViewInit() {
    const elements = document.querySelectorAll('.cdk-visually-hidden.cdk-focus-trap-anchor');
    elements.forEach(element => {
      element.remove();
    });
    this.closeWhenMobile();
  }

  closeWhenMobile() {
    if (this.mobile) {
      this.sideRight.close();
    }
  }

  clearCloseInterval() {
    clearInterval(this.closeInterval);
  }

  public onSelectedLanguage(language: string): void {
    this.selectedLanguage = language;
  }

  MobileCheck() {
    var isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return (
          navigator.userAgent.match(/IEMobile/i) ||
          navigator.userAgent.match(/WPDesktop/i)
        );
      },
      any: function () {
        return (
          isMobile.Android() ||
          isMobile.BlackBerry() ||
          isMobile.iOS() ||
          isMobile.Opera() ||
          isMobile.Windows()
        );
      },
    };

    return isMobile;
  }

}
