import { Component, ElementRef, Inject, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { fromEvent, merge, Observable } from 'rxjs';

import { MAT_DIALOG_DATA } from '@angular/material';

import { DisplayMessage, GenericValidator, ValidationMessages } from '../../utils/generic-form-validation';
import { ticketDto } from '../models/ticketDto';
import { ModalService } from '../services/modal.service';
import { ResponseDto } from '../../mapa/models/responseDto';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss']
})
export class EmailModalComponent implements OnInit {

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { ticket: ticketDto }, 
              private fb: FormBuilder, 
              private modalService: ModalService, 
              private translate: TranslateService) {

    this.validationMessages = {
      serviceText: {
        required: 'Informe o e-mail',
        serviceText: 'E-mail inválido'
      }
    };

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  emailForm: FormGroup;
  errorMessage: string;

  validationMessages: ValidationMessages;
  genericValidator: GenericValidator;
  displayMessage: DisplayMessage = {};
  displayErrorMessage: boolean = false;
  displaySuccessMessage: boolean = false;

  ngAfterViewInit(): void {
    let controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    merge(...controlBlurs).subscribe(() => {
      this.displayMessage = this.genericValidator.processarMensagens(this.emailForm);
    });
  }

  ngOnInit() {

    this.emailForm = this.fb.group({
      serviceText: ['', [Validators.required, Validators.email]]
    })
  }

  sendEmail() {
    this.displayErrorMessage = false;
    this.displaySuccessMessage = false;

    if (this.emailForm.dirty && this.emailForm.valid) {
      this.data.ticket.email = this.emailForm.value.serviceText;
      this.modalService.sendTicketEmail(this.data.ticket).subscribe(res => this.processResponse(res));
    }
  }

  /**
   * Process Response, after send Email
   * @param response 
   */
  processResponse(response: ResponseDto) {
    if (response.statusCode == 200)
      this.displaySuccessMessage = true;
    else if (response.statusCode < 100){//Business Error
      this.displayErrorMessage = true;
      this.errorMessage = response.message;
    }
    else{
      this.displayErrorMessage = true;
      this.errorMessage = this.translate.instant("Não foi possível enviar sua senha.");
    }

    this.emailForm.reset();
  }
}
