import { Component, OnInit, ViewChildren, ElementRef } from '@angular/core';
import { Input } from '@angular/core';
import { MapaComponent } from '../mapa/mapa.component';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { ApiAccessService } from '../services/apiAccess.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ErrorAlertComponent } from '../error-alert/error-alert.component';
import { SuccessAlertComponent } from '../success-alert/success-alert.component';
import { GoogleAnalyticsService } from 'angular-ga';
import { ModalComponent } from '../modal/modal.component';

import { TutorialService } from '../tutorial/services/tutorial.service';
import { Notification } from '../tutorial/models/notification';
import { SidebarService } from './services/sidebar.service';
import { Click } from './models/click';
import { Provider } from '../mapa/models/provider';
import { ServiceDemands } from '../chart/models/service-demands';
import { BaseService } from '../services/base.service';
import { LanguageService } from '../shared/services/language.service';
import { SearchForData } from './explore-panel/entities/search-for-data';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../auth/user.service';
import { ResponseData } from '../mapa/models/responseData';
import { MapPointInfoDto } from './models/mapPointInfoDto';
import { MapPointDto } from '../mapa/models/mapPointDto';
import { PointOfCareDto } from './models/pointOfCareDto';
import { PointOfCareDivisionDto } from './models/pointOfCareDivisionDto';
import { DivisionServiceInfoDetailDto } from './models/divisionServiceInfoDetailDto';
import { Group } from '../mapa/models/group';
import { ConfirmationModalComponent } from '../modal/confirmation-modal/confirmation-modal.component';
import { ticketDto } from '../modal/models/ticketDto';
import { tick } from '@angular/core/testing';
import { ChartComponent } from '../chart/chart.component';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-sidebarright',
  templateUrl: './sidebarright.component.html',
  styleUrls: ['./sidebarright.component.scss']
})
export class SidebarrightComponent extends BaseService implements OnInit {
  @Input() mapa: MapaComponent;
  @Input() divLoader: HTMLDivElement;
  @ViewChildren('senhasCanvas') senhasCanvas: ElementRef;

  //#region Global variables 
  showFilterByGroup: boolean = true;
  selectedPoint: MapPointDto;
  pointsOfCare: PointOfCareDto[] = [];
  showListPointsOfCare: boolean = false;
  showAlertPointOfCareNotFound: boolean = false;
  showAlertDivisionNotFound: boolean = false;
  showAlertTicketInformationNotFound: boolean = false;
  pointOfCareDivisions: PointOfCareDivisionDto[] = [];
  /**
   * Divisions Ticket Info List of selected PointOfCare
   */
  selectedPointOfCareDivisionsTicket: DivisionServiceInfoDetailDto[] = []; 
  /**
   * Division Selected by User to show Ticket Information
   */
  selectedDivisionTicketInfo: DivisionServiceInfoDetailDto;
  showListDivision: boolean = false;
  showTicketArea: boolean = false;
  imgTicketLoading: boolean = false;
  userDeviceIp: string;
  
  closeddivisionServiceInfoDetailDto: DivisionServiceInfoDetailDto = {
    sigaInstituteIdentifier: 0,
    sigaEntityIdentifier: 0,
    sigaLocalIdentifier: 0,
    sigaServiceIdentifier: 0,
    isOpen: false,
    waitingTimeLastTicket: "0:00",
    averageServiceTime: null,
    waitingUsers: 0,
    openingHour: "0",
    serviceName: null,
    lastTicketNumberAttended: 0,
    waitingStatus: null
};


  //#endregion


  @Input() set selectedLanguage(language: string) {
    if (language) {
      this._selectedLanguage = language;
      localStorage.setItem("languageSelected", language);
      this.translate.use(language);
    }
    let selectedLanguage = localStorage.getItem('selectedLanguage');
    selectedLanguage = selectedLanguage ? selectedLanguage.slice(0,2) : 'pt';

    let langMap = {
      'EN': { language: 'EN', country: 'GB' },
      'PT': { language: 'PT', country: 'PT' }
    };

    this.language = (langMap[selectedLanguage.toUpperCase()] || langMap.PT).language;
    this.country = (langMap[selectedLanguage.toUpperCase()] || langMap.PT).country;

    this.languageService.setLanguage(this.language);
    this.languageService.setCountry(this.country);

    this.translate.use(selectedLanguage);
  }

  public searchControl: FormControl = new FormControl();
  public exploreSearchForm: SearchForData;

  get selectedLanguage(): string {
    return this._selectedLanguage;
  }

  public _selectedLanguage: string;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  ]);

  phoneFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/([9]{1})\)?([1-3|6]{1})\)?([0-9]{7}){1,10}$/)
  ]);

  matcher = new MyErrorStateMatcher();

  permission_geo: any;
  provider: Provider

  showLessTopDigitalServices = false;
  showClearPointsButton = false;
  showClearLifeEventsButton = false;

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private service: ApiAccessService,
    private tutorialService: TutorialService,
    private sidebarService: SidebarService,
    private translate: TranslateService,
    private gaService: GoogleAnalyticsService,
    private readonly languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    super();

    this.registerIcon();

    translate.use(localStorage.getItem('languageSelected') || "pt");

    translate.get(this.Telemovel).subscribe((text: string) => { this.Telemovel = text });
    translate.get(this.Email).subscribe((text: string) => { this.Email = text });

    translate.get(this.TelemovelEmpty).subscribe((text: string) => { this.TelemovelEmpty = text });
    translate.get(this.TelemovelInvalid).subscribe((text: string) => { this.TelemovelInvalid = text });

    translate.get(this.TdasFreguesias).subscribe((text: string) => { this.TdasFreguesias = text });
    translate.get(this.TdasCategorias).subscribe((text: string) => { this.TdasCategorias = text });
    translate.get(this.TdosMunicipios).subscribe((text: string) => { this.TdosMunicipios = text });

    translate.get(this.EmailEmpty).subscribe((text: string) => { this.EmailEmpty = text });
    translate.get(this.EmailInvalid).subscribe((text: string) => { this.EmailInvalid = text });
    translate.get(this.EmailInvalid).subscribe((text: string) => { this.EmailInvalid = text });
    translate.get('permission_geo').subscribe((text: string) => { this.permission_geo = text });

    translate.get('Todos').subscribe((text: string) => { this.todosOption = text });

  }

  public registerIcon(){
    this.iconRegistry.addSvgIcon('emailIcon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/emailIcon.svg'));
    this.iconRegistry.addSvgIcon('infoIcon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/infoIcon.svg'));
    this.iconRegistry.addSvgIcon('planeIcon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/planeIcon.svg'));
    this.iconRegistry.addSvgIcon('ticketIcon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ticketIcon.svg'));
    this.iconRegistry.addSvgIcon('webIcon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/webIcon.svg'));
    this.iconRegistry.addSvgIcon('phoneIcon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/phoneIcon.svg'));
    this.iconRegistry.addSvgIcon('directionsIcon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/directionsIcon.svg'));
    this.iconRegistry.addSvgIcon('entityIcon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/entityIcon.svg'));
    this.iconRegistry.addSvgIcon('adressIcon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/adressIcon.svg'));
    this.iconRegistry.addSvgIcon('closeIcon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg'));
    this.iconRegistry.addSvgIcon('ticket', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ticket_icon.svg'));
    this.iconRegistry.addSvgIcon('menu', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/menu.svg'));
    this.iconRegistry.addSvgIcon('arrowForward', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow_forward.svg'));
    this.iconRegistry.addSvgIcon('arrowDown', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/setapz.svg'));
    this.iconRegistry.addSvgIcon('arrowUp', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/setapV.svg'));
    this.iconRegistry.addSvgIcon('pinLocation', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pin_location.svg'));
    this.iconRegistry.addSvgIcon('check', this.sanitizer.bypassSecurityTrustResourceUrl('assets/SVG/icone_Artboard 24_visto.svg'));
    this.iconRegistry.addSvgIcon('info', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon_info.svg'));
    this.iconRegistry.addSvgIcon('directions', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/directions_arrow.svg'));
    this.iconRegistry.addSvgIcon('search', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/search.svg'));
    this.iconRegistry.addSvgIcon('arrowBack', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow_back.svg'));
    this.iconRegistry.addSvgIcon('clock', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/clock.svg'));
    this.iconRegistry.addSvgIcon('globle', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/globle.svg'));
    this.iconRegistry.addSvgIcon('arrowEast', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow_east.svg'));
    this.iconRegistry.addSvgIcon('ticketUnavailable', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ticket_unavailable.svg'));
    this.iconRegistry.addSvgIcon('schedule', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/schedule.svg'));
    this.iconRegistry.addSvgIcon('group', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/group.svg'));
    this.iconRegistry.addSvgIcon('nearbyPlaces', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/nearby-places.svg'));
  }

  // Variaveis de labels para translate
  EnviarSenhaPorEmail: string = "Receber senha por email";
  EnviarSenhaPorSMS: string = "Receber senha por SMS";
  Enviar: string = "Enviar";
  VerFicha: string = "Ver Ficha";
  TirarSenhaPorEmail: string = "Receber senha por email";
  TirarSenhaPorSMS: string = "Receber senha por SMS";
  Telemovel: string = "Telemóvel";
  TelemovelEmpty: string = "Indique o seu telemóvel";
  TelemovelInvalid: string = "Introduza um número de telemóvel válido";
  TdasCategorias: string = "Todos"; //"Todas as categorias";
  TdasFreguesias: string = "Todas"; //"Todas as freguesias";
  TdosMunicipios: string = "Todos"; //"Todos os municípios";
  EmailEmpty: string = "Indique o seu email";
  EmailInvalid: string = "Introduza um email válido";
  Email: string = "Email"
  todosOption: string  = 'Todos';

  pathImgDivisaoSeta: string = "assets/icons/setapz.svg";

  pathImgDivisaoLoading: string = "assets/icons/wait.gif";
  waitingTicketStatistics: boolean = false;
  imgDivisaoLoading: number = 0;

  latAtual: number = 0;
  lngAtual: number = 0;

  /**@deprecated */
  //publicIp: string = "";

  userId: number = 0;

  tipoEnvio: number = 0;
  emailSMS: string = "";

  emailEnviar: string = "";
  smsEnviar: string = "";

  listCategorySelected: string = "";
  listServiceSelected: string = "";
  listDistrictSelected: string = "";
  listMunicipioSelected: string = "";
  listFreguesiaSelected: string = "";

  selectedFilterCode: string = "";
  selectedFilter: boolean = false;

  require: any;

  mostrarBotaoTopPage: any = [false, false, false, false];

  umLocalMesmaLat: boolean = false;

  country: string = 'PT';
  language: string = 'PT';

  //Variáveis referentes ao ponto seleccionado
  titulo: String = 'Loja do cidadão - Laranjeiras';
  informacao: String = 'O elétron (português brasileiro) (eletrão [português europeu], electrão [português angolano]) (do grego ήλεκτρον, élektron, "âmbar") é uma partícula subatômica, de símbolo e−ou β−, com carga elétrica negativa.[8] Pertence à primeira geração da família dos léptons,[9] e considera-se que são partículas elementares porque não possuem componentes conhecidos.[1] A massa do elétron é aproximadamente 1/1836 da massa do próton.[10] As propriedades quânticas do elétron incluem um momento angular intrínseco (spin) fracionário, o que significa que é um férmion. Portanto, dois elétrons não podem ocupar o mesmo estado quântico, de acordo com o princípio da exclusão de Pauli.[9]';
  imgPath: String = 'http://i.imgur.com/kSWV8dy.jpg';
  image: String = ''
  site: string = '';
  telefone: string[] = [];
  morada: string = '';
  emails: string[] = [];
  horario: string[] = [];
  linkEntidade: string = '';
  linkLivroAmareloLocal: string = '';
  restricoes: String[] = [];
  restricaoHighlight: String[] = [];
  idPonto: number = 0;
  latPonto: number = 0;
  lngPonto: number = 0;

  idEntidade: number = 0;
  idServico: number = 0;
  idSenha: number = 0;

  
  mostrarEntidade: boolean = false;
  mostrarBusca: boolean = false;
  mostrarSenha: boolean = false;
  mostrarLocal: boolean = false;
  mostrarEntidadeInfo: boolean = false;
  mostrarEntidadeServicos: boolean = false;
  mostrarEntidadeServicosDetail: boolean = false;
  mostrarSenhaDetail: boolean = false;
  


  mostrarEntidadeDivisoes: boolean = false;

  mostrarDetalheSenha: boolean = false;
  mostrarInserirEmail: boolean = false;
  mostrarInserirTelemovel: boolean = false;
  idSenhaClickedDeatil: number = 0;
  idServicoClickedDeatil: string = '';

  chartData: ServiceDemands = null;

  entidadesDB: Entidade =
    {
      idEntidade: 0,
      idPonto: 0,
      logoPath: '',
      nome: '',
      informacao: '',
      site: '',
      telefone: '',
      morada: '',
      email: '',
      listaEntidadesLocal: [
        {
          idEntidade: 0,
          idPonto: 0,
          logoPath: '',
          nome: '',
          informacao: '',
          site: '',
          telefone: '',
          morada: '',
          email: '',
          servicos: [],
          divisoes: [],
          qtdePocs: 0,
          qtdeObjetoPorTexto: 0,
          linkAgendamento: ''
        }
      ]
    };

  servicos: any[] = [{
    "code": String,
    "name": String
  }]

  origemCaminhoEntidadeSenha: number;

  informacaoCompleta: boolean = false;

  idServiceClicked: number = 0;

  idSenhaClicked: number = 0;

  idSenhaNonHistoricClicked: number = 0;
  divisionCodeClicked: string = '';

  entidade: Entidade = this.entidadesDB;
  entidadesSenhas: EntidadeLocalViewModel[] = this.entidadesDB.listaEntidadesLocal;

  senhasHistorico: Senha[];

  sendMessage: string = "";

  //objeto com as informacoes completas da Entidade

  distritos: Item[] = [];
  municipios: Item[] = [];
  freguesias: Item[] = [];
  tipos: Category[] = [];

  distritosTodos: Item[] = [];
  municipiosTodos: Item[] = [];
  freguesiasTodos: Item[] = [];
  tiposTodos: Category[] = [];
  totalLocaisServicos: TotalLocaisServicosViewModel = { locais: 0, servicos: 0 };
  notificarTutorial: Notification;
  tiposFiltro: any[] = [];

  //usar geolocalização ao invés dos filtros por região
  filterUseGeoLocation: boolean = false;

  entidadeEscolhida: EntidadeLocalViewModel = this.entidadesDB.listaEntidadesLocal[0];

  senhaTirada: Senha = {
    idSenha: 1,
    codigoSenha: 'B0002',
    tipoEnvio: 0,
    email: 'B0002',
    sms: 'B0002',
    nomePonto: 'Gabinete de Planeamento, Políticas e Administração Geral',
    nomeEntidade: 'Instituto de Financiamento da Agricultura e Pescas, I.P.',
    nomeServico: 'Pedir a autorização de residência',
    dataEmissao: this.GetCurrentFormatDate(),
    dataHoraEmissao: this.GetCurrentFormatDateHour(),
    mensagemErro: '',
    mensagem: '',
    divisionName: ''
  };


  //Variáveis referentes ao controle de exibição das informações
  mostrarEntidades: boolean = false;
  mostrarSenhas: boolean = false;
  
  mostrarInfos: boolean = true;
  
  mostrarSobre: boolean = false;
  mostrarDirecoes: boolean = false;
  drivingTime: string = "";
  walkingTime: string = "";

  mostrarMinhasSenha: boolean = true;
  mostrarHistorico: boolean = false;

  //Variáveis referentes as divisiões de senhas
  divisoes: Divisao[] = [];

  existemEntidadesSenhas: boolean = true;

  busca: Busca = {
    district: '',
    county: '',
    parish: '',
    type: '',
    pointName: '',
    category: '',
  };

  //Variáveis referentes a Busca
  mostrarPesquisa: boolean = false;
  mostrarCategorias: boolean = false;
  mostrarServicos: boolean = false;
  mostrarDistritos: boolean = false;
  mostrarMunicipios: boolean = false;
  mostrarFreguesias: boolean = false;
  mostrarErroPesquisa: boolean = false;
  mensagemErroPesquisa: string = "";

  showBtnServices = false;
  showBtnDistrict = false;
  showBtnMunicipio = false;

  categoriaEscolhida: string = "";
  servicoEscolhido: string = "";
  distritoEscolhida: string = "";
  municipioEscolhida: string = "";
  freguesiaEscolhida: string = "";

  tipoFiltroEscolhido: number = 1;

  listaServicos: any[] = [
    { code: "" },
    { name: "" },
  ];

  habilitarBusca: boolean = false;

  qtdeBalcaoComTextoProcurado: number = 0;

  mostrarMais: boolean = false;

  mostrarMaisLocais: boolean = false;
  mostrarMaisDigitalServices: boolean = false;
  mostrarMaisTopDigitalServices: boolean = false;
  mostrarMaisGrupos: boolean = false;
  mostrarMaisEventosVida: boolean = false;

  mostrarHorario: boolean = false;
  mostrarTelefone: boolean = false;
  mostrarTutorial: boolean = false;
  waiting: string;

  //digitalServices: DigitalServices[] = [];
  topDigitalServicesPage: number = 0;
  itemActive = '';

  //#region methods List Map's Points 
    /**
     * Initialize Sidebar, cleaning all variables of search. 
     * Used at initial search to show List MapPointsDto object.
    */
    public InitializeSidebar() {
      this.mapa.showListMapPoints = true;
      this.mostrarSobre = false
      this.mostrarEntidade = false;
      this.mostrarBusca = false;
      this.mostrarSenha = false;
      this.mostrarLocal = false;
      this.mostrarInfos = false;
      this.mostrarEntidades = false;
      this.showListPointsOfCare = false;
      this.umLocalMesmaLat = false;
      this.showListDivision = false
      this.selectedPoint =  null;
      this.pointsOfCare = [];
      this.showAlertPointOfCareNotFound = false;
      this.showAlertDivisionNotFound = false;
      this.showAlertTicketInformationNotFound = false;
      this.imgTicketLoading = false;
      this.pointOfCareDivisions = [];
    }
    //#endregion

  MostrarHorario() {
    if (this.horario?.filter(String).length > 0) {
      if (this.horario.length == 1 && this.horario[0] == '') {
        this.mostrarHorario = false;
      }
      else
        this.mostrarHorario = true;
    }
    else
      this.mostrarHorario = false;
  }

  MostrarTelefone() {
    if (this.telefone?.filter(String).length > 0) {
      if (this.telefone.length == 1 && this.telefone[0] == '') {
        this.mostrarTelefone = false;
      }
      else
        this.mostrarTelefone = true;
    }
    else
      this.mostrarTelefone = false;
  }

  ShowTutorial() {
    this.mostrarTutorial = !this.mostrarTutorial;
  }

  OpenAccessibilityDeclaration() {
    window.open('https://eportugal.gov.pt/acessibilidade');
  }

  toggleMostraMais() {
    this.mostrarMais = !this.mostrarMais;
  }

  /**Usado na tela.
   * Refazer esse filtro. Ruth 23/05/2023
   * @deprecated
   */
  /*carregarFiltros() {
    this.tiposFiltro = [
      { code: 1, name: 'Mais próximo' },
      { code: 2, name: 'Serviços abertos' },
      // { code: 3, name: 'Tempo de Espera' },
      // { code: 4, name: 'Tempo de deslocação' },
      // { code: 5, name: 'Mais rápido' }
    ];
  }*/

  VoltarEstadoOriginal() {
    (<HTMLElement>document.getElementsByClassName('fundo')[0]).scrollTop = 0;
    (<HTMLElement>document.getElementsByClassName('fundo')[1]).scrollTop = 0;
    (<HTMLElement>document.getElementsByClassName('fundo')[2]).scrollTop = 0;
    (<HTMLElement>document.getElementsByClassName('fundo')[3]).scrollTop = 0;

  }

  /*() {
    //this.mapa.nenhumPontoEncontrado = false;

    var here = this;
    if (this.filterUseGeoLocation) {
      navigator.geolocation.watchPosition(function (position) {

      },
        function (error) {
          if (error.code == error.PERMISSION_DENIED)
            here.openDialog(this.permission_geo);
          here.filterUseGeoLocation = false;
        });
    }

    this.mostrarErroPesquisa = false;
    this.mensagemErroPesquisa = '';
  }*/

  /*buscarPontosCategoria(id: string) {
    this.boxSelected(id);
    this.mapa.ListarPontos(this.mapa.userPosition.latitude, this.mapa.userPosition.longitude, id, '', '', '', '', this.mapa.radius, 1, parseInt(this.tipoFiltroEscolhido.toString()));

    //code in 19/05/2020 pedido de Pedro Viana
    this.mapa.centerMap(this.mapa.userPosition.latitude, this.mapa.userPosition.longitude);
    //End
  }*/

  getPagination(id: number)
  {
    const list = document.querySelectorAll('ul li');
    const lastId = list[list.length - 1].id;
  }


 /* clearCategory() {
    this.mapa.pointsParams.zoom = 14;
    this.mapa.ListarPontos(this.mapa.userPosition.latitude, this.mapa.userPosition.longitude, '', '', '', '', '', this.mapa.radius, 1, parseInt(this.tipoFiltroEscolhido.toString()));
    this.removeBoxSelected();
  }*/

  /* botao limpar
  clearPoints() {
    this.mapa.zoom = 14;
    this.mapa.radius = 2500;
    this.mapa.pointsParams.pageNumber = 0;
    this.mapa.textSearch = '';
    this.mapa.pointsParams.pointName = '';
    this.mapa.pointsParams.searchType = 1;
    this.mapa.selectMethodInitial = true;

    this.mapa.resetUrl();
    //this.mapa.SelectMethodInitial();
    this.resetDropDownFilter();
    this.mapa.ListarPontos(this.mapa.userPosition.latitude, this.mapa.userPosition.longitude, '', '', '', '', '', this.mapa.radius, 1, 1);
    this.mapa.ResizeMap();
    this.mapa.centerMap(this.mapa.userPosition.latitude, this.mapa.userPosition.longitude);
    this.itemActive = '';
    this.hideClearButtons();
    this.exploreSearchForm = null;
    this.mapa.showMorePoints = true;
  }*/

  hideClearButtons() {
    this.tipoFiltroEscolhido = 1;
    this.showClearPointsButton = false;
    this.showClearLifeEventsButton = false;
  }

  /* botao veja mais
    showNextPointList() {
    this.mapa.selectMethodInitial = false;
    this.mapa.zoomChanged = false;
    this.mapa.seeMore = true;

    this.mapa.ListarPontos(this.mapa.userPosition.latitude, this.mapa.userPosition.longitude, this.mapa.pointsParams.type, this.mapa.pointsParams.pointName, '', '', '', this.mapa.radius, this.mapa.pointsParams.searchType, parseInt(this.tipoFiltroEscolhido.toString()));
  }*/

  boxSelected(id: string) {
    this.removeBoxSelected();

    switch (id) {
      case '55':
        document.getElementById('box-55').classList.add('boxSelected');
        break;
      case '84':
        document.getElementById('box-84').classList.add('boxSelected');
        break;
      case '61':
        document.getElementById('box-61').classList.add('boxSelected');
        break;
      case '106':
        document.getElementById('box-106').classList.add('boxSelected');
        break;
      case '77':
        document.getElementById('box-77').classList.add('boxSelected');
        break;
      case '102':
        document.getElementById('box-102').classList.add('boxSelected');
        break;
      case '404':
        document.getElementById('box-404').classList.add('boxSelected');
        break;
      case '408':
        document.getElementById('box-408').classList.add('boxSelected');
        break;
      case '407':
        document.getElementById('box-407').classList.add('boxSelected');
        break;
      case '43':
        document.getElementById('box-43').classList.add('boxSelected');
        break;
      case '70':
        document.getElementById('box-70').classList.add('boxSelected');
        break;
      case '111':
        document.getElementById('box-111').classList.add('boxSelected');
        break;
    }
  }

  removeBoxSelected() {
    document.getElementById('box-55').classList.remove('boxSelected');
    document.getElementById('box-84').classList.remove('boxSelected');
    document.getElementById('box-61').classList.remove('boxSelected');
    document.getElementById('box-106').classList.remove('boxSelected');
    document.getElementById('box-77').classList.remove('boxSelected');
    document.getElementById('box-102').classList.remove('boxSelected');
    document.getElementById('box-404').classList.remove('boxSelected');
    document.getElementById('box-408').classList.remove('boxSelected');
    document.getElementById('box-407').classList.remove('boxSelected');
    document.getElementById('box-43').classList.remove('boxSelected');
    document.getElementById('box-70').classList.remove('boxSelected');
    document.getElementById('box-111').classList.remove('boxSelected');
  }

  CategoriaSelecionada(categoryCode: string, name: string, code = null) {
    this.busca.type = code;

    if (this.listCategorySelected == categoryCode) {
      this.listCategorySelected = ''
      this.categoriaEscolhida = "";
      this.mostrarErroPesquisa = true;
      this.servicoEscolhido = '';
    }
    else {
      this.listCategorySelected = categoryCode;
      this.categoriaEscolhida = " : " + name;
      this.listaServicos = [];
      this.servicoEscolhido = '';
      this.mostrarErroPesquisa = false;
    }

    this.mostrarCategorias = !this.mostrarCategorias;

    if (this.categoriaEscolhida != "") {
      this.showBtnServices =  true;
    } else {
      this.showBtnServices =  false;
      this.showBtnDistrict = false;
      this.showBtnMunicipio = false;

      this.servicoEscolhido = '';
      this.distritoEscolhida = '';
      this.municipioEscolhida = '';
      this.freguesiaEscolhida = '';
    }
    this.mostrarPesquisa = !this.mostrarPesquisa;
    this.mostrarErroPesquisa = false;

    this.listaServicos = [];

    if (this.listCategorySelected == '') {
      this.habilitarBusca = false;
      return;
    }

    this.tipos.forEach(e => {
      if (!e.head && (e.categoryCode.indexOf(this.listCategorySelected) !== -1 || this.listCategorySelected === 'TodosOption')) {

        this.listaServicos.push({
          code: e.code,
          name: e.nome
        })
      }
    });

    this.addTodosServicos();

    this.HabilitarBusca();
  }

  ServicoSelecionado(serviceCode: string, name: string) {
    this.busca.type = serviceCode;
    if (this.listServiceSelected == serviceCode || this.categoriaEscolhida == '') {
      this.listServiceSelected = ''
      this.servicoEscolhido = '';
    }
    else {
      this.listServiceSelected = serviceCode;
      this.servicoEscolhido = " : " + name;
    }

    this.mostrarServicos = !this.mostrarServicos;
    this.mostrarPesquisa = !this.mostrarPesquisa;

    this.HabilitarBusca();
  }

  DistritoSelecionado(districtCode: string, name: string) {
    this.busca.district = districtCode;
    if (this.listDistrictSelected == districtCode) {
      this.listDistrictSelected = '';
      this.distritoEscolhida = '';
    }
    else {
      this.listDistrictSelected = districtCode;
      this.distritoEscolhida = " : " + name;
    }

    if (this.distritoEscolhida != "") {
      this.showBtnDistrict =  true;
    } else {
      this.showBtnDistrict =  false;
      this.showBtnMunicipio = false;

      this.distritoEscolhida = '';
      this.municipioEscolhida = '';
      this.freguesiaEscolhida = '';
    }

    this.mostrarErroPesquisa = false;
    this.mensagemErroPesquisa = '';

    this.mostrarDistritos = !this.mostrarDistritos;
    this.mostrarPesquisa = !this.mostrarPesquisa;

    this.municipios = [];
    this.municipios = this.municipiosTodos.filter(
      area => area.code.substr(0, 2) === districtCode);
    this.addTodosMunicipio();

    this.HabilitarBusca();
  }

  MunicipioSelecionado(municipioCode: string, name: string) {
    this.busca.county = municipioCode;
    if (this.listMunicipioSelected == municipioCode) {
      this.listMunicipioSelected = '';
      this.municipioEscolhida = '';
    }
    else {
      this.listMunicipioSelected = municipioCode;
      this.municipioEscolhida = " : " + name;
    }

    if (this.municipioEscolhida != "") {
      this.showBtnMunicipio =  true;
    } else {
      this.showBtnMunicipio = false;

      this.municipioEscolhida = '';
      this.freguesiaEscolhida = '';
    }

    this.mostrarMunicipios = !this.mostrarMunicipios;
    this.mostrarPesquisa = !this.mostrarPesquisa;

    this.freguesias = [];
    this.freguesias = this.freguesiasTodos.filter(
      area => area.code.substr(0, 4) === municipioCode || municipioCode === 'TodosOption');
    this.addTodosFreguesias();

    this.HabilitarBusca();
  }

  FreguesiaSelecionada(freguesiaCode: string, name: string) {
    this.busca.parish = freguesiaCode;
    if (this.listFreguesiaSelected == freguesiaCode) {
      this.listFreguesiaSelected = '';
      this.freguesiaEscolhida = '';
    }
    else {
      this.listFreguesiaSelected = freguesiaCode;
      this.freguesiaEscolhida = " : " + name;
    }

    this.mostrarFreguesias = !this.mostrarFreguesias;
    this.mostrarPesquisa = !this.mostrarPesquisa;

    this.HabilitarBusca();
  }

  /*public searchPoI(searchData: SearchForData) {
    this.exploreSearchForm = searchData;
    if (!searchData.canUseGeolocation) {
      this.mapa.ListarPontos(
        0,
        0,
        searchData.service ? searchData.service.code : searchData.lifeEvent.code,
        searchData.search,
        searchData.district.code,
        searchData.municipality ? searchData.municipality.code : '',
        searchData.parish ? searchData.parish.code : '',
        0,
        2,
        this.tipoFiltroEscolhido,
        true
      );
      return;
    }

    this.mapa.ListarPontos(
      this.mapa.userPosition.latitude, 
      this.mapa.userPosition.longitude,
      searchData.service ? searchData.service.code : searchData.lifeEvent.code,
      searchData.search,
      '',
      '',
      '',
      this.mapa.radius,
      1,
      this.tipoFiltroEscolhido,
      true
    );

    this.mapa.centerMap(this.mapa.userPosition.latitude, this.mapa.userPosition.longitude);
  }*/

  /*BuscarPontos() {
    this.busca.type = this.busca.type === 'TodosOption' ? '' : this.busca.type;
    this.busca.county = this.busca.county === 'TodosOption' ? '' : this.busca.county;
    this.busca.parish = this.busca.parish === 'TodosOption' ? '' : this.busca.parish;

    if ((this.searchControl.value || '').trim().length > 3 && this.filterUseGeoLocation) {
      //Busca para o mapa de cidadao]
      window.open('/#/SearchText?text=' + this.searchControl.value, '_self', '');

      setTimeout(function () {
        window.location.reload();
      }, 100);

      this.mostrarErroPesquisa = false;
      this.mensagemErroPesquisa = '';
      return;
    }

    if (this.listCategorySelected == '') {
      this.mostrarErroPesquisa = true;
      this.mensagemErroPesquisa = 'Selecione um evento de vida.';
      return;
    }

    if (!this.filterUseGeoLocation) {
      if (this.distritoEscolhida == "") {
        this.mostrarErroPesquisa = true;
        this.mensagemErroPesquisa = 'Selecione um distrito.';
        this.habilitarBusca = false;
      } else
        this.mapa.ListarPontos(0, 0, this.busca.type, this.busca.pointName, this.busca.district, this.busca.county, this.busca.parish, 0, 2, parseInt(this.tipoFiltroEscolhido.toString()), true);
    } else {
      this.mapa.ListarPontos(this.mapa.userPosition.latitude, this.mapa.userPosition.longitude, this.busca.type, '', '', '', '', this.mapa.radius, 1, parseInt(this.tipoFiltroEscolhido.toString()), true);
      this.mapa.centerMap(this.mapa.userPosition.latitude, this.mapa.userPosition.longitude);
    }
  }*/

  public addTodosTipos(): void {
    this.tipos = [{ nome: this.todosOption, code: '', categoryCode: 'TodosOption', id: null, head: true }, ...this.tipos];
  }

  public addTodosServicos(): void {
    this.listaServicos = [{ name: this.todosOption, code: 'TodosOption' }, ...this.listaServicos];
  }

  public addTodosMunicipio(): void {
    this.municipios = [{ nome: this.todosOption, code: 'TodosOption' }, ...this.municipios];
  }

  public addTodosFreguesias(): void {
    this.freguesias = [{ nome: this.todosOption, code: 'TodosOption' }, ...this.freguesias];
  }

  


  abrirAgendamento(url: string, idEntidade: number, idPOC: number, divisionCode: string) {
    window.open(url, '_blank');

    //Registra número de clicks
    var click: Click = {
      localId: this.idPonto,
      entityId: idEntidade,
      pocId: idPOC,
      divisionCode: divisionCode,
      type: 'Web'
    };

    this.registerScheduleClick(click);
  }

  registerScheduleClick(click: Click) {
    this.sidebarService.registerScheduleClick(click)
      .subscribe(
        res => this.registerScheduleClick(res));
  }

  /*getTopDigitalServices() {
    this.sidebarService.getTopDigitalServices(++this.topDigitalServicesPage).subscribe(res => this.addTopDigitalServices(res));
    this.sidebarService.getTopDigitalServices(this.topDigitalServicesPage + 1).subscribe(res => {
      const response: any = res;
      if (response) {
        if (response.digitalServices){
          this.showLessTopDigitalServices = response.digitalServices.length === 0;
        }
        else {
          this.showLessTopDigitalServices = true;
        }
      }
    });
  }

  addTopDigitalServices(data: DigitalServices[]) {

    if (data["digitalServices"] === null || data["digitalServices"].length <= 0)
      return;

    this.digitalServices.push(...data["digitalServices"]);
  }

  clearTopDigitalServices() {
    this.sidebarService.getTopDigitalServices(this.topDigitalServicesPage = 1).subscribe(res => this.digitalServices = res["digitalServices"]);
    this.showLessTopDigitalServices = false;
  }

  seeLessTopDigitalServices() {
    this.clearTopDigitalServices();
  }*/

  Loading(loading: boolean) {
    this.divLoader.hidden = !loading;
  }

  GoService(url: string) {
    window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  InicializarFormErrorSMS() {
    this.smsEnviar = "";

    this.phoneFormControl = new FormControl('', [
      Validators.required,
      Validators.pattern(/([9]{1})\)?([1-3|6]{1})\)?([0-9]{7}){1,10}$/)
    ]);
  }

  InicializarFormErrorEmail() {
    this.emailEnviar = "";

    this.emailFormControl = new FormControl('', [
      Validators.required,
      Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    ]);

  }

  HabilitarBusca(): Boolean {
    if (this.categoriaEscolhida == "" && this.servicoEscolhido == ""
      && !this.filterUseGeoLocation) {
      return this.habilitarBusca = false;
    }

    if (this.categoriaEscolhida != "" && this.servicoEscolhido != "" && this.distritoEscolhida == "") {
      return this.habilitarBusca = false;
    }

    if (this.distritoEscolhida == "" || this.municipioEscolhida == "") {
      this.habilitarBusca = false;
    }
    else
      this.habilitarBusca = true;
  }

  MostrarCategorias() {
    this.mostrarCategorias = !this.mostrarCategorias ? true : false
    this.mostrarServicos = false;
    this.mostrarPesquisa = !this.mostrarPesquisa ? true : false;
  }

  MostrarServicos() {
    if (this.categoriaEscolhida != "") {
      this.mostrarServicos = !this.mostrarServicos ? true : false
      this.mostrarCategorias = false;
      this.mostrarPesquisa = !this.mostrarPesquisa ? true : false;
      this.mostrarErroPesquisa = false;
      this.mensagemErroPesquisa = '';
    }
    else {
      this.mostrarErroPesquisa = true;
      this.mensagemErroPesquisa = 'Selecione um evento de vida.';
      this.listaServicos = [];
      this.servicoEscolhido = '';
      this.listServiceSelected = '';
    }
  }

  MostrarDistritos() {
    this.mostrarServicos = false;
    this.mostrarCategorias = false;
    this.mostrarPesquisa = !this.mostrarPesquisa ? true : false;
    this.mostrarDistritos = !this.mostrarDistritos ? true : false

    if (this.mostrarDistritos) {
      this.mostrarMunicipios = false;
      this.mostrarFreguesias = false;
    }
  }

  MostrarMunicipios() {

    if (this.distritoEscolhida == '') {
      this.mostrarErroPesquisa = true;
      this.mensagemErroPesquisa = 'Selecione um distrito';

      return;
    }

    this.mostrarErroPesquisa = false;
    this.mensagemErroPesquisa = '';

    this.mostrarServicos = false;
    this.mostrarCategorias = false;
    this.mostrarDistritos = false;
    this.mostrarPesquisa = !this.mostrarPesquisa ? true : false;

    if (this.distritoEscolhida != '') {
      this.mostrarMunicipios = !this.mostrarMunicipios ? true : false

      if (this.mostrarMunicipios) {
        this.mostrarDistritos = false;
        this.mostrarFreguesias = false;
      }
    }
  }

  MostrarFreguesias() {
    if (this.distritoEscolhida == '' || this.municipioEscolhida == '') {
      this.mostrarErroPesquisa = true;
      this.mensagemErroPesquisa = 'Selecione um distrito e um município';

      return;
    }

    this.mostrarErroPesquisa = false;
    this.mensagemErroPesquisa = '';

    this.mostrarServicos = false;
    this.mostrarCategorias = false;
    this.mostrarDistritos = false;
    this.mostrarMunicipios = false;
    this.mostrarPesquisa = !this.mostrarPesquisa ? true : false;

    if (this.municipioEscolhida != '') {
      this.mostrarFreguesias = !this.mostrarFreguesias ? true : false

      if (this.mostrarFreguesias) {
        this.mostrarMunicipios = false;
        this.mostrarDistritos = false;
      }
    }
  }

  LimparBusca() {
    this.busca.type = '0';
    this.busca.district = '';
    this.busca.county = '';
    this.busca.parish = '';

    this.categoriaEscolhida = "";
    this.servicoEscolhido = "";
    this.distritoEscolhida = "";
    this.municipioEscolhida = "";
    this.freguesiaEscolhida = "";

    this.mostrarCategorias = false;
    this.mostrarServicos = false;
    this.mostrarDistritos = false;
    this.mostrarMunicipios = false;
    this.mostrarFreguesias = false;

    this.listCategorySelected = "";
    this.listServiceSelected = "";
    this.listDistrictSelected = "";
    this.listMunicipioSelected = "";
    this.listFreguesiaSelected = "";

    //this.mapa.nenhumPontoEncontrado = false;

    this.filterUseGeoLocation = false;

    this.mostrarErroPesquisa = false;
    this.mensagemErroPesquisa = '';
    this.mostrarPesquisa = false;

    this.HabilitarBusca();
  }


  //Mostrar lista de serviços do ponto escolhido
  /**@deprecated */
  MostrarSenhasEntidades() {
    this.showListPointsOfCare = true;
    this.mostrarEntidades = false;
    this.mostrarInfos = false;
    this.showListDivision = false;
    this.mostrarDirecoes = false;
  }

  /**Get List of PointOfCare by Location. Used in btnmenuSideBar click event sidebarright.component.html */
  getPointsOfCare(){
    this.showListPointsOfCare = true;
    this.mostrarEntidades = false;
    this.mostrarInfos = false;
    this.showListDivision = false;
    this.mostrarDirecoes = false;

    if (this.selectedPoint.organizationId){
      this.sidebarService.getPointsOfCareByLocation(this.selectedPoint.locationId, this.getLanguage()).subscribe(res => this.setPointsOfCare(res));
    }
    else
    {
      this.pointsOfCare = [{
                              id: this.selectedPoint.pointOfCareId, 
                              title: this.selectedPoint.title, 
                              logoPath: this.selectedPoint.imageUrl, 
                              sigaInstituteIdentifier: this.selectedPoint.sigaInstituteIdentifier, 
                              sigaEntityIdentifier: this.selectedPoint.sigaEntityIdentifier, 
                              sigaLocalIdentifier: this.selectedPoint.sigaLocalIdentifier,
                              clicked: false
                          }];
    }
  }

  setPointsOfCare(response: ResponseData<PointOfCareDto[]>){
    if (response.statusCode == 200){
      this.pointsOfCare = response.data;
      this.showAlertPointOfCareNotFound = false;
    }
    else {
      this.pointsOfCare = [];
      this.showAlertPointOfCareNotFound = true;
    }
  }

  /**Get list divisions of PointOfCare 
  * @param pointOfCareId PointOfCare Identifier
  */
  getPointOfCareDivisions(pointOfCareId: number){
    var point = this.pointsOfCare.find(p => p.id == pointOfCareId);

    if (!point.clicked){
      this.pointsOfCare.forEach(element => element.clicked = false);

      point.clicked = true;
      this.imgDivisaoLoading = 1;
      this.sidebarService.getPointOfCareDivisions(pointOfCareId, this.getLanguage()).subscribe(res => this.setPointOfCareDivisions(res));
    }
    else{
      point.clicked = false;
      this.showAlertDivisionNotFound = false;
      this.pointOfCareDivisions = [];
    }
  }

  setPointOfCareDivisions(response: ResponseData<PointOfCareDivisionDto[]>){
    if (response.statusCode == 200){
      this.pointOfCareDivisions = response.data;
      this.showAlertDivisionNotFound = false;
      this.imgDivisaoLoading = 0;
      this.showListDivision = true;
      this.showTicketArea = false;
    }
    else{
      this.showAlertDivisionNotFound = true;
      this.pointOfCareDivisions = [];
      this.imgDivisaoLoading = 0;
      this.showListDivision = false;
      this.showTicketArea = false;
    }
  }

  /** Get and Show SIGA Information detail about ticket Point of Care Division. Used on event click button sidebarright.component.html
   * @param sigaInstituteIdentifier Institute identifier on SIGA system
   * @param sigaEntityIdentifier Entity identifier on SIGA system
   * @param sigaLocalIdentifier Local Identifier on SIGA system
  */
  getDivisionsTicketInfoDetail(division: PointOfCareDivisionDto, sigaInstituteIdentifier: number, sigaEntityIdentifier: number, sigaLocalIdentifier: number){
    
    if (!division.clicked){
      this.pointOfCareDivisions.forEach(element => element.clicked = false);
      division.clicked = true;

      if (this.canGetNewDivisionsTicketInfoDetail(sigaInstituteIdentifier, sigaEntityIdentifier, sigaLocalIdentifier)){
        this.imgTicketLoading = true;
        this.sidebarService.getDivisionsTickestInfoDetail(sigaInstituteIdentifier, sigaEntityIdentifier, sigaLocalIdentifier).subscribe(res => this.setDivisionsTicketInfoDetail(res, division.sigaServiceIdentifier));
      }
      else if (this.selectedPointOfCareDivisionsTicket?.length > 0){
        this.showTicketArea = true;
        this.showAlertTicketInformationNotFound = false;
        this.selectedDivisionTicketInfo = this.selectedPointOfCareDivisionsTicket.find(d => d.sigaServiceIdentifier == division.sigaServiceIdentifier);
      }
      else{
        this.showAlertTicketInformationNotFound = true;
        this.showTicketArea = false;
        this.selectedDivisionTicketInfo = null;
      }
    }
    else{
      division.clicked = false; 
      this.showTicketArea = false;
      this.showAlertTicketInformationNotFound = false;
      this.selectedDivisionTicketInfo = null;
      this.selectedPointOfCareDivisionsTicket = [];
    }
  }

  setDivisionsTicketInfoDetail(response: ResponseData<DivisionServiceInfoDetailDto[]>, sigaServiceIdentifier: number){
    this.imgTicketLoading = false; 
    if (response.statusCode == 200){
      this.showTicketArea = true;
      this.showAlertTicketInformationNotFound = false;
      this.selectedPointOfCareDivisionsTicket = response.data;
      this.selectedDivisionTicketInfo = this.selectedPointOfCareDivisionsTicket.find(d => d.sigaServiceIdentifier == sigaServiceIdentifier);            
    }
    else{
      this.showAlertTicketInformationNotFound = true;
      this.showTicketArea = false;
      this.selectedDivisionTicketInfo = null;
    }
  }

  /**Check if must get New Divisions Service Ticket Info Detail from SIGA system; otherwise selectedPointOfCareDivisionsTicket already has information 
   * if null is not possible retrieve information on SIGA
  */
  canGetNewDivisionsTicketInfoDetail(sigaInstituteIdentifier: number, sigaEntityIdentifier: number, sigaLocalIdentifier: number): boolean{
      if (sigaInstituteIdentifier && sigaEntityIdentifier && sigaLocalIdentifier)
          if (this.selectedPointOfCareDivisionsTicket?.length > 0 && 
              this.selectedPointOfCareDivisionsTicket[0].sigaInstituteIdentifier == sigaInstituteIdentifier &&
              this.selectedPointOfCareDivisionsTicket[0].sigaEntityIdentifier == sigaEntityIdentifier &&
              this.selectedPointOfCareDivisionsTicket[0].sigaLocalIdentifier == sigaLocalIdentifier){
            return false;
          }
          else{
            return true;
          }
      else{
        this.selectedPointOfCareDivisionsTicket = [];
        return false;
      }
  }

  /**
     * Get IP of User device used to acess site CitizenMap
     */
  async getUserDeviceIp(){
    var result = await this.sidebarService.getUserDeviceIp().toPromise();
    this.userDeviceIp = result["ip"];
  }

  openModalStatisticsTicket(sigaServiceIdentifier: number, divisionCode: string){
    this.waitingTicketStatistics = true;
    this.sidebarService.getSigaTicketStatistics(sigaServiceIdentifier, divisionCode).subscribe(response => {

      if (response.statusCode == 200)
      {
        this.waitingTicketStatistics = false;
        const statistics = response.data;

        const dialogRef = this.dialog.open(ChartComponent, {
          width: '500px',
          height: '450px',
          data: statistics
          
        });

        dialogRef.afterClosed().subscribe(result => {

        });
      }
      else{
        this.waitingTicketStatistics = false;
      }

    });
  }

  /**
   * Retrieve service next ticket and show it. Used on button click GetTicket on sidebarright.component.html
   * @param pointOfCareName 
   * @param divisionName 
   * @param entityId 
   * @param pocId 
   * @param divisionCode 
   */
  async openModalTicket(division: PointOfCareDivisionDto, pointOfCareName: string, sigaInstituteIdentifier: number, sigaEntityIdentifier: number, 
                  sigaLocalIdentifier: number) {
    await this.getUserDeviceIp();

    var organizationName = this.selectedPoint.organizationId ? this.selectedPoint.title : "";
    var divisionName = division.code+"-"+division.title;

    var ticket = ticketDto.newTicket(organizationName, pointOfCareName, divisionName, 
                                           sigaInstituteIdentifier, sigaEntityIdentifier, sigaLocalIdentifier, division.sigaServiceIdentifier,
                                           this.userDeviceIp, this.getLanguage(), division.divisionId);

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      height: '350px',
      data: {
        ticket
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  /**
   * @deprecated
   */
  public prepareDataSourceDivisoes(): void {
    this.divisoes.forEach(divisao => {
      divisao.serviceDemandsTable = [];
      divisao.serviceDemandsTable.data = [];
      divisao.serviceDemandsTable.headers = ['Hora', ...divisao.serviceDemands.daysOfWeek];
      divisao.serviceDemandsTable.days = [...divisao.serviceDemands.daysOfWeek];
      const actualDay = new Date().getDay();
      //this.selectedDay = divisao.serviceDemandsTable.days[actualDay -1];

      for (let row = 0; row < divisao.serviceDemands.hours.length; row++) {
        let dataObj: any = {};
        dataObj.Hora = divisao.serviceDemands.hours[row];

        for (let col = 0; col < divisao.serviceDemands.daysOfWeek.length; col++) {
          dataObj[divisao.serviceDemands.daysOfWeek[col]] = divisao.serviceDemands.values[col].tickets[row];
        }

        divisao.serviceDemandsTable.data.push(dataObj);
      }
    });
  }

  /**@deprecated Não é mais utilizado */
  public showDivisions(element: any, divisionaCode: string) {
    console.log("showDivisions clicked");
    console.log("this.showTicketArea" +this.showTicketArea);
    if (!this.divisionCodeClicked || this.divisionCodeClicked == divisionaCode) {
      this.showTicketArea = !this.showTicketArea;
    }
    this.divisionCodeClicked = element.currentTarget.id;

    let buttonFocus = document.getElementById(divisionaCode);
    buttonFocus.focus()
  }

  public showAbout() {

    this.mostrarSobre = !this.mostrarSobre ? true : false
    this.mostrarBusca = !this.mostrarBusca ? true : false

    //if (this.totalLocaisServicos.locais == 0 || this.totalLocaisServicos.servicos == 0)
      //this.service.BuscarTotalLocaisServicos().subscribe(res => this.totalLocaisServicos = res);

    this.tutorialService.isToNotify().subscribe(res => this.NotificarTutorial(res));
  }

  NotificarTutorial(res: Notification) {
    this.notificarTutorial = res;
  }

  SetSenhasServicos(element: any, senhasServicos: any, nomeEntidade: string) {
    var lang = this.mapa.GetLanguage();

    var msg = "Não foi possivel obter informações de senhas para esta entidade";

    if (lang.toLowerCase() == "en")
      msg = "It was impossible to get information for this entity tickets";

    if (lang.toLowerCase() == "es")
      msg = "No fue posible obtener informaciones de billetes para esta entidad";

    if (typeof senhasServicos === "string") {
      this.openDialog(msg);
      this.Loading(false);
      this.imgDivisaoLoading = 0;
      this.idSenhaNonHistoricClicked = 0;
      return;
    }

    if (senhasServicos.Message != null && senhasServicos.Message != "") {
      this.openDialog(msg);
      this.Loading(false);
      this.imgDivisaoLoading = 0;
      this.idSenhaNonHistoricClicked = 0;
      return;
    }

    this.Loading(false);
    this.imgDivisaoLoading = 0;

    this.entidadeEscolhida.nome = nomeEntidade;

    this.divisoes = senhasServicos.divisoes;
    this.prepareDataSourceDivisoes();

    this.entidadeEscolhida.servicos = senhasServicos.servicos;

    this.entidadeEscolhida.divisoes = senhasServicos.divisoes;
    /*if (element != null) {
      this.delay(500).then(any => {
        element.srcElement.scrollIntoView({ behavior: "smooth", block: "start" });
      });
    }*/

  }

  openUrl(url: any) {
    window.open(url, '_system');
  }

/**@deprecated */
  /*BuscarIpPublico() {
    this.service.BuscarIpPublico().subscribe(result => this.publicIp = result['ip']);
  }*/

  CompareChart(idPOC: number, divisionCode: string) {
    window.open('http://localhost:8080/?p=' + idPOC.toString() + '&d=' + divisionCode,
      'C-Sharpcorner',
      'toolbar=no,scrollbars=no,resizable=no,top=100,left=500,width=520,height=300');
  }

  CompareDistancePoc(idPOC: number, divisionCode: string) {
    window.open('http://localhost:8080/?l=' + this.idPonto + '&p=' + idPOC.toString() + '&d=' + divisionCode,
      'C-Sharpcorner',
      'toolbar=no,scrollbars=no,resizable=no,top=100,left=500,width=520,height=150');
  }

  //Nova versão - Não tira senha vinculado a um serviço, apenas ao poc
  /*RetirarSenha(idPOC: number, divisionCode: string, EmailSms: string, divisionName: string) {
    this.Loading(true);
    this.BuscarIpPublico();

    this.emailSMS = EmailSms;


    if ((this.tipoEnvio == 1 && this.emailFormControl.status == "VALID") || (this.tipoEnvio == 2 && this.phoneFormControl.status == "VALID")) {
      if (this.emailSMS != undefined && this.emailSMS != '') {
        this.Loading(false);
        this.sendMessage = "";

        this.gaService.event.emit({
          category: 'Lista de senhas em entidade',
          action: this.tipoEnvio == 1 ? 'Click - Email' : 'Click - SMS',
          label: this.titulo + " - " + this.entidadeEscolhida.nome + " - " + divisionName
        });

        //New way to send email and sms
        this.SenhaHistoricoChange(idPOC, divisionCode);
      }
    }

    this.Loading(false);
  }*/

  /*BuscarSenhasUser() {
    this.Loading(true);
    this.service.BuscarSenhasUser(this.publicIp, this.language).subscribe(result =>
      this.SenhaHistorico(result)
    );
  }*/

  /*SenhaHistoricoChange(idPOC, divisionCode) {
    this.InicializarFormErrorSMS();
    this.InicializarFormErrorEmail();

    if (this.tipoEnvio == 1)
      this.EnviarEmailChange(idPOC, divisionCode, this.emailSMS);
    else
      this.EnviarSMSChange(idPOC, divisionCode, this.emailSMS);
  }*/

  /*SenhaHistorico(result: any) {
    this.senhasHistorico = result

    this.InicializarFormErrorSMS();
    this.InicializarFormErrorEmail();


    if (this.senhasHistorico[0] == null) {
      this.openDialog("Falha ao tirar senha");
      this.Loading(false);
      return;
    }

    if (this.senhasHistorico[0].mensagemErro != null && this.senhasHistorico[0].mensagemErro != "") {
      this.openDialog(this.senhasHistorico[0].mensagemErro);
      this.Loading(false);
      return;
    }

    if (this.tipoEnvio == 1)
      this.EnviarEmail(this.senhasHistorico[0], this.emailSMS);
    else
      this.EnviarSMS(this.senhasHistorico[0], this.emailSMS);


  }*/

  VoltarSenha() {

    if (this.origemCaminhoEntidadeSenha == 1) {
      this.mostrarEntidade = false;
      this.mostrarLocal = true;
      this.mostrarBusca = false;
      this.mostrarSenha = false;
      let abc = document.getElementById("loacalpertodesiaposclick");
      abc.focus();
    }
    else if (this.origemCaminhoEntidadeSenha == 2) {
      this.mostrarEntidade = true;
      this.mostrarLocal = false;
      this.mostrarBusca = false;
      this.mostrarSenha = false;
    }
    else {
      this.mapa.CloseSidebar();
    }


  }

  /*AbrirUser() {
    this.BuscarSenhasUser();

    this.origemCaminhoEntidadeSenha = 3;

    this.sendMessage = "";
    this.mostrarEntidade = false;
    this.mostrarLocal = false;
    this.mostrarBusca = false;
    this.mostrarSenha = true;
  }*/

  MostrarEntidadeInfos() {
    this.mostrarEntidadeInfo = !this.mostrarEntidadeInfo ? true : false

    if (this.mostrarEntidadeInfo)
      this.mostrarEntidadeServicos = false;
  }

  MostrarEntidadeServicos() {
    this.mostrarEntidadeServicos = !this.mostrarEntidadeServicos ? true : false

    if (this.mostrarEntidadeServicos)
      this.mostrarEntidadeInfo = false;
  }

  MostrarEntidadeDivisoes() {
    this.mostrarEntidadeDivisoes = !this.mostrarEntidadeDivisoes ? true : false
  }

  ReduzirTexto(text: string): string {
    return text.substring(0, 50);
  }

  MostrarInformacoesSenha() {
    this.mostrarEntidade = false;
    this.mostrarLocal = false;
    this.mostrarBusca = false;
    this.mostrarSenha = true;
    this.mostrarMinhasSenha = true;
  }

  MostrarInformacoesSenhaError() {
    this.mostrarEntidade = false;
    this.mostrarLocal = true;
    this.mostrarBusca = false;
    this.mostrarSenha = false;
    this.showListDivision = false;
    let abc = document.getElementById("loacalpertodesiaposclick");
    abc.focus();
  }

  //Formatar data e hora atual
  GetCurrentFormatDateHour() {

    var day: string = new Date().getDate().toString();

    if (day.length == 1)
      day = '0' + day;

    var month: string = (new Date().getMonth() + 1).toString();

    if (month.length == 1)
      month = '0' + month;

    var year: string = new Date().getFullYear().toString();

    var hour: string = new Date().getHours().toString();

    var minute: string = new Date().getMinutes().toString();

    var second: string = new Date().getUTCSeconds().toString();

    return day + '/' + month + '/' + year + ' ' + hour + ':' + minute + ':' + second;
  }


  //Formatar data atual
  GetCurrentFormatDate() {

    var day: string = new Date().getDate().toString();

    if (day.length == 1)
      day = '0' + day;

    var month: string = (new Date().getMonth() + 1).toString();

    if (month.length == 1)
      month = '0' + month;

    var year: string = new Date().getFullYear().toString();

    return day + '/' + month + '/' + year;
  }


  MostrarInfos() {
    this.mostrarInfos = true;
    this.showListPointsOfCare = false;
    this.showAlertPointOfCareNotFound = false;
    this.mostrarEntidades = false;
    this.mostrarDirecoes = false;
  }

  //Mostrar lista de entidades do ponto escolhido
  MostrarEntidades() {
    this.mostrarEntidades = true;
    this.showListPointsOfCare = false;
    this.mostrarInfos = false;
  }

  Direcao() {
    this.mostrarDirecoes = true;
    this.mostrarInfos = false;
    this.showListPointsOfCare = false;
    this.mostrarEntidades = false;
  }

  LinkDirecao() {
    const address = this.morada !== '' ? this.morada : `${this.latPonto},${this.lngPonto}`;
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${address}`);
  }

  /**Utilizada no botão Voltar, e no filtro que aparece quando a sidebar esta fechada */
  AbrirBusca() {
    this.mostrarEntidade = false;
    this.mostrarLocal = false;
    this.mostrarBusca = this.hasPermission('search');
    this.mostrarSenha = false;
    this.mapa.showListMapPoints = this.mostrarBusca ? false : true;
    this.mostrarSobre = false
  }

  isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function () {
      return (this.isMobile.Android() || this.isMobile.BlackBerry() || this.isMobile.iOS() || this.isMobile.Opera() || this.isMobile.Windows());
    }
  };



  MaisInfoService(serviceId: number) {
    window.open(
      '',
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  InfoSenhaDetail(senhaId: number) {
    if (senhaId != this.idSenhaClickedDeatil)
      this.mostrarDetalheSenha = true;
    else
      this.mostrarDetalheSenha = !this.mostrarDetalheSenha ? true : false;

    this.idSenhaClickedDeatil = senhaId;
  }


  MostrarEnviarEmail(divisionCode: string, tipo: number) {
    if (divisionCode != this.idServicoClickedDeatil)
      this.mostrarInserirEmail = true;
    else
      this.mostrarInserirEmail = !this.mostrarInserirEmail ? true : false;

    if (this.mostrarInserirEmail) {
      this.mostrarInserirTelemovel = false;
      this.delay(500).then(any => {
        if (tipo == 1)
          document.getElementById("emailEnviarSenhas").focus();
        else
          document.getElementById("emailEnviarEntidade").focus();
      });

    }

    this.idServicoClickedDeatil = divisionCode;
  }

  MostrarEnviarSMS(divisionCode: string, tipo: number) {

    if (divisionCode != this.idServicoClickedDeatil)
      this.mostrarInserirTelemovel = true;
    else
      this.mostrarInserirTelemovel = !this.mostrarInserirTelemovel ? true : false;

    if (this.mostrarInserirTelemovel) {
      this.mostrarInserirEmail = false;
      this.delay(500).then(any => {
        if (tipo == 1)
          document.getElementById("smsEnviarSenhas").focus();
        else
          document.getElementById("smsEnviarEntidade").focus();
      });

    }


    this.idServicoClickedDeatil = divisionCode;
  }

  InfoService(serviceId: number, nome: string) {
    this.gaService.event.emit({
      category: 'Lista de serviços',
      action: 'Click',
      label: this.titulo + " - " + this.entidadeEscolhida.nome + " - " + nome,
      value: serviceId
    });

    if (serviceId != this.idServiceClicked)
      this.mostrarEntidadeServicosDetail = true;
    else
      this.mostrarEntidadeServicosDetail = !this.mostrarEntidadeServicosDetail ? true : false;

    this.idServiceClicked = serviceId;
  }

  InfoSenha(senhaId: number) {

    if (senhaId != this.idSenhaClicked)
      this.mostrarSenhaDetail = true;
    else
      this.mostrarSenhaDetail = !this.mostrarSenhaDetail ? true : false;

    this.idSenhaClicked = senhaId;
  }


  //Carrega as informacoes completas da Entidade
  /*CarregarEntidade(idEntidade: number, nome: string) {
    this.gaService.event.emit({
      category: 'Lista de entidades',
      action: 'Click',
      label: this.titulo + " - " + nome,
      value: idEntidade
    });

    for (let i of this.entidade.listaEntidadesLocal) {
      if (i.idEntidade == idEntidade)
        this.entidadeEscolhida = i;
    }

    this.Loading(true);

    this.service.BuscarEntidadeInfos(this.idPonto, idEntidade, this.mapa.GetLanguage()).subscribe(result => this.SetSenhasServicos(null, result, nome));

    this.mostrarEntidade = true;
    this.mostrarLocal = false;
    this.mostrarBusca = false;
    this.mostrarSenha = false;
  }*/

  MouseEnterRow(pontoCidadao: any) {
    this.mapa.highLightIconOnMap(pontoCidadao);
  }

  MouseLeaveRow() {
    this.mapa.setIconMapPoints();
  }

  /**
   * Utilizar OpenListMapPointSidebar
   * @deprecated
   */
  /*VoltarLocal() {

    if (!this.mapa.mobile) {
      this.showListMapPoints = true;
      this.mostrarSobre = false
      this.mostrarEntidade = false;
      this.mostrarBusca = false;
      this.mostrarSenha = false;
      this.mostrarLocal = false;
      this.mostrarInfos = false;
      this.mostrarEntidades = false;
      this.mostrarSenhasEntidades = false;
      this.umLocalMesmaLat = false;
    }
    else {
      this.mapa.sideNavRight.toggle();
    }
  }*/

  /**Get Map Point Info Detail. Used on event informacoes of component app-nearby-places, click button in list MapPointDto */
  public getMapPointInfoDetail(point: MapPointDto) {

    this.selectedPoint = point;

    if (point.organizationId){
      this.sidebarService.getOrganizationInfoDetail(point.organizationId, point.locationId, this.getLanguage()).subscribe(res => this.setInfoDetail(res));
    }
    else{
      this.sidebarService.getPointsOfCareInfoDetail(point.pointOfCareId, this.getLanguage()).subscribe(res => this.setInfoDetail(res));
    }

  }

  /**Show Map Point Info Detail */
  private setInfoDetail(response: ResponseData<MapPointInfoDto>){
    if (response.statusCode == 200){
      this.titulo = this.selectedPoint.title;
      this.informacao = response.data.description;
      this.imgPath = this.selectedPoint.imageUrl;
      this.image = this.selectedPoint.imageUrl;
      this.site = response.data.site.trim();
      this.telefone = response.data.phoneNumber.split(",").filter(_ => _).map(_ => _.trim());
      this.morada = response.data.address;
      this.emails = (response.data.emails + '').split(",").filter(_ => _).map(_ => _.trim());
      //this.horario = points.schedule?.split("//").filter(_ => _).map(_ => _.trim());

      this.latPonto = this.selectedPoint.latitude;
      this.lngPonto = this.selectedPoint.longitude;

      this.MostrarHorario();
      this.MostrarTelefone();

      this.mapa.showListMapPoints = false;
      this.mostrarEntidade = false;
      this.mostrarBusca = false;
      this.mostrarSenha = false;
      this.mostrarLocal = true;
      this.mostrarInfos = true;
      this.mostrarEntidades = false;
      this.showListPointsOfCare = false;
      this.showAlertPointOfCareNotFound = false;
      this.showAlertDivisionNotFound = false;
      this.showAlertTicketInformationNotFound = false;
      this.pointsOfCare = [];
      this.pointOfCareDivisions = [];

      this.mapa.centerMap(this.selectedPoint.latitude, this.selectedPoint.longitude);

      let abc = document.getElementById("btnmenuSideBar");
      abc.focus();
    }
  }

  //Procura as informações do ponto seleccionado
  /*public Informacoes(filtro: Boolean, id: number, latAtual: number, lngAtual: number, listaLocais: boolean) {

    var points = this.mapa.citizenPoints.find(s => s.id === id);

    if (points.id === null || points === undefined)
      return;

    this.titulo = points.title;
    this.informacao = points.description;
    this.imgPath = points.imageUrl;
    this.image = points.imageUrl;
    this.site = points.site.trim();
    this.telefone = points.phoneNumber.split(",").filter(_ => _).map(_ => _.trim());
    this.morada = points.address;
    this.emails = (points.email + '').split(",").filter(_ => _).map(_ => _.trim());
    this.horario = points.schedule?.split("//").filter(_ => _).map(_ => _.trim());

    this.drivingTime = points.drivingTime;
    this.walkingTime = points.walkingTime;

    if (points.restrictions != null)
      this.restricoes = points.restrictions.split("//").filter(_ => _);

    this.restricaoHighlight = [];

    this.MostrarHorario();
    this.MostrarTelefone();

    //restrictions treatment
    if (points.restrictions != null) {
      if (points.restrictions.indexOf('[highlight]') !== -1) {
        for (let i of this.restricoes) {
          if (i.indexOf('[highlight]') !== -1)
            this.restricaoHighlight.push(i.replace('[highlight]', ''));
        }
      }
    }

    this.idPonto = points.id;

    this.latPonto = points.latitude;
    this.lngPonto = points.longitude;

    this.entidadesSenhas.filter(obj => obj.qtdePocs > 0)

    let pontosMesmaGeoLocation = this.mapa.citizenPoints.filter(obj => obj.latitude == points.latitude && obj.longitude == points.longitude);

    if (pontosMesmaGeoLocation.length == 1) {
      listaLocais = false;
      this.umLocalMesmaLat = false;
    }
    else {
      this.umLocalMesmaLat = true;
    }

    if (latAtual != 0) {
      this.latAtual = latAtual;
      this.lngAtual = lngAtual;
    }

    if (listaLocais) {

      if (this.mapa.citizenPoints != null && document.getElementById(this.mapa.citizenPoints[0].id.toString()) != null)
        this.showListMapPoints = true;

      this.mostrarEntidade = false;
      this.mostrarBusca = false;
      this.mostrarSenha = false;
      this.mostrarLocal = false;
      this.mostrarInfos = false;
      this.mostrarEntidades = false;
      this.mostrarSenhasEntidades = false;

      this.ToggleAbrirBarraLateral();
    }
    else {
      this.showListMapPoints = false;
      this.mostrarEntidade = false;
      this.mostrarBusca = false;
      this.mostrarSenha = false;
      this.mostrarLocal = true;
      this.mostrarInfos = true;
      this.mostrarEntidades = false;
      this.mostrarSenhasEntidades = false;

      //this.mapa.latMap = points.latitude;
      //this.mapa.lngMap = points.longitude;
      this.mapa.centerMap(points.latitude, points.longitude);

      this.Loading(true);
      this.service.BuscarEntidades(this.idPonto, this.mapa.GetLanguage(), this.mapa.textSearch).subscribe(result => this.InformacoesPontoSelecionado(result), _ => this.Loading(false));
      let abc = document.getElementById("btnmenuSideBar");
      abc.focus();
    }

  }*/

  InformacoesPontoSelecionado(entidade: any) {

    this.qtdeBalcaoComTextoProcurado = 0;

    this.entidade = entidade;

    this.entidadesSenhas = entidade.listaEntidadesLocal;

    this.entidadesSenhas = this.entidadesSenhas.filter(obj => obj.qtdePocs > 0);

    this.existemEntidadesSenhas = this.entidadesSenhas.length > 0;

    for (let e of this.entidadesSenhas) {
      this.qtdeBalcaoComTextoProcurado = this.qtdeBalcaoComTextoProcurado + e.qtdeObjetoPorTexto;
    }

    this.ToggleAbrirBarraLateral();

    if (this.qtdeBalcaoComTextoProcurado == 0 && this.existemEntidadesSenhas)
      this.MostrarSenhasEntidades();

    this.linkEntidade = entidade.linkEntity;
    this.linkLivroAmareloLocal = entidade.linkLivroAmarelo;

    this.Loading(false);
    let abc = document.getElementById("loacalpertodesiaposclick");
    abc.focus();
  }

  MinhasSenhas() {
    this.mostrarMinhasSenha = !this.mostrarMinhasSenha ? true : false;
    this.mostrarHistorico = false;
  }

  HistoricoSenhas() {
    this.mostrarHistorico = !this.mostrarHistorico ? true : false;
    this.mostrarMinhasSenha = false;
    this.sendMessage = "";
  }

  Logout() {
    window.location.href = "#";
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }

  ToggleAbrirBarraLateral() {
    if (!this.mapa.sideNavRight.opened)
      this.mapa.sideNavRight.toggle();
  }

  ToggleSideButtonsPosition() {
    const menu = document.getElementsByClassName('wa__rootmenu')[0];
    const menuBot = document.getElementsByClassName('wa__bottom')[0];
    menu.classList.remove('open_sidenav');
    menuBot.classList.remove('open_sidenav');
  }

  /*EnviarEmail(senha: Senha, email: string) {
    this.Loading(true);

    senha.email = email;
    senha.tipoEnvio = 1;

    this.service.EnviarEmailSenha(senha, this.horario, this.mapa.GetLanguage()).subscribe(result => this.RespostaEnvio(1, result));
  }*/

  /*EnviarEmailChange(idPOC: number, divisionCode: string, emailSMS: string) {
    this.Loading(true);

    this.service.EnviarEmailSenhaChange(0, idPOC, divisionCode, this.userId, 'Senha', this.publicIp, this.horario, emailSMS, this.mapa.GetLanguage()).subscribe(result => this.delay(1400).then(any => {
      if (result.status == 0 && result.message != null) {
        this.openDialog(result.message);
        this.mostrarMinhasSenha = false;
        this.senhasHistorico = [];
      }
      else if (result.status == 1 && result.senha != null) {
        this.senhasHistorico = result.senha;
      }

      this.RespostaEnvio(1, result);
    }));
  }*/

  /*EnviarSMS(senha: Senha, sms: string) {
    this.Loading(true);

    senha.sms = sms;
    senha.tipoEnvio = 2;


    this.service.EnviarSMSSenha(senha, this.mapa.GetLanguage()).subscribe(result => this.RespostaEnvio(2, result));

  }*/

  /*EnviarSMSChange(idPOC: number, divisionCode: string, emailSMS: string) {
    this.Loading(true);

    this.service.EnviarSMSSenhaChange(0, idPOC, divisionCode, this.userId, 'Senha', this.publicIp, emailSMS, this.mapa.GetLanguage()).subscribe(result => this.delay(1400).then(any => {
      if (result.status == 0 && result.message != undefined) {
        this.openDialog(result.message);
        this.mostrarMinhasSenha = false;
        this.senhasHistorico = [];
      }
      else if (result.status == 1 && result.senha != null) {
        this.senhasHistorico = result.senha;
      }
      this.RespostaEnvio(2, result);
    }));

  }*/

  RespostaEnvio(type: number, result: any) {
    this.mostrarInserirEmail = false;
    this.mostrarInserirTelemovel = false;

    if (type == 1) {
      if (result.status == 1) {
        this.sendMessage = "Email enviado com sucesso!";
        this.openSuccessDialog(this.sendMessage);
        this.MostrarInformacoesSenha();
      }
      else {
        this.MostrarInformacoesSenhaError();
      }
    }
    else {
      if (result.status == 1) {
        this.sendMessage = "SMS enviado com sucesso!";
        this.openSuccessDialog(this.sendMessage);
        this.MostrarInformacoesSenha();
      }
      else {
        this.MostrarInformacoesSenhaError();
      }
    }
    this.Loading(false);
  }

  /*public resetDropDownFilter() {
    this.carregarFiltros();
  }*/

  
  /*
  FiltrarLocais(selectedValue: number) {

    this.mostrarMaisLocais = false;
    this.selectedFilter = !this.selectedFilter;
    this.mapa.selectMethodInitial = true;
    this.tipoFiltroEscolhido = selectedValue;

    if (this.exploreSearchForm) {
      return this.searchPoI(this.exploreSearchForm);
    }

    let searchType = this.mapa.textSearch === '' ? 1 : 5;
    this.mapa.ListarPontos(this.mapa.userPosition.latitude, this.mapa.userPosition.longitude, this.busca.type, this.mapa.textSearch, '', '', '', this.mapa.radius, searchType, parseInt(selectedValue.toString()));
  }*/

  FiltrarAreas(code: string, tipo: number) {

    switch (tipo) {
      case 2: {
        //distritos possui lista dependente
        this.municipios = this.municipiosTodos.filter(
          area => area.code.substr(0, 2) === code);
        this.addTodosMunicipio();
        break;
      }
      case 3: {
        //municipios possui lista dependente
        this.freguesias = this.freguesiasTodos.filter(
          area => area.code.substr(0, 4) === code);
        this.addTodosFreguesias();
        break;
      }
      default: {
        break;
      }
    }
  }

  CarregarPocService(pocservice: any, serviceId: number) {
    this.titulo = pocservice.ponto.titulo;
    this.informacao = pocservice.ponto.descricao;
    this.imgPath = pocservice.ponto.imgPath;
    this.site = pocservice.ponto.site.trim();
    this.telefone = pocservice.ponto.telefone;
    this.morada = pocservice.ponto.morada;
    this.emails = (pocservice.ponto.email as string).split(",");
    this.horario = pocservice.ponto.horario.split("//");
    this.restricoes = pocservice.ponto.restricoes.split("//");

    this.restricaoHighlight = [];

    this.MostrarHorario();
    //restrictions treatment
    if (pocservice.ponto.restricoes.indexOf('[highlight]') !== -1) {
      for (let i of this.restricoes) {
        if (i.indexOf('[highlight]') !== -1)
          this.restricaoHighlight.push(i.replace('[highlight]', ''));
      }
    }


    this.idPonto = pocservice.ponto.id;

    this.latPonto = pocservice.ponto.lat;
    this.lngPonto = pocservice.ponto.lng;
    this.latAtual = pocservice.ponto.latCentral;
    this.lngAtual = pocservice.ponto.lngCentral;

    this.mostrarEntidade = true;
    this.mostrarBusca = false;
    this.mostrarSenha = false;
    this.mostrarLocal = false;

    this.mostrarInfos = false;
    this.mostrarEntidades = true;
    this.showListPointsOfCare = false;

    this.entidadesSenhas = pocservice.entidades;

    for (let i of pocservice.entidades) {
      if (i.idEntidade == pocservice.entidadeId)
        this.entidade = i;
    }

    this.entidade.listaEntidadesLocal = pocservice.entidades;

    for (let i of this.entidade.listaEntidadesLocal) {
      if (i.idEntidade == pocservice.entidadeId)
        this.entidadeEscolhida = i;
    }

    if (this.entidadesSenhas.length == 0)
      this.existemEntidadesSenhas = false;
    else
      this.existemEntidadesSenhas = true;

    this.mostrarEntidadeServicos = true;
    this.mostrarEntidadeInfo = false;
    this.mostrarEntidadeServicosDetail = true;
    this.idServiceClicked = serviceId;
  }

  CarregarAreas(areas: any) {

    this.distritosTodos = this.distritos = areas.distritos;
    this.municipiosTodos = this.municipios = areas.municipios;
    this.freguesiasTodos = this.freguesias = areas.freguesias;
    this.tiposTodos = this.tipos = areas.tipos;
    this.addTodosMunicipio();
    this.addTodosTipos();
    this.addTodosMunicipio();
    this.addTodosFreguesias();
  }

  SenhaHoje(dataEmissao: string): boolean {

    if (dataEmissao === this.GetCurrentFormatDate())
      return false;
    else
      return true;
  }

  MostrarGoTopButton(indice: number) {
    if ((<HTMLElement>document.getElementsByClassName('fundo')[indice]).scrollTop > 0)
      this.mostrarBotaoTopPage[indice] = true;
    else
      this.mostrarBotaoTopPage[indice] = false;
  }

  GoTopPage(indice: number) {
    (<HTMLElement>document.getElementsByClassName('fundo')[indice]).scrollTop = 0;
  }

  ngOnInit() {

    /*this.activatedRoute.queryParams.subscribe(params => {
      const param = params['text'];
      this.alteredLocaisPertoDeSiFilter(param && param.length);
    });
    this.alteredLocaisPertoDeSiFilter(this.hideGroupsAndLifeEvents());*/
    //this.service.BuscarIpPublico().subscribe(result => this.publicIp = result['ip']);
    //this.service.BuscarAreas(this.language, this.country).subscribe(result => this.CarregarAreas(result));
    //this.service.BuscarTotalLocaisServicos().subscribe(res => this.totalLocaisServicos = res);
    //this.carregarFiltros();
  }

  labelSlider(value: number): string {
    return value + ' km';
  }

  openDialog(message) {
    let dialogRef = this.dialog.open(ErrorAlertComponent, {
      data: { erro: message }
    });
  }

  openSuccessDialog(message) {
    let dialogRef = this.dialog.open(SuccessAlertComponent, {
      data: { success: message }
    });
  }

  //Format number methods
  transform(value: any): number {

    if (value > 999) {
      //convert meters in kilometers
      value = value / 1000;

      //2 decimals place
      value = Math.round(value * 100) / 100;

      //format number
      return this.localeString(value, ' km');
    }
    else {
      //2 decimals place
      value = Math.round(value);

      //format number
      return this.localeString(value, ' m');
    }


  }

  missingOneDecimalCheck(nStr) {
    nStr += '';
    const x = nStr.split(',')[1];
    if (x && x.length === 1) return true;
    return false;
  }

  missingAllDecimalsCheck(nStr) {
    nStr += '';
    const x = nStr.split(',')[1];
    if (!x) return true;
    return false;
  }

  localeString(nStr, unit) {
    if (nStr === '') return '';
    let x, x1, x2, rgx, y1, y2;
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? ',' + x[1] : '';
    rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }

    /** If value was inputed by user, it could have many decimals(up to 7)
        so we need to reformat previous x1 results */
    if (x1.indexOf(',') !== -1) {
      y1 = x1.slice(x1.lastIndexOf(',')).replace(/\./g, '');

      y2 = x1.split(',');
      x = y2[0] + y1;
    } else {
      x = x1 + x2;
      if (this.missingOneDecimalCheck(x)) return x += '0' + unit;
    }

    return x + unit;
  }

  showTabela: boolean = false;
  dataSource: Array<any> = [];
  displayedColumns: string[] = ['hora'];
  toggleTable() {
    this.showTabela = !this.showTabela
  }

  /*getProvider(providersId: string): void {
    this.service.getInfoProviders(providersId).subscribe(
      (response) => this.provider = response,
      (error) => console.log('provider ', error),
      () => console.log('provider'));
    ;
  }*/

  hasPermission(type: string): boolean {
    if (this.provider) {
      let permission = false;
      switch (type) {
        case 'groups':
          return permission = this.provider && this.provider.kit.groups ? this.provider.kit.groups : false;
          break;
        case 'life-events':
          return permission = this.provider && this.provider.kit.lifeEvents ? this.provider.kit.lifeEvents : false;
          break;
        case 'tickets':
          return permission = this.provider && this.provider.kit.tickets ? this.provider.kit.tickets : false;
          break;
        case 'digital-services-list':
          return permission = this.provider && this.provider.kit.digitalServices ? this.provider.kit.digitalServices : false;
          break;
        case 'scheduling':
          return permission = this.provider && this.provider.kit.scheduling ? this.provider.kit.scheduling : false;
          break;
        case 'search':
          return permission = this.provider && this.provider.kit.search ? this.provider.kit.search : false;
          break;
        case 'Estatisticas':
          return permission = this.provider && this.provider.kit.statistics ? this.provider.kit.statistics : false;
          break;
        case 'Ponto Uníco':
          return permission = this.provider && this.provider.kit.uniqPoint ? this.provider.kit.uniqPoint : false;
          break;
        case 'Autenticação':
          return permission = this.provider && this.provider.kit.authentication ? this.provider.kit.authentication : false;
          break;
        case 'Link Mapa':
          return permission = this.provider && this.provider.kit.mapLink ? this.provider.kit.mapLink : false;
          break;
        default:
          return permission;
          break;
      }
    } else {
      return true;
    }
  }

  public hideGroupsAndLifeEvents(): boolean {
    const hideGroupsAndLifeEvents = sessionStorage.getItem('hideGroupsAndLifeEvents');
    return hideGroupsAndLifeEvents == 'true';
  }

  public showNearbyPlacesOnTop() : boolean {
    return window.location.href.includes('SearchText');
  }

  /**@deprecated */
  /*public alteredLocaisPertoDeSiFilter(newValue: boolean): void {
    sessionStorage.setItem('hideGroupsAndLifeEvents', newValue ? 'true' : 'false');
  }*/

  getColumns(colluns: string []): string [] {
    let finalColluns = ['Hours',...colluns];
    return finalColluns;
  }


  /** Used to control active group button 
   * @deprecated
    */
  isItemActive(item: Group) {
    return item.isActive ? true : false;
    /*if (item.isActive){
      return true;
    }
    else{
      return false;
    }*/
  }

   /** Used to control active group button */
  setItemActive(item: Group) {

    this.mapa.groups.forEach(g => g.isActive = false);

    item.isActive = true;
  }

}

export interface Item {
  code: string;
  nome: string;
}

export interface Category {
  id: number;
  code: string;
  categoryCode: string;
  head: boolean;
  nome: string;
}

export interface PontoCidadao {
  id: number;
  titulo: string;
  descricao: string;
  lat: number;
  lng: number;
  imgPath: string;
  site: string;
  telefone: string;
  morada: string;
  email: string;
  horario: string;
  restricoes: string;
  image: string;
}

export interface Entidade {
  idEntidade: number;
  idPonto: number;
  logoPath: string;
  nome: string;
  informacao: string;
  site: string;
  telefone: string;
  morada: string;
  email: string;
  listaEntidadesLocal: EntidadeLocalViewModel[];
}

export interface EntidadeLocalViewModel {
  idEntidade: number;
  idPonto: number;
  logoPath: string;
  nome: string;
  informacao: string;
  site: string;
  telefone: string;
  morada: string;
  email: string;
  divisoes: Divisao[];
  servicos: Servico[];
  qtdePocs: number;
  qtdeObjetoPorTexto: number;
  linkAgendamento: string;
}

export interface Servico {
  idServico: number;
  nome: string;
  informacao: string;
  urlService: string;
}

export interface Divisao {
  idEntidade: number;
  idPOC: number;
  pocName: string;
  divisionCode: string;
  divisionName: string;
  informacao: string;
  tempoMedAtendimento: string;
  tempoEsperaUltimaSenha: string;
  utentesEmEspera: string;
  numUltimaSenha: string;
  estado: string;
  habilitarSenha: number;
  qtdeTextoEcontradoDivisao: number;
  habilitarAgendamento: boolean;
  linkName: string;
  linkApi: string;
  linkApiIsChecked: boolean;
  linkLivroAmarelo: string;
  serviceDemands: ServiceDemands;
  serviceDemandsTable?: any;
  tickets: Ticket;
}

export interface Senha {
  idSenha: number;
  codigoSenha: string;
  tipoEnvio: number;
  email: string;
  sms: string;
  nomePonto: string;
  nomeEntidade: string;
  nomeServico: string;
  dataEmissao: string;
  dataHoraEmissao: string;
  mensagemErro: string;
  mensagem: string;
  divisionName: string;
}

export interface Busca {
  type: string;
  district: string;
  county: string;
  parish: string;
  pointName: string;
  category: string;

}

export interface TotalLocaisServicosViewModel {
  locais: number;
  servicos: number;
}

export interface Ticket {
  codigoErro?: string;
  estado?: boolean;
  mensagemErro: string;
  senhasEspera: string;
  sucesso: boolean
  tempoEsperaUltimaSenha: string;
  tempoMedioAtendimento: string;
  ultimaSenhaAtendida: string;
  waitingState: string;
}
