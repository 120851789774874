<!--Explore panel nao esta sendo utilizado Ruth 23/05/2023-->
<div class="explore-panel">
  <!-- BACK & ABOUT -->
  <div class="header-actions">
    <button (click)="goBack.emit()">
      <mat-icon svgIcon="arrowBack"></mat-icon>
      <span>{{ 'VOLTAR' | translate }}</span>
    </button>
    <button (click)="about.emit()" >
      <span>{{ 'SOBRE' | translate }}</span>
    </button>
  </div>

  <!-- <form (submit)=""></form> -->
  <!-- EXPLORE -->
  <form class="form-explore">
    <h2 class="explore-title">
      {{ 'Explorar' | translate }}
    </h2>
    <div class="explore-geo-check">
      <label for="geolocation-checkbox-input">
        <mat-icon svgIcon="pinLocation" mat-prefix></mat-icon>
        <p>{{ 'geo' | translate }}</p>
        <mat-checkbox
          id="geolocation-checkbox"
          color="primary"
          labelPosition="before"
          (change)="useGeolocationToggle($event)"
          [checked]="canUseGeolocation"
        ></mat-checkbox>
      </label>
    </div>

    <div class="explore-search-text">
      <mat-form-field>
        <mat-label>{{ 'Pesquisa' | translate }}</mat-label>
        <input matInput [formControl]="searchControl" type="text" attr.aria-label="{{ 'Pesquisar' | translate }}">
      </mat-form-field>
    </div>

    <div class="explore-filters">
      <!-- SELECAO DE EVENTOS DE VIDA -->
      <div [hidden]="showDropdown !== null && showDropdown != 'life-events'">
        <button id="life-events" (click)="toggleDropdown('life-events')" [attr.aria-expanded]="showDropdown === 'life-events' ? 'true' : 'false'">
          <div id="life-events-title">
            <b>{{ 'Categoria' | translate }}{{ chosenFilters.lifeEvent ? (': ' + chosenFilters.lifeEvent?.nome) : '' }}</b>
          </div>
          <div id="life-events-arrow">
            <img
              aria-hidden="true"
              src="assets/icons/setapz.svg"
              alt="{{ 'alternar entre mostrar e ocultar conteúdo' | translate }}"
              [ngClass]="{ 'dropdown-opened': showDropdown === 'life-events' }"
            >
          </div>
        </button>
        <div [hidden]="showDropdown != 'life-events'" class="options">
          <div *ngFor="let lifeEvent of lifeEvents; let i = index;">
            <button
              (click)="selectLifeEvent(lifeEvent)" [hidden]="!lifeEvent.head"
            >
              <div id="life-event-name-{{ i }}">
                {{ lifeEvent.nome }}
              </div>
              <div
                id="life-event-code-{{ lifeEvent.categoryCode }}"
                *ngIf="chosenFilters.lifeEvent && chosenFilters.lifeEvent.categoryCode === lifeEvent.categoryCode"
              >
                <img aria-hidden="true" src="assets/SVG/icone_Artboard 24_visto.svg" alt="{{ 'item marcado' | translate }}">
              </div>
            </button>
          </div>
        </div>
      </div>
      <!-- FIM SELECAO DE EVENTOS DE VIDA -->

      <!-- SELECAO DE SERVICOS POR EVENTOS DE VIDA-->
      <div [hidden]="!chosenFilters.lifeEvent || (showDropdown !== null && showDropdown != 'services')">
        <button id="services" (click)="toggleDropdown('services')" [attr.aria-expanded]="showDropdown === 'services' ? 'true' : 'false'">
          <div id="services-title">
            <b>{{ 'Serviços' | translate }}{{ chosenFilters.service ? (': ' + chosenFilters.service?.nome) : '' }}</b>
          </div>
          <div id="services-arrow">
            <img
              aria-hidden="true"
              src="assets/icons/setapz.svg"
              alt="{{ 'seta para alternar entre mostrar e ocultar conteúdo' | translate }}"
              [ngClass]="{ 'dropdown-opened': showDropdown === 'services' }"
            >
          </div>
        </button>
        <div [hidden]="showDropdown != 'services'" class="options">
          <div *ngFor="let service of filterServicesSelectedByLifeEvent(); let i = index;">
            <button (click)="selectService(service)">
              <div id="service-name-{{ i }}">
                {{ service.nome }}
              </div>
              <div id="service-code-{{ service.code }}"
                *ngIf="chosenFilters.service && chosenFilters.service.code === service.code"
              >
                <img aria-hidden="true" src="assets/SVG/icone_Artboard 24_visto.svg" alt="{{ 'imagem simbolizando item marcado' | translate }}">
              </div>
            </button>
          </div>
        </div>
      </div>
      <!-- FIM SELECAO DE SERVICOS POR EVENTOS DE VIDA-->

      <!--DISTRITOS-->
      <div [hidden]="!chosenFilters.lifeEvent || canUseGeolocation || (showDropdown !== null && showDropdown != 'districts')">
        <button id="districts" (click)="toggleDropdown('districts')" [attr.aria-expanded]="showDropdown === 'districts' ? 'true' : 'false'">
          <div id="districts-title">
            {{ 'Distrito' | translate }}{{ chosenFilters.district ? (': ' + chosenFilters.district?.nome) : '' }}
          </div>
          <div id="districts-arrow">
            <img
              aria-hidden="true"
              src="assets/icons/setapz.svg"
              alt="{{ 'seta para alternar entre mostrar e ocultar conteúdo' | translate }}"
              [ngClass]="{ 'dropdown-opened': showDropdown === 'districts' }"
            >
          </div>
        </button>
        <div [hidden]="showDropdown != 'districts'" class="options">
          <div *ngFor="let district of districts; let i = index;">
            <button (click)="selectDistrict(district)">
              <div id="district-name-{{ i }}">
                {{ district.nome }}
              </div>
              <div
                id="district-code-{{ district.code }}"
                *ngIf="chosenFilters.district && chosenFilters.district.code == district.code"
              >
                <img aria-hidden="true" src="assets/SVG/icone_Artboard 24_visto.svg" alt="{{ 'imagem simbolizando item marcado' | translate }}">
              </div>
            </button>
          </div>
        </div>
      </div>
      <!--FIM DISTRITOS-->

      <!--MUNICIPIOS-->
      <div [hidden]="!chosenFilters.district || canUseGeolocation || (showDropdown !== null && showDropdown != 'municipalities')">
        <button id="municipalities" (click)="toggleDropdown('municipalities')" [attr.aria-expanded]="showDropdown === 'municipalities' ? 'true' : 'false'">
          <div id="municipalities-title">
            {{ 'Município' | translate }}{{ chosenFilters.municipality ? (': ' + chosenFilters.municipality?.nome) : '' }}
          </div>
          <div id="municipalities-arrow" >
            <img
              aria-hidden="true"
              src="assets/icons/setapz.svg"
              alt="{{ 'seta para alternar entre mostrar e ocultar conteúdo' | translate }}"
              [ngClass]="{ 'dropdown-opened': showDropdown === 'municipalities' }"
            >
          </div>
        </button>
        <div [hidden]="showDropdown != 'municipalities'" class="options">
          <div *ngFor="let municipality of filterMunicipalitiesSelectedByDistrict(); let i = index;">
            <button (click)="selectMunicipality(municipality)">
              <div id="municipality-name-{{ i }}">
                {{ municipality.nome }}
              </div>
              <div
                id="municipality-code-{{municipality.code}}"
                *ngIf="chosenFilters.municipality && chosenFilters.municipality.code == municipality.code"
              >
                <img aria-hidden="true" src="assets/SVG/icone_Artboard 24_visto.svg" alt="{{ 'imagem simbolizando item marcado' | translate }}">
              </div>
            </button>
          </div>
        </div>
      </div>
      <!--FIM MUNICIPIOS-->

      <!--FREGUESIA-->
      <div [hidden]="!chosenFilters.municipality || canUseGeolocation || (showDropdown !== null && showDropdown != 'parishes')">
        <button id="parishes" (click)="toggleDropdown('parishes')" [attr.aria-expanded]="showDropdown === 'parishes' ? 'true' : 'false'">
          <div id="parishes-name">
            {{ 'Freguesia' | translate }}{{ chosenFilters.parish ? (': ' + chosenFilters.parish?.nome) : '' }}
          </div>
          <div id="parishes-arrow">
            <img
              aria-hidden="true"
              src="assets/icons/setapz.svg"
              alt="{{ 'seta para alternar entre mostrar e ocultar conteúdo' | translate }}"
              [ngClass]="{ 'dropdown-opened': showDropdown === 'parishes' }"
            >
          </div>
        </button>
        <div [hidden]="showDropdown != 'parishes'" class="options">
          <div *ngFor="let parish of filterParishesSelectedByMunicipality(); let i = index">
            <button (click)="selectParish(parish)">
              <div id="parish-name-{{ i }}">
                {{ parish.nome }}
              </div>
              <div
                id="parish-code-{{ parish.code }}"
                *ngIf="chosenFilters.parish && chosenFilters.parish?.code == parish.code"
              >
                <img aria-hidden="true" src="assets/SVG/icone_Artboard 24_visto.svg" alt="{{ 'imagem simbolizando item marcado' | translate }}">
              </div>
            </button>
          </div>
        </div>
      </div>
      <!--FIM FREGUESIA-->
    </div>
    <div class="explore-errors">
      <mat-error *ngIf="errorMessage && ((!chosenFilters.district && !canUseGeolocation || showDropdown == 'districts') || !chosenFilters.lifeEvent)">
        {{ errorMessage }}
      </mat-error>
    </div>
    <div class="explore-actions">
      <button [ngClass]="{ 'disabled': !canSearch() }" type="submit" (click)="search()">{{ 'Pesquisar'| translate }}</button>
      <button (click)="resetForm()">{{ 'Limpar'| translate }}</button>
    </div>
    <div class="explore-errors">
      <mat-error *ngIf="poiExist">
        {{ 'points_not_found_1' | translate }} {{ mapArea }} km, {{ 'points_not_found_2' | translate }}
      </mat-error>
    </div>
  </form>
</div>
