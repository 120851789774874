import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from './header/header.component';
import { FilteroptionsComponent } from './filteroptions/filteroptions.component';
import { IndexComponent } from './index/index.component';
import { RouterModule } from '@angular/router';
import { CoreRoutingModule } from './core-routing.module';
import {MatSidenavModule} from '@angular/material/sidenav';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule} from '@angular/material/slider';
import { MatTableModule } from '@angular/material'
import { SidebarrightComponent } from './sidebarright/sidebarright.component';
import { MapaComponent } from './mapa/mapa.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../environments/environment';
import { GoogleAnalyticsModule, GA_TOKEN } from 'angular-ga';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { MatButtonModule, MatButtonToggleModule, MatDialogModule, ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from '@angular/material';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser';
import { ErrorAlertComponent } from "./error-alert/error-alert.component";
import { SuccessAlertComponent } from './success-alert/success-alert.component';

import 'hammerjs';

import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { ModalComponent } from './modal/modal.component';
import { EmailModalComponent } from './modal/email-modal/email-modal.component';
import { SmsModalComponent } from './modal/sms-modal/sms-modal.component';
import {TutorialComponent} from './tutorial/tutorial.component';
import { SplashComponent } from './splash/splash.component';
import { NearbyPointsOfInterestButtonComponent } from "./sidebarright/nearby-points-of-interest-button/nearby-points-of-interest-button.component";
import { ExplorePanelComponent } from "./sidebarright/explore-panel/explore-panel.component";
import { TableComponent } from './table/table.component';
import { NearbyPlacesComponent } from "./sidebarright/nearby-places/nearby-places.component";
import { TranslateModule } from "@ngx-translate/core";
import { NgxTranslateModule } from "../translate/translate.module";
import { ConfirmationModalComponent } from "./modal/confirmation-modal/confirmation-modal.component";
import { ChartComponent } from "./chart/chart.component";
import { SearchComponent } from "./search/search.component";
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
    pan: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({

    imports: [
        CommonModule,
        TranslateModule,
        NgxTranslateModule,
        CoreRoutingModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatTableModule,
        MatIconModule,
        HttpClientModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCheckboxModule,
        MatInputModule,
        MatSliderModule,
        MatButtonModule,
        MatButtonToggleModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CommonModule,
        BrowserModule,
        MatDialogModule,
        BrowserAnimationsModule,
        GoogleAnalyticsModule.forRoot(),
        AgmCoreModule.forRoot({
          apiKey: environment.googleApiKey
        }),
        DeviceDetectorModule.forRoot()
        
    ],
    providers: [
        {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
        { provide: GA_TOKEN, useValue: 'UA-35831726-1' },
        {
          provide: HAMMER_GESTURE_CONFIG,
          useClass: MyHammerConfig,
        }
    ],
    declarations: [
        HeaderComponent,
        FilteroptionsComponent,
        SidebarComponent,
        FooterComponent,
        SidebarrightComponent,
        NearbyPlacesComponent,
        NearbyPointsOfInterestButtonComponent,
        ExplorePanelComponent,
        MapaComponent,
        IndexComponent,
        ErrorAlertComponent, SuccessAlertComponent, ModalComponent, EmailModalComponent, SmsModalComponent, ConfirmationModalComponent, ChartComponent,
        TutorialComponent,
        SplashComponent,
        TableComponent,
        SearchComponent
    ],
    exports:[
        RouterModule,
        HeaderComponent,
        FilteroptionsComponent,
        MatSidenavModule,
        SidebarComponent,
        FooterComponent,
        SidebarrightComponent,
        MatSelectModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        MapaComponent,
        MatButtonModule,
        MatButtonToggleModule,
        MatDialogModule,
        IndexComponent
    ],
    bootstrap: [SidebarrightComponent,MapaComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class CoreModule{}
