import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private language: string = 'PT';
  private country: string = 'PT';

  public setLanguage(language: string) {
    this.language = (language || '').trim() || this.language;
  }

  public getLanguage() {
    return this.language;
  }

  public setCountry(country: string) {
    this.country = (country || '').trim() || this.country;
  }

  public getCountry() {
    return this.country;
  }
}
