<agm-map
  aria-label="Este é o Mapa de Portugal. Você está em Portugal, e pode consultar na lista abaixo os pontos de atendimento de serviços públicos que tem mais próximos de si."
  class="map-style" (mapReady)="mapReady($event)" (zoomChange)="zoomChange($event)" [latitude]="latFirstPoint"
  [longitude]="lngFirstPoint" [zoom]="14" [zoomControl]="zoomControl" [clickableIcons]="false" [streetViewControl]="false" 
  (mapClick)="MakeMarker($event)" [styles]="mapStyles" style="height: 72vh;" [style.width.px]="widthMap"
  [gestureHandling]="'greedy'">

  <agm-marker aria-hidden="true" [iconUrl]="'/assets/icons/pegman1.png'" [zIndex]="9999"
    [latitude]="userPosition.latitude" [longitude]="userPosition.longitude"></agm-marker>
  <agm-circle aria-hidden="true" [latitude]="userPosition.latitude" [longitude]="userPosition.longitude" [circleDraggable]="false"
    (circleClick)="MakeMarker($event)" [editable]="false" [fillColor]="'#cedbe047'" [radius]="areaInMeters"
    [hidden]="searchType != 1"></agm-circle>

  <agm-marker aria-hidden="true" *ngFor="let point of mapPointsDto" id="{{point.locationId}}" [latitude]="point.latitude"
    [longitude]="point.longitude" [iconUrl]="point.icon" (markerClick)="showPointInSidebar(point.locationId);"></agm-marker>
</agm-map>
