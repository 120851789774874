import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { transformDistance } from "../../shared/helpers";

@Component({
  selector: 'app-nearby-poi-button',
  templateUrl: './nearby-points-of-interest-button.component.html',
  styleUrls: ['./nearby-points-of-interest-button.component.scss']
})
export class NearbyPointsOfInterestButtonComponent implements OnInit {

  @Input('data')
  public data: any;

  @Output()
  public onclick: EventEmitter<any> = new EventEmitter();

  @Output()
  public onmouseenter: EventEmitter<any> = new EventEmitter();

  @Output()
  public onmouseleave: EventEmitter<any> = new EventEmitter();

  public constructor() {}

  public ngOnInit() {}

}
