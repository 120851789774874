<div class="modal-chart-close">
  <button mat-dialog-close attr.aria-label="{{'Fechar' | translate}}">
    <mat-icon class="icon" [inline]="true">close</mat-icon>
  </button>
</div>

<div class="justify-content-center">
  <div class="col-12" style="padding-left: 0; padding-right: 0;">
    <p class="modal-chart-title">{{ 'Horas de maior procura' | translate }}</p>
    <p class="modal-chart-subtitle">{{'Dados históricos com base nos últimos 13 dias' | translate}} </p>
  </div>
</div>

 <table class="table-days-of-week">
  <tr scope="row">
     <th scope="col" class="day-of-week" *ngFor="let day of data.statistics">
      <button class="buttonDaysOfWeek"
        [ngClass]="{ 'btn-selected' : selectedDay === day.day, 'btn-non-selected': selectedDay !== day.day}"
        (click)="changeSelectedDay(day.day)" >{{day.dayOfWeek | translate}}</button>
    </th>
  </tr>
</table> 

<!--<div class="table-days-of-week">
  <div *ngFor="let day of data.statistics" >

    <button class="buttonDaysOfWeek"
    [ngClass]="{ 'btn-selected' : selectedDay === day.day, 'btn-non-selected': selectedDay !== day.day}"
    attr.aria-label="{{selectedDay === day.day ? (day.day) + ( 'hours-active-day-label' | translate) : '' }}"
    (click)="changeSelectedDay(day.day)">{{day.dayOfWeek}}</button>

  </div>
</div>-->


<div class="table-body">
  <div class="chart">
    <canvas class="chart-info" #barCanvas></canvas>
  </div>
</div>
