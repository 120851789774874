import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";

import { BaseService } from 'src/app/core/services/base.service';
import { ticketDto } from '../models/ticketDto';
import { ResponseData } from '../../mapa/models/responseData';
import { DivisionServiceTicketDto } from '../models/divisionServiceTicketDto';
import { ResponseDto } from '../../mapa/models/responseDto';
import { DivisionServiceListStatisticTicketDto } from '../../chart/models/divisionServiceListStatisticTicketDto';

@Injectable()
export class ModalService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    /**
     * Get next Ticket available in SIGA system
     * @param sigaInstituteIdentifier Institute identifier on SIGA system
     * @param sigaEntityIdentifier Entity identifier on SIGA system
     * @param sigaLocalIdentifier Local Identifier on SIGA system
     * @param sigaServiceIdentifier Division Service Identifier on SIGA System
     * @param userDeviceId User Device Id to control request on SIGA system
     * @returns 
     */
    getSigaDivisionNextTicket(ticket: ticketDto): Observable<ResponseData<DivisionServiceTicketDto>> {
        
        let serviceUrl = this.UrlService + `/${this.azFunction}/map/pointOfCare/division/sigaNextTicket`;
        return this.http.post<ResponseData<DivisionServiceTicketDto>>(serviceUrl, ticket);
    }

    /**
     * Send ticket by email
     * @param ticket 
     * @returns ResponseDto with success or error message
     */
    sendTicketEmail(ticket: ticketDto): Observable<ResponseDto>{
        return this.http.post<ResponseDto>(this.UrlService + `/${this.azFunction}/map/user/sendEmail`, ticket);
    }

    /**
     * Send ticket by sms
     * @param ticket 
     * @returns ResponseDto with success or error message
     */
    sendTicketSms(ticket: ticketDto): Observable<ResponseDto>{
        return this.http.post<ResponseDto>(this.UrlService + `/${this.azFunction}/map/user/sendSms`, ticket);
    }

    
}