import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

import { ticketDto } from '../models/ticketDto';
import { ModalService } from '../services/modal.service';
import { ResponseDto } from '../../mapa/models/responseDto';
import { TranslateService } from '@ngx-translate/core';
import { confirmationType } from '../models/enum/confirmationType';
import { phoneNumberValidator } from './validation/phoneNumberValidator';
import { ModalComponent } from '../modal.component';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { ticket: ticketDto }, 
              private fb: FormBuilder, 
              private translate: TranslateService,
              public dialog: MatDialog, 
              public dialogRef: MatDialogRef<ConfirmationModalComponent>
              ) {

 }

  emailForm: FormGroup;
  smsForm: FormGroup;
  errorMessage: string;

  selectedRadio: confirmationType;

  displayErrorMessage: boolean = false;
  displaySuccessMessage: boolean = false;

  ngOnInit() {

    this.emailForm = this.fb.group({
      serviceText: ['', [Validators.required, Validators.email]]
    });

    this.smsForm = this.fb.group({
      serviceText: ['', [Validators.required, phoneNumberValidator()]]
    });

  }

  sendEmail() {
    this.displayErrorMessage = false;
    this.displaySuccessMessage = false;

    if (this.emailForm.dirty && this.emailForm.valid) {
      this.data.ticket.email = this.emailForm.value.serviceText;
      this.data.ticket.confirmationType = confirmationType.Email;

      const ticket = this.data.ticket;

      const dialogRef = this.dialog.open(ModalComponent, {
        //width: '550px',
        width: '480px',
        data: {
          ticket
        },
        panelClass: 'modal-container'
      });
  
      dialogRef.afterClosed().subscribe(result => {
  
      });

      this.dialogRef.close()
    }
  }

  sendSms() {
    this.displayErrorMessage = false;
    this.displaySuccessMessage = true;

    if (this.smsForm.dirty && this.smsForm.valid) {
      this.data.ticket.phoneNumber = this.smsForm.value.serviceText;
      this.data.ticket.confirmationType = confirmationType.SMS;

      const ticket = this.data.ticket;

      const dialogRef = this.dialog.open(ModalComponent, {
        //width: '550px',
        width: '480px',
        data: {
          ticket
        },
        panelClass: 'modal-container'
      });
  
      dialogRef.afterClosed().subscribe(result => {
  
      });

      this.dialogRef.close()
    }
  }

  /**
   * Process Response, after send Email
   * @param response 
   */
  processResponseEmail(response: ResponseDto) {
    if (response.statusCode == 200)
      this.displaySuccessMessage = true;
    else if (response.statusCode < 100){//Business Error
      this.displayErrorMessage = true;
      this.errorMessage = response.message;
    }
    else{
      this.displayErrorMessage = true;
      this.errorMessage = this.translate.instant("Não foi possível enviar sua senha.");
    }

    this.emailForm.reset();
  }

   /**
   * Process Response, after send Email
   * @param response 
   */
   processResponseSms(response: ResponseDto) {
    if (response.statusCode == 200)
      this.displaySuccessMessage = true;
    else if (response.statusCode < 100){//Business Error
      this.displayErrorMessage = true;
      this.errorMessage = response.message;
    }
    else{
      this.displayErrorMessage = true;
      this.errorMessage = this.translate.instant("Não foi possível enviar sua senha.");
    }

    this.smsForm.reset();
  }
}
