import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatCheckboxChange, MatDialog } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ErrorAlertComponent } from "../../error-alert/error-alert.component";
import { LifeEventType } from "./entities/life-event-type";
import { NameCodeType } from "./entities/name-code-type";
import { SearchForData } from "./entities/search-for-data";

@Component({
  selector: 'app-explore-panel',
  templateUrl: './explore-panel.component.html',
  styleUrls: ['./explore-panel.component.scss']
})
export class ExplorePanelComponent implements OnInit, OnDestroy {

  @Input()
  public poiExist: boolean = false;

  @Input()
  public mapArea: any = null;

  @Output()
  public about: EventEmitter<any> = new EventEmitter();

  @Output()
  public goBack: EventEmitter<any> = new EventEmitter();

  @Output()
  public onSearch: EventEmitter<SearchForData> = new EventEmitter();

  public canUseGeolocation: boolean = false;

  public showDropdown?: string = null;

  public searchControl: FormControl = new FormControl();

  public errorMessage: string = '';

  public geoPermissionDeniedError: string = '';

  private dropdownPropertyAll: LifeEventType = {
    nome: 'Todos',
    code: '',
    categoryCode: '',
    head: true
  };

  public chosenFilters = {
    lifeEvent: null,
    service: null,
    district: null,
    municipality: null,
    parish: null,
  }

  public lifeEvents: LifeEventType[] = [];

  public services: LifeEventType[] = [];

  public districts: NameCodeType[] = [];

  public municipalities: NameCodeType[] = [];

  public parishes: NameCodeType[] = [];

  private translationSubscription$?: Subscription;

  public constructor(
    private readonly dialog: MatDialog,
    public readonly translate: TranslateService
  ) {}

  public ngOnInit() {

    this.onTranslationChange();
    this.getDefaultTranslationMessages();
    this.geolocationIsActive();
    //this.loadDropdownFilters();
  }

  public ngOnDestroy() {
    if (this.translationSubscription$){
      this.translationSubscription$.unsubscribe();
    }
  }


  /*private loadDropdownFilters() {
    this.exploreService.findAreas().subscribe({
      next: (results) => {
        this.lifeEvents = [this.dropdownPropertyAll, ...results.tipos.filter(evt => evt.head)];
        this.services = [this.dropdownPropertyAll, ...results.tipos.filter(evt => !evt.head)];
        this.districts = results.distritos;
        this.municipalities = [this.dropdownPropertyAll, ...results.municipios];
        this.parishes = [this.dropdownPropertyAll, ...results.freguesias];
      }
    });
  }*/

  private geolocationIsActive(): void {
    if('geolocation' in navigator) {
      this.canUseGeolocation = true;
    }
  }

  private onTranslationChange() {
    this.translationSubscription$ = this.translate.onTranslationChange.subscribe({
      next: () => { this.getDefaultTranslationMessages() }
    });
  }

  /*private getDefaultTranslationMessages() {
    const geoPermissionsError$ = this.translate.get('permission_geo').subscribe({
      next: (text: string) => { this.geoPermissionDeniedError = text },
      complete: () => { geoPermissionsError$.unsubscribe() }
    });

    const allWord$ = this.translate.get('Todos').subscribe({
      next: (text: string) => { this.dropdownPropertyAll.nome = text; },
      complete: () => { allWord$.unsubscribe() }
    });
  }*/

  private getDefaultTranslationMessages() {
    this.geoPermissionDeniedError = this.translate.instant('permission_geo');

    this.dropdownPropertyAll.nome = this.translate.instant('Todos');
  }

  public useGeolocationToggle($event: MatCheckboxChange) {
    this.canUseGeolocation = $event.checked;

    if (this.canUseGeolocation) {
      navigator.geolocation.watchPosition(
        () => {},
        (error) => {
          if (error.code == error.PERMISSION_DENIED) {
            this.openDialog(this.geoPermissionDeniedError);
          }
          this.canUseGeolocation = false;
        }
      );
    }

    this.resetError();
  }

  public resetError() {
    this.errorMessage = '';
  }

  public openDialog(message) {
    this.dialog.open(ErrorAlertComponent, {
      data: { erro: message }
    });
  }

  public toggleDropdown(name: string) {
    if (this.showDropdown === name) {
      this.showDropdown = null;
      return;
    }

    this.showDropdown = name;
  }

  public selectLifeEvent(lifeEvent: LifeEventType) {
    this.chosenFilters.lifeEvent = (this.chosenFilters.lifeEvent != lifeEvent) ?
      lifeEvent :
      null;

    if (this.chosenFilters.lifeEvent == lifeEvent && this.errorMessage == 'Selecione um evento de vida.') {
      this.errorMessage = '';
    }

    this.clearService();
    if (!this.chosenFilters.lifeEvent) {
      this.clearDistrict();
    }
    document.getElementById(this.showDropdown).focus()
    this.showDropdown = null;
  }

  public selectService(service: LifeEventType) {
    this.chosenFilters.service = (this.chosenFilters.service != service) ?
      service :
      null;

    this.showDropdown = null;
  }

  public selectDistrict(district: NameCodeType) {
    this.chosenFilters.district = (this.chosenFilters.district != district) ?
      district :
      null;

    if (this.chosenFilters.district == district && this.errorMessage == 'Selecione um distrito.') {
      this.errorMessage = '';
    }

    this.clearMunicipality();
    this.showDropdown = null;
  }

  public selectMunicipality(municipality: NameCodeType) {
    this.chosenFilters.municipality = (this.chosenFilters.municipality != municipality) ?
      municipality :
      null;

    this.clearParishes();
    this.showDropdown = null;
  }

  public selectParish(parish: NameCodeType) {
    this.chosenFilters.parish = (this.chosenFilters.parish != parish) ?
      parish :
      null;

    this.showDropdown = null;
  }

  public filterServicesSelectedByLifeEvent(): LifeEventType[] {
    return this.chosenFilters.lifeEvent ?
      this.services.filter(service => this.canAddToServicesFilter(service)) :
      [];
  }

  private canAddToServicesFilter(service: LifeEventType) {
    return this.chosenFilters.lifeEvent && (
      // If Life event is All, or...
      !this.chosenFilters.lifeEvent.code ||
      // Service is All, or...
      !service.categoryCode ||
      // Service's code starts with the code of the Life event
      service.categoryCode.startsWith(this.chosenFilters.lifeEvent.categoryCode)
    );
  }

  public filterMunicipalitiesSelectedByDistrict(): NameCodeType[] {
    return this.chosenFilters.district ?
      this.municipalities.filter(municipality => this.canAddToMunicipalitiesFilter(municipality)) :
      [];
  }

  private canAddToMunicipalitiesFilter(municipality: NameCodeType) {
    return this.chosenFilters.district && (
      // Municipality is All, or...
      !municipality.code ||
      // Municipality's code starts with the code of the Life event
      municipality.code.startsWith(this.chosenFilters.district.code)
    );
  }

  public filterParishesSelectedByMunicipality(): NameCodeType[] {
    return this.chosenFilters.municipality ?
      this.parishes.filter(parish => this.canAddToParishesFilter(parish)) :
      [];
  }

  private canAddToParishesFilter(parish: NameCodeType) {
    return this.chosenFilters.municipality && (
      // Parish is All, or...
      !parish.code ||
      // Parish's code starts with the code of the Municipality or District
      parish.code.startsWith(this.chosenFilters.municipality.code || this.chosenFilters.district.code)
    );
  }

  public canSearch(): boolean {
    if (this.canUseGeolocation) {
      return this.canSearchWithGeolocation();
    }

    return this.canSearchWithoutGeolocation();
  }

  public search(): void {
    if (this.isStringSearchOrHasErrors()) {
      return;
    }

    this.onSearch.emit({
      canUseGeolocation: this.canUseGeolocation,
      lifeEvent: this.chosenFilters.lifeEvent,
      service: this.chosenFilters.service,
      district: this.chosenFilters.district,
      municipality: this.chosenFilters.municipality,
      parish: this.chosenFilters.parish,
      search: (this.searchControl.value || '').trim()
    });
  }

  private isStringSearchOrHasErrors(): boolean {
    if (this.canSearchString() && !this.chosenFilters.lifeEvent) {
      this.doDefaultURLSearch();
      return true;
    }

    return this.searchHasErrors();
  }

  public searchHasErrors(): boolean {
    if (!this.chosenFilters.lifeEvent) {
      this.errorMessage = 'Selecione um evento de vida.';
    }

    if (!this.canSearchDistrict()) {
      this.errorMessage = 'Selecione um distrito.';
    }

    return this.errorMessage != '';
  }

  private doDefaultURLSearch() {
    // Busca para o mapa de cidadao
    window.open('/#/SearchText?text=' + this.searchControl.value, '_self', '');

    setTimeout(function () {
      window.location.reload();
    }, 100);
  }

  private canSearchString() {
    return (this.searchControl.value || '').trim().length >= 3 && this.canUseGeolocation;
  }

  private canSearchDistrict() {
    return this.canUseGeolocation || this.chosenFilters.district;
  }

  public resetForm() {
    for(const property in this.chosenFilters) {
      this.chosenFilters[property] = null;
    }

    this.searchControl.setValue('');

    const matCheckbox = new MatCheckboxChange();
    matCheckbox.checked = true;
    this.useGeolocationToggle(matCheckbox);
  }

  private canSearchWithGeolocation(): boolean {
    return this.chosenFilters.lifeEvent || (this.searchControl.value || '').trim().length >= 3;
  }

  private canSearchWithoutGeolocation(): boolean {
    return this.chosenFilters.lifeEvent && this.chosenFilters.district;
  }

  private clearService(){
    this.chosenFilters.service = null;
  }

  private clearDistrict() {
    this.chosenFilters.district = null;
    this.clearMunicipality();
  }

  private clearMunicipality() {
    this.chosenFilters.municipality = null;
    this.clearParishes();
  }

  private clearParishes() {
    this.chosenFilters.parish = null;
  }

}
